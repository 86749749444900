import React, {useContext, useEffect, useState} from 'react'
import Swal from 'sweetalert2';
import VoitureContext from '../../context/voitures/voitureContext';

const ContactUs = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });


    const voitureContext = useContext(VoitureContext);

    const {
        sendContactUs, GetAllContent,contactus
    } = voitureContext;


    useEffect(() => {
        GetAllContent();
    }, []);



    const changeAccount = (key, value) => {
        let keys = key.split(".");
        let formDataS = formData;
        if (keys.length === 1) {
            formDataS[keys[0]] = value;
        } else if (keys.length === 2) {
            formDataS[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
            formDataS[keys[0]][keys[1]][keys[2]] = value;
        }
        setFormData({...formDataS});
    };

    const onSendContatcUs = (event) => {
        event.preventDefault();


        sendContactUs(formData).then(() => {

            Swal.fire({
                title: 'Votre message a été envoyé!',
                text: "Votre demande a bien été prise en compte, notre équipe SOPRIAM OCCASIONS prendra contact avec vous rapidement.",
                icon: 'success',
                confirmButtonColor: '#ef6744',
                confirmButtonText: 'Retourner à l’accueil'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/'
                }
            })
        }).catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: err.response.data.details,
            });

        });
    }

    return (
        <>

            <section class="page-header contactus_page">
                <div class="container-fluid">
                    <div class="page-header_wrap">
                        {/*<div class="page-heading">*/}
                        {/*    <h1>Contactez-nous</h1>*/}
                        {/*</div>*/}
                        <ul class="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>Contactez-nous</li>
                        </ul>
                    </div>
                </div>

                <div class="dark-overlay"></div>
            </section>


            <section class="contact_us section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <h3>Contactez-nous : </h3>
                            <div class="contact_form gray-bg">
                                <form onSubmit={onSendContatcUs}>
                                    <div class="form-group">
                                        <label class="control-label">Nom et prénom <span>*</span></label>
                                        <input onChange={(e) =>
                                            changeAccount("name", e.target.value)
                                        } name='name' required type="text" class="form-control white_bg" id="fullname"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="control-label">Email <span>*</span></label>
                                        <input onChange={(e) =>
                                            changeAccount("email", e.target.value)} name='email' required type="email"
                                               class="form-control white_bg" id="emailaddress"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="control-label">Téléphone <span>*</span></label>
                                        <input onChange={(e) =>
                                            changeAccount("phone", e.target.value)}
                                               pattern="^(((\+[\d]{2,2})|\+\([\d]{2,2}\))|[0])?[0-9]+$"
                                               minLength={10}
                                               maxLength={14}
                                               name='phone' required type="tel" class="form-control white_bg"
                                               id="phonenumber"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="control-label">Message <span>*</span></label>
                                        <textarea onChange={(e) =>
                                            changeAccount("message", e.target.value)} name='message' required
                                                  class="form-control white_bg" rows="4"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button  class="btn bg-inc-g uppr" type="submit">Envoyer le
                                            message </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h3>Informations de contact</h3>
                            <div class="contact_detail">
                                <ul>
                                    <li>
                                        <div class="icon_wrap"><i class="fa fa-map-marker" aria-hidden="true"></i></div>
                                        <div class="contact_info_m">{contactus[0]?.data?.inputList[0]?.data}
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon_wrap"><i class="fa fa-phone" aria-hidden="true"></i></div>
                                        <div class="contact_info_m"><a href="tel:61-1234-567-90">{contactus[0]?.data?.inputList[1]?.data}</a></div>
                                    </li>
                                    <li>
                                        <div class="icon_wrap"><i class="fa fa-envelope-o" aria-hidden="true"></i></div>
                                        <div class="contact_info_m"><a
                                            href="mailto:contact@sopriamoccasions.ma">{contactus[0]?.data?.inputList[2]?.data}</a>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ContactUs;
