import React, {useEffect, useState} from 'react';
import PaymentForm from "./Paymentform";
import axios from "axios";

const PaymentTest = (props) => {

    const [state, setState] = React.useState(undefined);

    const [disb, setdisb] = useState(props.disb);


    useEffect(() => {
        setdisb(props.disb);

    }, [props.disb]);

    const submitPaymentBtn = 'submit_payment';

    const callPayment = async () => {

        await axios.post('/payment', {orderId: props.orderId}).then(res => {
            setState(res.data);
            console.log("res.data",res.data);
            /*  setTimeout(_ => document.paymentForm.submit(), 1000)*/
        });
    }

    return (
        <div>

            <button disabled={!disb}
                    type="button" onClick={callPayment} class="btn  bg-inc-g uppr">Payez l'acompte</button>
            &nbsp;
            {
                state && <PaymentForm paymentForm={state}/>
            }
        </div>
    );
};

export default PaymentTest;
