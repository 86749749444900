import React, { useEffect } from 'react'
import AboutPlus from '../components/AboutPlus'
import Engagment from '../components/Engagment'
import Featured from '../components/Featured'
import Main from '../components/Main'

const Home = () => {
    const scrollToTop = () => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      };
      useEffect(() => {
          scrollToTop();
      }, []);

    return (
        <>
            <Main/>
            <Featured/>
            <Engagment/>
            {/*<AboutPlus/>*/}
        </>
    )
}

export default Home
