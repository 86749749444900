import React, {useEffect, useState} from "react";
import {Result} from 'antd';
import {Helmet} from "react-helmet";


const ValidationPage = () => {

    const onGo = () => {
        window.location.href = "/login";
    };

    const [randomValue, setRandomValue] = useState(0);

    useEffect(() => {
        let axel = Math.random() + '';
        setRandomValue(axel * 10000000000000) ;
    }, []);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sopriam Occasions - validation de compte</title>

                <script type="text/javascript">
                    {`
                          var axel = Math.random() + "";
                    var a = axel * 10000000000000;
                    document.write('<iframe src="https://13491286.fls.doubleclick.net/activityi;src=13491286;type=thank0;cat=thank00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=1;num='${randomValue}'?" width="1" height="1" frameborder="0" style="display:none"></iframe>'');
                    `}
                </script>
                <noscript>
                    {`
                    <iframe src="https://13491286.fls.doubleclick.net/activityi;src=13491286;type=form;cat=formu00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=1?" width="1" height="1" frameborder="0" style="display:none"></iframe>
                    `}
                </noscript>
            </Helmet>

            <br></br> <br></br>
            <Result
                status={"success"}
                title={"ACTIVER MON COMPTE"}
                subTitle="Votre compte est créé. Vous devez maintenant l'activer en cliquant dans le lien de l'email que vous avez reçu.        "
                extra={[
                    <button onClick={onGo} type="submit" class="btn  bg-inc-g">CONTINUER </button>
                    ,
                ]}
            />,
        </div>
    )
}

export default ValidationPage
