import {AUTH_ERROR, PROFILE_SUCCESS, CLEAR_ERRORS, LOAD_FAIL, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, USER_LOADED,} from "./types";

export default (state, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOGIN_SUCCESS:
            localStorage.setItem("access_token", payload.access_token);
            return {
                ...state,
                currentUser: null,
                error: null,
            };
        case USER_LOADED:
            return {
                ...state,
                isAuth: true,
                currentUser: payload,
                error: null,
            };
        case PROFILE_SUCCESS:
            return {
                ...state,
                currentProfil: payload,
            };
        case LOGOUT:
        case LOAD_FAIL:
        case LOGIN_FAIL:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                error: null,
            };
        case AUTH_ERROR:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};