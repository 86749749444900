import React from "react";
import {Input} from "antd";
import zxcvbn from "zxcvbn";

export class PasswordInput extends React.Component {
    static defaultProps = {
        settings: {
            colorScheme: {
                levels: ["#ff4033", "#fe940d", "#ffd908", "#cbe11d", "#6ecc3a"],
                noLevel: "lightgrey"
            },
            height: 5,
            alwaysVisible: false
        }
    };

    state = {
        level: -1
    };

    onChange = e => {
        const value = e.target.value;
        const score = value.length === 0 ? -1 : zxcvbn(value).score;

        this.setState({level: score});

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    render() {
        const {settings, inputProps, ...rest} = this.props;
        return (
            <div>
                <Input.Password {...inputProps} {...rest} onChange={this.onChange}/>
                <PasswordStrengthIndicator
                    level={this.state.level}
                    settings={settings}
                />
            </div>
        );
    }
}

export const PasswordStrengthIndicator = ({level, settings}) => {
    if (!settings.alwaysVisible && level < 0) {
        return null;
    }

    const indicators = [];

    for (let i = 0; i < 5; i++) {
        const color =
            i <= level
                ? settings.colorScheme.levels[level]
                : settings.colorScheme.noLevel;
        indicators.push(<div key={`indicator-${i}`} style={getIndicatorStyle(color, settings.height)}/>);
    }

    return <div style={getWrapperStyle(settings.height)}>{indicators}</div>;
};

function getWrapperStyle(height) {
    return {
        lineHeight: height + "px"
    };
}

function getIndicatorStyle(color, height) {
    return {
        display: "inline-block",
        width: "20%",
        backgroundColor: color,
        height: height + "px",
        borderRadius: "2px"
    };
}
