import React, {useContext, useEffect, useState} from 'react'
import {Badge, Checkbox, Form, List, Modal, Space, Spin} from 'antd';
import {useShopCart} from '../../contexts';
import LINKS from '../../utils/Link';
import {useHistory} from "react-router-dom";
import VoitureContext from '../../context/voitures/voitureContext';
import PaymentTest from '../payment/PaymentTest';
import SimpleBar from 'simplebar-react';

const MesReservation = () => {

    let history = useHistory();

    const [agreementModal, setAgreementModal] = useState({
        visible: false,
        checked: false
    });

    const {cartValue, setCartValue} = useShopCart();

    const voitureContext = useContext(VoitureContext);

    const {getAllReservations, allreservations} = voitureContext;

    const [paymentVisible, setpaymentVisible] = useState(false);

    useEffect(() => {

        getAllReservations();
    }, []);

    const goToVoiture = (data) => {

        history.push({
            pathname: '/Voituredetail/' + data.vehicle.id,
            data: data,
        });

    }

    const listData = [];
    for (let i = 0; i < 23; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `ant design part ${i}`,
            avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            description:
                'Ant Design, a design language for background applications, is refined by Ant UED Team.',
            content:
                'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
        });
    }

    const IconText = ({icon, text}) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    const onAddToPanier = (v, t) => {
        if (t === "add") {
            setCartValue([...cartValue, v]);

        } else {
            setCartValue(cartValue.filter(value => value.id !== v.id));
        }
    }

    const [orderId, setorderId] = useState();

    const goToPayment = (data) => {
        setpaymentVisible(true);
        setorderId(data.id);

    }

    const onOk = () => {
        setpaymentVisible(false);
    }

    const onCancel = () => {
        setpaymentVisible(false);

    }

    const [loading, setLoading] = useState(false);

    const onClickpayment = (data) => {
        setLoading(true);   
        
    }

    const [disb, setdisb] = useState(false);

    const onCheckchange = (e) => {

        setdisb(e.target.checked);
    }

    return (

        <>

            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        <div className="page-heading">
                        </div>
                        <ul className="coustom-breadcrumb">
                            <li><a href="/">ACCUEIL</a></li>
                            <li>MES RÉSERVATIONS</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            margin: '3rem'
        }}>


            <h5 className='text-center uppr'>Mes réservations DE VÉHICULE(S) D'OCCASION </h5>

            <h6 className='uppr'> {allreservations.filter((value) => {return value.status === "PAYEE"; }).length} VÉHICULE(S) Réservé(S) </h6>

            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: page => {

                    },
                    pageSize: 3,
                }}
                dataSource={allreservations.filter((value) => {
                    return value.status === "PAYEE";
                })}
                footer={null
                }
                renderItem={item => (
                    <Badge.Ribbon color={
                        item.status === "ACCEPTÉE" ? 'green':
                            item.status === "PAYEE" ? 'green'
                            : item.status === "DEMANDE" ? 'rgb(31, 58, 108)'
                            : item.status === "REJETÉE" ? 'red'
                                : item.status === "COMPLÈTE" ? "#ef6744"
                                    : ''
                    }
                                  text={
                                      item.status === "ACCEPTÉE" ? 'VALIDÉ'
                                          : item.status === "DEMANDE" ? 'EN COURS DE TRAITEMENT'
                                          : item.status === "PAYEE" ? 'PAYÉE'
                                          : item.status === "REJETÉE" ? 'REJETÉE'
                                              : item.status === "COMPLÈTE" ? "COMPLÈTE"
                                                  : ''
                                  }>

                        <List.Item
                            key={item?.vehicle?.category}
                            actions={[

                                item.status === "ACCEPTÉE" ?

                                    <div onClick={() => onClickpayment(item)} class="btn btn-block bg-inc-g">
                                    <PaymentTest disb={true} orderId={item.id}/>

                                    </div>
                                            : ''

                            ]}
                            extra={
                                <>
                                    <img
                                        width={272}
                                        alt="logo"
                                        src={LINKS.imagePath + item?.vehicle?.mainPicture}
                                    />
                                </>
                            }
                        >
                            <List.Item.Meta
                                title={<a
                                    onClick={() => goToVoiture(item)}>{item?.vehicle?.mark + "  " + item?.vehicle?.model + "  " + item?.vehicle?.category}</a>}
                                description={item?.vehicle?.mark}
                            />
                            {new Intl.NumberFormat('fr-FR').format(item?.vehicle?.promoPrice)}{" DH"}
                        </List.Item>
                    </Badge.Ribbon>
                )}
            />
            <Modal

                visible={paymentVisible}
                onOk={onOk}
                title="Vous allez réserver votre véhicule !"
                onCancel={onCancel}
                footer={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <img width="50" height="45" src="/images/secure_code_logo.png"></img>
                        <img width="50" height="45" src="/images/logo_cmi.png"></img>
                        <img width="50" height="45" src="/images/tn_verified_by_visa.png"></img>

                    </div>
                }

                style={{width: '100%'}}

            >

                <Spin style={{fontSize: '30px'}} size='large' spinning={loading}>


                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                    }}>
                        <h6 className="text-center">Vous vous apprêtez à valider votre demande de réservation. </h6>
                        <p className="text-center">Nous vous contacterons dans les meilleurs délais par email pour vous
                            confirmer la disponibilité du véhicule.</p>

                        <p className="text-center">Si le véhicule est disponible, vous disposerez d'un délai de 24h pour
                            confirmer votre réservation par une autorisation de débit de 2000 DH .</p>


                        <div>

                            <Form
                            >
                                <Form.Item
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject('Prière accepter les mentions légales'),
                                        },
                                    ]}
                                >
                                    <Checkbox onChange={onCheckchange}
                                              onClick={() => setAgreementModal({...agreementModal, visible: true})}
                                              checked={agreementModal.checked}>
                                        <span> J'accepte les conditions</span><span
                                        style={{color: '#88888'}}>*</span>
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item>
                                    <div onClick={onClickpayment}>
                                        <PaymentTest disb={disb} orderId={orderId}/>

                                    </div>
                                </Form.Item>

                            </Form>


                        </div>


                    </div>


                </Spin>
            </Modal>


            <Modal
                title='Mentions légales :'
                visible={agreementModal.visible}
                onCancel={() => setAgreementModal({...agreementModal, visible: false})}
                footer={null}>
                <SimpleBar style={{maxHeight: 300, maxWidth: 600}}>
                    <p>
                        Certains des véhicules présentés sur le Site peuvent être réservés. L’utilisateur qui souhaite
                        réserver en ligne un véhicule doit fournir ses coordonnées en ouvrant un compte ou en se
                        connectant à son compte s’il en possède déjà un.
                        Ce compte permet également à l’utilisateur d’enregistrer s’il le souhaite plusieurs offres de
                        vente de véhicule figurant sur le Site et d’y accéder par la suite (rubrique «Se connecter / Mon
                        panier»). Cet enregistrement ne constitue cependant pas une réservation du véhicule, qui pourra
                        être réservé ou acheté par un autre client. Si un véhicule enregistré dans la rubrique «Se
                        connecter / Mon panier» est ensuite réservé par un tiers, cette information sera indiquée sous
                        forme d’une bannière «RESERVE». Si un véhicule enregistré dans la rubrique «Se connecter / Mon
                        panier» est ensuite vendu à un tiers, il sera retiré automatiquement de la rubrique «Se
                        connecter / Mon panier».

                        Dans le cas où l’utilisateur demande à réserver un véhicule présenté sur le Site, il est procédé
                        à la vérification de la disponibilité de ce véhicule. Puis il est adressé à l’utilisateur un
                        courrier électronique pour soit lui confirmer la disponibilité du véhicule soit l’informer de ce
                        que le véhicule n’est pas disponible. Cette vérification de la disponibilité s’explique par le
                        fait que les véhicules présentés sur le Site sont également proposés à la vente en points de
                        vente physique.

                        En cas de confirmation de la disponibilité du véhicule à l’utilisateur, ce dernier dispose d’un
                        délai de 24 heures à compter de la réception du courrier électronique de confirmation pour, s’il
                        le souhaite, confirmer sa réservation sur le Site. L’utilisateur qui souhaite confirmer sa
                        réservation est appelé à approuver les présentes conditions générales d’utilisation et de
                        réservation sur le Site.

                        Afin de s’assurer du sérieux de la démarche de l’utilisateur, la réservation est subordonnée au
                        fait que celui-ci donne une autorisation de débit de deux mille dirhams (2000 Dhs) par carte
                        bancaire sur le Site internet.

                        Une fois la réservation confirmée par l’utilisateur, celui-ci reçoit une confirmation de sa
                        réservation par courrier électronique. Le Point de Vente qui propose le véhicule réservé prend
                        alors contact avec l’utilisateur pour organiser un rendez-vous en point de vente pour la suite
                        de la procédure d’achat du véhicule.

                        Dans le cas où, dans le cadre de la vérification de la disponibilité du véhicule évoquée
                        ci-dessus, il apparaitrait que le véhicule n’est pas disponible, le Point de Vente concerné
                        pourra prendre contact avec l’utilisateur pour lui proposer un véhicule approchant ou le
                        remboursement du montant de l’acompte payé en ligne.

                    </p>

                </SimpleBar>
            </Modal>
        </div>

            </>
    )
}

export default MesReservation;

