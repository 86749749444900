import React from 'react'

import './eng.css'

export const Engaements = () => {

    return (
        <div>


            <section class="pb-5 pt-5" id="services">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="eng center-heading">
                                <h2 className='stlspor'><span className="sttyl">NOS </span> ENGAGEMENTS </h2>

                            </div>
                        </div>
                        <div class="offset-lg-3 col-lg-6">
                            <div class="eng center-text">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <a href="#" class="services-small-item">
                                <div class="icon">
                                    <i class="far fa-check-square"></i>
                                </div>
                                <h5 class="services-title">JUSQU’À 24 MOIS
                                    DE GARANTIE</h5>
                                <p>Garantie de 24 mois , couvrant les pièces mécaniques et électriques ainsi que la main
                                    d‘oeuvre.. </p>

                            </a>
                            <div class="item-bg"></div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a href="#" class="services-small-item">
                                <div class="icon">
                                    <i class="fa fa-bed"></i>
                                </div>
                                <h5 class="services-title">ASSISTANCE 24H/24 ET 7J/7 </h5>
                                <p>Parce que nous vous devons de prévoir l'imprévisible.

                                    Alors, tous nos véhicules bénéficient de l’assistance ,.. </p>

                            </a>
                            <div class="item-bg"></div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a href="#" class="services-small-item">
                                <div class="icon">
                                    <i class="fas fa-chart-bar"></i>
                                </div>
                                <h5 class="services-title">Reprise de votre véhicule. </h5>
                                <p>Nous reprenons votre ancien véhicule et vous bénéficiez des remises importantes. .

                                    . </p>

                            </a>
                            <div class="item-bg"></div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a href="#" class="services-small-item">
                                <div class="icon">
                                    <i class="far fa-check-square"></i>
                                </div>
                                <h5 class="services-title">JUSQU’À 24 MOIS
                                    DE GARANTIE</h5>
                                <p>Garantie de 24 mois , couvrant les pièces mécaniques et électriques ainsi que la main
                                    d‘oeuvre.. </p>

                            </a>
                            <div class="item-bg"></div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
