import React from 'react'
import VoitureContext from "../../context/voitures/voitureContext";
import ReactHtmlParser from "react-html-parser";
import LINKS from "../../utils/Link";

export const GuideAchat = () => {

    const voitureContext = React.useContext(VoitureContext);

    const {GetAllContent, whoAreWee} = voitureContext;

    React.useEffect(() => {
        GetAllContent();
    }, []);

    let data = whoAreWee && whoAreWee[0] && whoAreWee[0].data.inputList;


    return (
        <div>

            <section className="page-header faq_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        <div className="page-heading">
                        </div>
                        <ul className="coustom-breadcrumb">
                            <li><a href="/">ACCUEIL</a></li>
                            <li>GUIDE D'ACHAT</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>

            <section className="our_services section-padding">
                <div className="container">

                    {data !== undefined &&

                        <>

                            {data.map((v,i) => {
                                return(
                                    <>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="services_image">
                                             <img src={LINKS.imagePath + v.image}/>

                                                </div>
                                            </div>
                                            <div className="col-md-12 guide-achat">
                                                <h3>{v.mainTitle}</h3>
                                                {ReactHtmlParser(v.mainDesc)}
                                                <br></br>
                                                {ReactHtmlParser(v.mainDetails)}

                                            </div>
                                        </div>
                                        <div className="space-80"></div>

                                    </>
                                )

                            })}


                        </>
                    }
                </div>
            </section>


        </div>
    )
}
