import {Result} from 'antd';
import React from 'react'

const okUrl = () => {

    const onGo = () => {
        window.location.href = "/";
    };

    return (
        <div>
            <br></br>

            <Result
                status="success"
                title={"Paiement Réussi!"}
                subTitle={
                    "Votre paiement a été traité avec succès. rendez-vous au point de vente choisis. Pour toute question ou information supplémentaire, veuillez contacter notre service commercial par téléphone au 0529 075 077"
                }
                extra={[
                    <button onClick={onGo} type="submit" class="btn  bg-inc-g">Retourner à l’accueil </button>
                    ,
                ]}
            />,
        </div>
    )
}

export default okUrl;
