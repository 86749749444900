import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {Card, Checkbox, Form, Modal} from 'antd';
import Swal from "sweetalert2";
import VoitureContext from '../../context/voitures/voitureContext';
import LINKS from '../../utils/Link';
import {useShopCart} from '../../contexts';
import SimpleBar from 'simplebar-react';
import PaymentTest from "../payment/PaymentTest";
import axios from "axios";
import PaymentForm from "../payment/Paymentform";
import {BgColorsOutlined, CarOutlined} from "@ant-design/icons";

const {Meta} = Card;

const DemandeReservation = (props) => {

    let history = useHistory();

    const {cartValue, setCartValue} = useShopCart();
    const [state, setState] = React.useState(undefined);

    const voitureContext = useContext(VoitureContext);

    const [agreementModal, setAgreementModal] = useState({
        visible: false,
        checked: false
    });

    const {
        voituredata,
        getVoitureById,
        ReserverVoiture
    } = voitureContext;

    useEffect(() => {
        getVoitureById(props.match.params.id);
    }, [props.match.params.id, voituredata !== null]);

    const callPayment = async (data) => {

        await axios.post('/payment', {orderId: data}).then(res => {
            setState(res.data);
            setTimeout(_ => document.paymentForm.submit(), 1000)
        });
    }
    const onReserver = () => {

        ReserverVoiture(voituredata).then((res) => {
            console.log(res);
            console.log(res.message.id);
            callPayment(res.message.id);
            //setCartValue(cartValue.filter(value => value.id !== voituredata.id));

          /*  Swal.fire({
                title: 'Votre message a été envoyé!',
                text: "Nous avons pris en compte votre demande. Notre équipe SOPRIAM OCCASIONS prendra contact avec vous rapidement.",
                icon: 'success',
                confirmButtonColor: '#ef6744',
                confirmButtonText: 'Retour à mes réservations'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/reservations'

                }
            })*/

        }).then(() => {

        })
    }

    return (
        <>

            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        <div className="page-heading">
                        </div>
                        <ul className="coustom-breadcrumb">
                            <li><a href="/">ACCUEIL</a></li>
                            <li>MES RÉSERVATIONS</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>


        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        }}>
            <br></br>
            <h4 className='cstmTite'>MA DEMANDE DE RÉSERVATION
            </h4>
            <br></br>

            {voituredata !== null &&

            <div className='row cstmRow'>

                <div className='col-md-6'>
                <Card
                    title={
                        <span></span>
/*
                        <span>RÉCAPITULATIF DU VÉHICULE</span>
*/
                    }
                    hoverable
                    style={{margin: '2rem',textAlign:'center'}}
                    cover={
                        <img style={{margin: 'auto',width:'90%',display:'block'}} className='img-fluidDd' alt="example"  src={LINKS.imagePath + voituredata.mainPicture}/>
                    }
                >
                    <Meta title={voituredata.mark+" "+voituredata.model} />
                    <Meta   description={voituredata.category}/>
                </Card>
                </div>
                <div className='col-md-6'>
                <div   style={{margin: '2rem'}}>
                    <div   className="featured-car-content">
                        <div className="price_info">
                            <p className="featured-price">PRIX : {new Intl.NumberFormat('fr-FR').format(voituredata.promoPrice)}{" DH"}</p>
                            <p className="featured-price" style={{fontSize:"13px"}}>Acompte à payer  2000 DH </p>
                        </div>
                        <ul>
                            <li><i className="fa fa-road" aria-hidden="true"></i>{new Intl.NumberFormat('fr-FR').format(voituredata.mileage)} {" KM"} </li>
                            <li><i className="fa fa-calendar" aria-hidden="true"></i>{voituredata.registrationDate}</li>
                            <li><i className="fa fa-car" aria-hidden="true"> ac</i>{voituredata.fuel}</li>
                            <li><i className="fa fa-user" aria-hidden="true"></i>{voituredata.carSeats}</li>
                            <li><i className="fa fa-superpowers" aria-hidden="true"></i>{voituredata.gearBox}</li>
                            <li><BgColorsOutlined /> {voituredata.color} </li>

                            {/*<li><CarOutlined /> {voituredata.frame} </li>*/}
                        </ul>
                    </div>


                    <div style={{display: 'flex', justifyContent: 'center', margin: '2rem'}}>

                        <Form onFinish={onReserver}>
                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject('Prière accepter les mentions légales'),
                                    },
                                ]}
                                {...tailFormItemLayout}
                            >
                                <Checkbox onClick={() => setAgreementModal({...agreementModal, visible: true})}
                                          checked={agreementModal.checked} style={{whiteSpace: "nowrap"}}>
                                    <span> J'accepte les mentions légales</span><span
                                    style={{color: '#88888'}}>*</span>
                                </Checkbox>
                            </Form.Item>

                            <Form.Item {...tailFormItemLayout}>
                                <button type="submit" className="btn  bg-inc-">PAYER L'ACOMPTE </button>
                            </Form.Item>

                        </Form>

                        {
                            state && <PaymentForm paymentForm={state}/>
                        }

                    </div>
                </div>
                </div>


                 {/*   <h6 className="text-center">Vous vous apprêtez à valider votre demande de réservation. </h6>
                    <p className="text-center">Nous vous contacterons dans les meilleurs délais par email pour vous
                        confirmer la disponibilité du véhicule.</p>

                    <p className="text-center">Si le véhicule est disponible, vous disposerez d'un délai de 24h pour
                        confirmer votre réservation par une autorisation de débit de 2000 DH .</p>

                    <p className="text-center">NB : pour valider votre demande de réservation, assurez-vous que votre
                        nom et votre prénom soient bien renseignés dans votre compte
                        .</p>
*/}



            </div>
            }

            <Modal
                title='Mentions légales :'
                visible={agreementModal.visible}
                onCancel={() => setAgreementModal({...agreementModal, visible: false})}
                footer={null}>
                <SimpleBar style={{maxHeight: 300, maxWidth: 600}}>
                    Certains des véhicules présentés sur le Site peuvent être réservés. L’utilisateur qui souhaite réserver en ligne un véhicule doit fournir ses coordonnées en ouvrant un compte ou en se connectant à son compte s’il en possède déjà un.
                    <p></p>
                    Ce compte permet également à l’utilisateur d’enregistrer s’il le souhaite plusieurs offres de vente de véhicule figurant sur le Site et d’y accéder par la suite (rubrique «Se connecter / Mon panier»). Cet enregistrement ne constitue cependant pas une réservation du véhicule, qui pourra être réservé ou acheté par un autre client. Si un véhicule enregistré dans la rubrique «Mon panier» est ensuite réservé par un tiers, cette information sera indiquée sous forme d’une bannière «Réservé».
                    <p></p>
                    Si un véhicule enregistré dans la rubrique « Mon panier » est ensuite vendu à un tiers, il sera retiré automatiquement de la rubrique « Mon panier ».
                    <p></p>
                    L’utilisateur qui souhaite confirmer sa réservation est appelé à approuver les présentes conditions générales d’utilisation et de réservation sur le Site. Afin de s’assurer du sérieux de la démarche de l’utilisateur, la réservation est subordonnée au fait que celui-ci donne une autorisation de débit de deux mille dirhams (2000 DH) par carte bancaire sur le Site internet.
                    <p></p>
                    Une fois le paiement validé, celui-ci reçoit une confirmation de sa réservation par courrier électronique.
                    <p></p>
                    L’utilisateur aura par la suite 24h afin de s’adresser au point de vente le plus proche pour la suite de la procédure d’achat du véhicule.
                    <p></p>
                    Dans le cas où, dans le cadre de la vérification de la disponibilité du véhicule évoquée ci-dessus, il apparaitrait que le véhicule n’est pas disponible, le Point de Vente concerné pourra prendre contact avec l’utilisateur pour lui proposer un véhicule approchant ou le remboursement de 50% du montant de l’acompte payé en ligne, soit un remboursement de 1000 DH).

                </SimpleBar>
            </Modal>
        </div>

            </>
    )
}

export default DemandeReservation;

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 20,
            offset: 1,
        },
    },
};
