import firebase from "firebase";

const config = {
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
};

firebase.initializeApp(config);


const firebaseAuth = firebase.auth();

const signIn = () => {
    firebaseAuth.signInAnonymously()
        .then(() => {
            console.log('Success');
        })
        .catch(error => {
            console.log(error);
        });
}
signIn();

export default firebase;
