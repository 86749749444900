import React, {useContext, useEffect, useState} from 'react'
import VoitureContext from '../context/voitures/voitureContext';
import ChatWindow from './chat/ChatWindow';
import {useHistory} from "react-router-dom";
import Swal from 'sweetalert2';
import LINKS from "../utils/Link";

const Footer = () => {
    let history = useHistory();

    const [formData, setFormData] = useState({
        email: '',
    });


    const voitureContext = useContext(VoitureContext);

    const {
        getMarks,
        allmarks,
        filterDatas,
        GetAllContent, media, sendNewsLetter,conditions,conditionsvent,whoAreWe
    } = voitureContext;

    useEffect(() => {
        GetAllContent();
        getMarks();
    }, [])


    const changeAccount = (key, value) => {
        let keys = key.split(".");
        let formDataS = formData;
        if (keys.length === 1) {
            formDataS[keys[0]] = value;
        } else if (keys.length === 2) {
            formDataS[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
            formDataS[keys[0]][keys[1]][keys[2]] = value;
        }
        setFormData({...formDataS});
    };

    const [isChatOpen, setIsChatOpen] = React.useState(false);

    const handleChatWindow = (e) => {
        const {checked} = e.target;
        setIsChatOpen(checked);
    };

    const onSendNewsLetter = (event) => {
        event.preventDefault();


        sendNewsLetter(formData).then(() => {

            Swal.fire({
                title: 'Votre inscription a bien été enregistrée!',
                icon: 'success',
                confirmButtonColor: '#ef6744',
                confirmButtonText: 'Retourner à l’accueil'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/'

                }
            });
        }).catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: err.response.data.details,
            });

        });

    }


    return (

        <footer>
            <div style={{backgroundColor: '#1f3a6c'}} class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-sm-6">
                            <h6>Besoin d'aide</h6>
                            <ul>
                                <li><a href="/FAQ">F.A.Q</a></li>
                                <li><a href="/WhoAreWe">Qui sommes-nous?</a></li>
                                <li><a href="/contact-us">Contactez-nous</a></li>
                                <li><a href={LINKS.imagePath + conditions[0]?.data?.logo }>Conditions générales d'utilisation</a></li>
                                <li><a href={LINKS.imagePath + conditionsvent[0]?.data?.logo }>Conditions générales de vente</a></li>
                            </ul>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <h6>Marques</h6>
                            <ul>


                                {allmarks.map((v, i) =>
                                    <>
                                        <li><a key={i}

                                               onClick={() => {
                                                   filterDatas({mark: v}).then((res) => {

                                                       // history.push("/Searchresult", { type: true });


                                                       history.push({
                                                           pathname: '/voitures-occasion',
                                                           SearchType: true,
                                                           data: {mark: v}
                                                       });
                                                   });


                                               }}

                                        >{v}</a></li>
                                    </>
                                )}


                            </ul>

                        </div>

                        <div class="col-md-4 col-sm-6 float-right">
                            <h6> Newsletter</h6>
                            <div class="newsletter-form">
                                <form onSubmit={onSendNewsLetter} action='' >
                                    <div class="form-group">
                                        <input type="email" class="form-control newsletter-input" required
                                               placeholder="Entrer l'adresse e-mail"

                                               onChange={(e) =>
                                                   changeAccount("email", e.target.value)}
                                        />
                                    </div>
                                    <button  type="submit"
                                            class="btn btn-block bg-inc-g uppr">S'inscrire</button>
                                </form><br></br>
                                <h6 className="uppr">Contactez-nous : 0522674691</h6>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">

                        <div class="col-md-6">
                            <p class="copy-right">© {new Date().getFullYear()} Sopriam Occasions. Tous droits
                                réservés. </p>
                        </div>
                        <div class="col-md-6 text-right">
                            <div class="footer_widget">
                                <p>Suivez-nous sur :</p>
                                <ul>
                                    <li><a href={media.length > 0 && media[0].data.inputList[0].lien}><i
                                        class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                                    <li><a href={media.length > 0 && media[0].data.inputList[1].lien}><i
                                        class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fixed-bottom">

                <input
                    type="checkbox"
                    id="click"
                    onChange={handleChatWindow}
                    checked={isChatOpen}
                />
                <label className="chat-label" htmlFor="click">
                    <i className="fab fa-facebook-messenger"/>
                    <i className="fas fa-times"/>
                </label>
                <ChatWindow isOpen={isChatOpen}/>

            </div>
        </footer>

    )
}

export default Footer
