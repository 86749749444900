import React, {useContext, useEffect} from 'react'
import {Avatar, List} from 'antd';
import VoitureContext from "../../context/voitures/voitureContext";

const FAQ = () => {

    const voitureContext = useContext(VoitureContext);

    const {GetAllContent, faq} = voitureContext;

    useEffect(() => {
        GetAllContent();
    }, []);


    let data = faq && faq[0] && faq[0].data.inputList;


    return (
        <>


            <section class="page-header faq_page">
                <div class="container-fluid">
                    <div class="page-header_wrap">
                        {/*<div class="page-heading">*/}
                        {/*    <h1>FAQ</h1>*/}
                        {/*</div>*/}
                        <ul class="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>FAQ</li>
                        </ul>
                    </div>
                </div>

                <div class="dark-overlay"></div>
            </section>

            <section class="faq section-padding">
                <div class="container">

                    {data !== undefined &&
                    <>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar
                                            src="https://www.shareicon.net/data/512x512/2015/10/16/656968_info_512x512.png"/>}
                                        title={<span className='faq'>{item.title}</span>}
                                        description={<p className='faqD'>{item.description}</p>}
                                    />
                                </List.Item>
                            )}
                        /> 
                    </>
                    }

                </div>

            </section>
        </>
    )
}

export default FAQ;
