import React, {useContext, useEffect} from 'react'
import {Avatar, List} from 'antd';
import VoitureContext from "../../context/voitures/voitureContext";

const WhoAreWe = () => {

    const voitureContext = useContext(VoitureContext);

    const {GetAllContent, whoAreWee} = voitureContext;

    useEffect(() => {
        GetAllContent();
    }, []);


    let data = whoAreWee && whoAreWee[0] && whoAreWee[0].data.inputList;
    console.log('data::',data);

    return (
        <>


            <section class="page-header faq_page">
                <div class="container-fluid">
                    <div class="page-header_wrap">
                        <ul class="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>Qui Sommes-nous</li>
                        </ul>
                    </div>
                </div>

                <div class="dark-overlay"></div>
            </section>

            <section class="faq section-padding">
                <div class="container">

                    {data !== undefined &&
                    <>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<span className='whoAreWe'>{item.mainTitle}</span>}
                                        description={<p className='whoAreWeD' dangerouslySetInnerHTML={{ __html: item.mainDesc }}></p>}
                                    />
                                </List.Item>
                            )}
                        />

                    </>
                    }

                </div>

            </section>
        </>
    )
}

export default WhoAreWe;
