import React, {useContext, useEffect, useState} from 'react'
import {Card, Select} from 'antd';
import {GoogleMap, Marker, withGoogleMap} from "react-google-maps"
import VoitureContext from '../context/voitures/voitureContext';
import City from '../utils/City';
import {BankOutlined, PhoneOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import { isEmpty } from '../utils/validator'; 
import Map3 from './map-ol/Map3';

   
const {Option} = Select;


const PDV = () => {



    let history = useHistory();

    const voitureContext = useContext(VoitureContext);

    const {
        getPDV,
        allPDV

    } = voitureContext;

    const [resulrt, setresulrt] = useState();
    const [filter, setfilter] = useState(false);
    const [zoomMap, setZoomMap] = useState("");

    useEffect(() => {
        getPDV();

    }, []);

    const google = window.google;

    const [places, setplaces] = useState(
        [
            {
                "id": 1,
                "city": "RABAT",
                "name": "Park Slope",
                "latitude": "33.573109",
                "longitude": "-7.589843"
            },
            {
                "id": 2,
                "city": "CASABLANCA",
                "name": "Bushwick",
                "latitude": "33.971588",
                "longitude": "-6.849813"
            },
            {
                "id": 3,
                "city": "CASABLANCA",
                "name": "East New York",
                "latitude": "33.545520",
                "longitude": "-7.600432"
            }
        ]
    );

    const [data, setData] = useState({
        lat: 33.573109,
        lng: -7.589843
    });

    const onMarkerClick = (ds) => {

        let lat = parseFloat(ds.latitude, 10);
        let lng = parseFloat(ds.longitude, 10);
        setData({
            lat: lat,
            lng: lng
        })

    };


    const MyMapComponent = withGoogleMap((props) =>
        <GoogleMap
            zoom={zoomMap ? zoomMap : 8}
            center={data}


        >
            {props.isMarkerShown &&
            <>
            {isEmpty(resulrt) && setZoomMap(8)}
                {resulrt &&
                resulrt.map((place, i) => {
                    let lat = parseFloat(place.latitude, 10);
                    let lng = parseFloat(place.longitude, 10);
                    setZoomMap(15);

                    return (
                        <Marker
                            onClick={() => onMarkerClick(place)}
                            id={place.id}
                            key={place.id}
                            position={{lat: lat, lng: lng}}
                        >
                        </Marker>
                    );
                })}


            </>

            }

        </GoogleMap>
    )

    const handleChangePDV = (value) => {
        setfilter(true);
        let dd = allPDV.filter((v) => {
            return v.city === value
        })
        setresulrt(dd);

        if (dd.length > 0) {
            let lat = parseFloat(dd[0].latitude, 10);
            let lng = parseFloat(dd[0].longitude, 10);
            setData({
                lat: lat,
                lng: lng
            })
        }


    }

    const onGoDetails = (id) => {

        history.push({
            pathname: '/pdvdetail/' + id,
        });
    }


    return (

        <>


            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        {/*<div class="page-heading">*/}
                        {/*    <h1>Contactez-nous</h1>*/}
                        {/*</div>*/}
                        <ul className="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>POINT DE VENTE</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>

            <div className='Pdvcontainter'>
                <h6>TROUVER LE POINT DE VENTE LE PLUS PROCHE DE CHEZ VOUS ? </h6>
                <div>
                    <Select
                        showSearch
                        placeholder="Saissze votre région"
                        style={{width: 350}}
                        onChange={handleChangePDV}
                    >

                        {City.map((v, i) => {

                            return (
                                <Option key={i} value={v.name}>{v.name} </Option>

                            )
                        })}

                    </Select>

                </div>
                <div className="map-dirc">
                
                    <div className="idmmap"
                    >
                         <Map3 lang={data.lat} lat={data.lng} />

                       {/*    <MyMapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `500px`, width: `700px`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                        />*/}
                    </div>

                    <div className='datamapc'>
                        <span className='stlsporMap'> {filter ? resulrt.length : allPDV.length} <span
                            className='sttylMap'>réponse(s) trouvée(s)</span></span>

                        {!filter ?
                            allPDV.map((v, i) => {

                                return (
                                    <Card onClick={() => onGoDetails(v.id)} key={i} size="small" style={{width: 300, margin: 5,cursor:'pointer'}}>
                                        <p className='sttylsvMap'>{v.name}</p>
                                        <p className='abourdescMap'>
                                            <BankOutlined style={{marginRight:10}} />
                                            {v.address}{" "} {v.city}</p>
                                        <p className='abourdescMap'>
                                            <PhoneOutlined style={{marginRight:10}} />
                                             {v.tel}</p>

                                        <a onClick={() => onGoDetails(v.id)} className={"float-right upper"}>«Voir Plus» </a>
                                    </Card>

                                )
                            })
                            :

                            resulrt.map((v, i) => {

                                return (
                                    <Card key={i} size="small" style={{width: 300, margin: 5}}>
                                        <p className='sttylsvMap'>{v.name}</p>
                                        <p className='abourdescMap'>
                                            <BankOutlined style={{marginRight:10}} />
                                            {v.address}{" "} {v.city}</p>
                                        <p className='abourdescMap'>
                                            <PhoneOutlined style={{marginRight:10}} />
                                            {v.tel}</p>

                                        <a onClick={() => onGoDetails(v.id)} className={"float-right"}>Plus de détails </a>
                                    </Card>

                                )
                            })
                        }


                    </div>

                </div>


            </div>

        </>
    )
}

export default PDV


// <MyMapComponent
// isMarkerShown
// googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
// loadingElement={<div style={{ height: `100%` }} />}
// containerElement={<div style={{ height: `400px` , width: `700px` }} />}
// mapElement={<div style={{ height: `100%` }} />}
// />
