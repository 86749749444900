export const apiUrl2 = "http://localhost:8080/api/";

export const apiUrl = "https://sopriamoccasions.ma/api/";


export const LINKS = {
    baseURL: apiUrl,
    imagePath: apiUrl + "file/",
    SimiliPath: apiUrl + "simulator/",

};

export default LINKS;
