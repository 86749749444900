import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from 'axios';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.style.css";

const AutoPlaySlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        cssEase: "linear"
    };

    const setDefaultImages = () => {
        setImages([{ id: 1, pathImage: '/images/2.png' }])
    }

    useEffect(() => {
        axios
            .get("/slide/active")
            .then((response) => response.data)
            .then((response) => {
                if (response && response.length > 0) {
                    setImages(response.reverse());
                } else {
                    setDefaultImages();
                }
            })
            .catch((exception) => {
                setDefaultImages();
            });
    }, []);

    const [images, setImages] = useState([]);

    return (
        <Slider {...settings}>
            {images.map((image) => {
                return (<div className="slider-image-container">
                    <img className="slider-image" src={image.id === 1 ? image.pathImage : `${process.env.REACT_APP_API_FILE}/${image.pathImage}`} alt='' />
                </div>)
            })}
        </Slider>
    );
};

export default AutoPlaySlider;