import React from "react";
import {Spin} from "antd";

const Loading = ({children, spinning}) => (
    <Spin tip="Loading..." size={"large"} spinning={spinning}>
        {children}
    </Spin>
);

export default Loading;
