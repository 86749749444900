import React, {useContext, useEffect} from 'react';
import {GoogleMap, Marker, withGoogleMap} from "react-google-maps"
import ImageGallery from "react-image-gallery";
import VoitureContext from "../context/voitures/voitureContext";
import LINKS from "../utils/Link";
import Map3 from './map-ol/Map3';
import './pdvdetail.css';
const Pdvdetail = (props) => {


    const voitureContext = useContext(VoitureContext);

    const {
        getPDVById,
        PdvDetail

    } = voitureContext;

    const formatedImages = [];

    PdvDetail && PdvDetail.images.forEach((v, i) => {

        formatedImages.push({original: LINKS.imagePath + v, thumbnail: LINKS.imagePath + v})

    });


    let lat = parseFloat(PdvDetail?.latitude, 10);
    let lng = parseFloat(PdvDetail?.longitude, 10);

    const [data, setData] = React.useState({
        lat: lat,
        lng: lng
    });
    const [data2, setData2] = React.useState({
        lat: PdvDetail?.latitude,
        lng: PdvDetail?.longitude
    });

    React.useEffect(() => {

        getPDVById(props.match.params.id);

        let lat = parseFloat(PdvDetail?.latitude, 10);
        let lng = parseFloat(PdvDetail?.longitude, 10);
           setData2({
            lat: PdvDetail?.latitude,
            lng: PdvDetail?.longitude
        });
        setData({
            lat: lat,
            lng: lng
        });
    }, [props.match.params.id, PdvDetail !== null]);


    const MyMapComponent = withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={data ? 14 : 8}
            defaultCenter={data}


        >
            {props.isMarkerShown &&
            <>

                        <Marker

                            position={{lat: data.lat, lng: data.lng}}
                            title="Click to zoom"
                        >
                        </Marker>


            </>

            }

        </GoogleMap>
    )




    return (

        <>


            <section className="page-header contactus_page">
           
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        {/*<div class="page-heading">*/}
                        {/*    <h1>Contactez-nous</h1>*/}
                        {/*</div>*/}
                        <ul className="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>POINT DE VENTE</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>
        <section className="layout-content col-lg-12 col-md-12 col-sm-12 mt-3">
            <div>
            
                <div className="row">
                    <div id="pdv_landing_page"
                         className="pdv_title pdv_title pdv-title col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h1>
                            {/*<span className="welcome">Bienvenue dans notre point de vente</span>*/}
                            <span className="pdv_marketing_name title">{PdvDetail?.name}</span>
                            <span className="no-margin marque-rattachement-1">{PdvDetail?.address}</span>
                        </h1>
                    </div>
                </div>
            </div>
             
            <div className="row">

                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 pdv-image-about-container">
                    <div className="pdv_img_container pdv-img-container-main">
                        <div className="pdv-img-container">

                            <ImageGallery showPlayButton={false} items={formatedImages}/>


                        </div>
                    </div>
                    <div className="pdv_description pdv-description">
                        <div className="pdv_about">
                            <p className="pdv-about-title">A propos</p>
                            <p className="no-margin">{PdvDetail?.description}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 pdv-infos-map-container">


                    <div id="pdv-vo" className="vo-card pdv-infos-map col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div id="pdv_map">
                             <Map3 lat={
                                 data2.lng } lang={data2.lat} />
                          {/*    <MyMapComponent
                                isMarkerShown
                                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{height: `100%`}}/>}
                                containerElement={<div style={{height: `100%`, width: `100%`}}/>}
                                mapElement={<div style={{height: `100%`}}/>}
                            />*/}
                        </div>
                        <div className="pdv_infos pdv-infos">
                            <div className="adresse row">
                                <div className="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                                    <span className="icon-home" aria-hidden="true">
                                      <i className="fa fa-map-marker" aria-hidden="true"></i>

                                    </span>
                                </div>
                                <div className="pdv-address-container col-lg-11 col-md-12 col-sm-12 col-xs-12">
                                    <p className="no-margin">{PdvDetail?.address}</p>
                                    <p className="no-margin">
                                        <span className=" zip-code">{PdvDetail?.postcode}</span>
                                        <span>{" "} {PdvDetail?.city}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="pdv-into-vo-info-pdv-adresse-tel-value desktop-phone-number col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="col-lg-1 col-md-2 col-sm-2 col-xs-2">
                                        <span className="icon-phone" aria-hidden="true">

                                            <i className="fa fa-phone" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12">
                                        <span className="telephone">{PdvDetail?.tel}</span>
                                    </div>
                                </div>

                            </div>
                            <div className="horaire row">
                                <div className="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                                    <span className="icon-clock" aria-hidden="true"></span>
                                </div>
                                <div className="detail col-lg-11 col-md-12 col-sm-12 col-xs-12">

                                        {PdvDetail?.hours.map((v,i) => {
                                           return(
                                               <div key={i} className="row">

                                                   <div  className="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                                                       {v.jour}
                                                   </div>
                                                   <div  className="col-md-4 col-lg-4 col-sm-4 col-xs-4 no-padding morning-hour">
                                                       {v.value}
                                                   </div>
                                               </div>
                                           )

                                        })}




                                </div>
                            </div>
            </div>

                    </div>
            </div>
            </div>
        </section>

            </>
    );
}

export default Pdvdetail;
