import {Result} from 'antd';
import React from 'react'

const failUrl = () => {

    const onGo = () => {
        window.location.href = "/";
    };

    return (
        <div>
            <br></br>

            <Result
                status="error"
                title={"Paiement Non Abouti."}
                subTitle={
                    "Votre paiement a rencontré un probleme et a echoué."
                }
                extra={[
                    <button onClick={onGo} type="submit" class="btn  bg-inc-g uppr">Retourner à l’accueil</button>
                    ,
                ]}
            />,
        </div>
    )
}

export default failUrl;
