import React, {useReducer} from "react";
import axios from "axios";

import AuthContext from "./authContext";
import authReducer from "./authReducer";

import {AUTH_ERROR, CLEAR_ERRORS,PROFILE_SUCCESS, LOAD_FAIL, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, USER_LOADED,} from "./types";
import qs from "qs";
import {checkResponse, getJWT} from "../../utils";
import {isEmpty} from "../../utils/validator";
import {useHistory} from "react-router-dom";

const AuthState = (props) => {
    let history = useHistory();
    const initialState = {
        isAuth: false,
        currentUser: null,
        error: null,
        currentProfil:null
    };

    const [state, dispatch] = useReducer(authReducer, initialState, () => {
        if (!isEmpty(getJWT())) initialState.isAuth = true;
        return initialState;
    });

    //  Login User
    const login = (formData) => {
        const options = {
            headers: {
                "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
            auth: {
                username: "browser", // This is the client_id
                password: "secret", // This is the client_secret
            },
        };

        try {
            return new Promise(async (resolve, reject) => {
                await axios
                    .post(
                        "/oauth/token",
                        qs.stringify({...formData, grant_type: "password"}),
                        options
                    )
                    .then(async (response) => {
                        if (checkResponse(response)) {
                            dispatch({
                                type: LOGIN_SUCCESS,
                                payload: response.data,
                            });
                            resolve(response.data);

                            if(response.data && response.data.authorities && response.data.authorities.includes("ADMIN") || response.data.authorities.includes("SUPER_ADMIN")){
                                window.location.href = "/backoffice";
                            } else {
                                window.location.href = "/";
                            }
                        }
                        //response.data.authorities.includes("USER") ||
                        await loadUser();
                    })
                    .catch((error) => {
                        dispatch({
                            type: LOGIN_FAIL,
                            payload: "Invalid Credentials",
                        });
                        reject(error);
                    });
            });
        } catch (err) {
            dispatch({
                type: AUTH_ERROR,
                payload: err,
            });
        }
    };

    //  Load User
    const loadUser = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios
                    .get("/auth/current")
                    .then((response) => {
                        if (checkResponse(response)) {
                            dispatch({
                                type: USER_LOADED,
                                payload: response.data,
                            });
                            resolve(response.data);
                        }
                    })
                    .catch((error) => {
                        !isEmpty(getJWT()) && localStorage.removeItem("access_token");
                        dispatch({
                            type: LOAD_FAIL,
                            payload: "User loaded fail",
                        });
                        reject(error);
                    });
            });
        } catch (err) {
            dispatch({
                type: AUTH_ERROR,
                payload: err,
            });
        }
    };




    //  Load PROFILE
    const loadProfile = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios
                    .get("/profile/current")
                    .then((response) => {
                        if (checkResponse(response)) {
                            dispatch({
                                type: PROFILE_SUCCESS,
                                payload: response.data,
                            });
                            resolve(response.data);
                        }
                    })
                    .catch((error) => {

                        reject(error);
                    });
            });
        } catch (err) {

        }
    };


    //  Logout
    const logout = () => {
        localStorage.removeItem("access_token");
        dispatch({
            type: LOGOUT,
        });
        window.location.href = '/'
    };


    //  Clear Errors
    const clearErrors = () => {
        dispatch({
            type: CLEAR_ERRORS,
        });
    };

    return (
        <AuthContext.Provider
            value={{
                isAuth: state.isAuth,
                currentUser: state.currentUser,
                isLoading: state.isLoading,
                error: state.error,
                currentProfil:state.currentProfil,
                loadUser,
                loadProfile,
                login,
                logout,
                clearErrors,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;
