import {isEmpty} from "./validator";
import {notification} from "antd";
import {v4 as uuid} from "uuid";
import zxcvbn from "zxcvbn";

export const camelCaseToNormal = (value) =>
    value.replace(/([A-Z])/g, " $1").replace(/^./, (str2) => str2.toUpperCase());

export const asyncHandler = (fn) => (formData, params) =>
    Promise.resolve(fn(formData, params));


export const errorResponseHandler = (err) => {
    return err.response &&
    err.response.data &&
    err.response.data.message &&
    !isEmpty(err.response.data.message)
        ? err.response.data.message
        : "Sorry, something went wrong. Please try again later.";
};

export const getJWT = () => localStorage.getItem("access_token");

export const removeJWT = () =>
    !isEmpty(getJWT()) && localStorage.removeItem("access_token");

export const checkResponse = (res) => res && res.status === 200 && res.data;

export const formatDataTable = (header, data) => {
    let columns = [];
    header.forEach((value) => {
        columns.push({
            dataIndex: value,
            title: value,
        });
    });
    let resultData = [];
    data.forEach((value) => {
        resultData.push({
            ...value,
            id: uuid(),
        });
    });
    return {columns, resultData};
};

export const openNotification = (type, message) => {
    notification[type]({
        message,
    });
};

export const sleep = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time);
    });
};

export const snapshotToArray = (snapshot) => {
    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;
        returnArr.push(item);
    });
    return returnArr;
};

export const validatePhone = (rule, val, callback) => {
    const specialCharRegex = new RegExp("(\\+212|0)([ \\-_/]*)(\\d[ \\-_/]*){9}");
    if (specialCharRegex.test(val)) {
        return Promise.resolve();
    }
    return Promise.reject('Le numéro de téléphone n\'est pas valide');
};

export const validatePassword = (rule, val, callback) => {
    const score = val.length === 0 ? -1 : zxcvbn(val).score;
    if (score && score >= 2) {
        return Promise.resolve();
    }
    return Promise.reject('Votre mot de passe doit comporter au moins 8 caractères et au moins un chiffre et une lettre');
};


export const layout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
};

export const buttonLayout = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 8, offset: 8},
        md: {span: 8, offset: 8},
    },
};
