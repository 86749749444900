import React, {useContext} from "react";
import {Redirect, Route,useHistory} from "react-router-dom";
import AuthContext from "../context/auth/authContext";

const PrivateRoute = ({component: Component, roles, ...rest}) => {
    const authContext = useContext(AuthContext);
    const {isAuth, currentUser,useHistory} = authContext;

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isAuth) {
                   // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{pathname: "/login", state: {from: props.location}}}
                        />
                    );
                }

                if (currentUser !== null) {
                    if (roles && roles.indexOf(currentUser.authorities[0]) === -1) {
                        // role not authorised so redirect to home page
                        return <Redirect to={{pathname: "/denied"}}/>;
                    }
                }
                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};
export default PrivateRoute;
