export const GET_ALL_FILTER = 'GET_ALL_FILTER';
export const GET_ALL_MARKS = "GET_ALL_MARKS";
export const Update_Mark_Succuss = "Update_Mark_Succuss";
export const Delete_Mark_Succuss = "Delete_Mark_Succuss";
export const GET_ALL_ModuleByMark = "GET_ALL_ModuleByMark";
export const GET_ALL_CategoriesByMarkByModel = "GET_ALL_CategoriesByMarkByModel";
export const UPLOAD_Succuss = "UPLOAD_Succuss";
export const Import_Succuss = "Import_Succuss"
export const Export_Succuss = "Export_Succuss"
export const UPLOAD_Multiple = "UPLOAD_Multiple";
export const UPLOAD_Voiture_Succuss = "UPLOAD_Voiture_Succuss";
export const GET_ALL_VOITURES = "GET_ALL_VOITURES";
export const UPDATE_VOITURE_SUCCUSS = "UPDATE_VOITURE_SUCCUSS";
export const DELETE_VOITURE_SUCCUSS = "UPDATE_VOITURE_SUCCUSS";
export const GET_VOITURE_SUCCUSS = "GET_VOITURE_SUCCUSS";
export const CONTACT_VOITURE_SUCCUSS = "CONTACT_VOITURE_SUCCUSS";

export const OFFRE_MOMENT = "OFFRE_MOMENT";

export const RESERVER_VOITURE = "RESERVER_VOITURE";


export const REVENDRE_VOITURE = "REVENDRE_VOITURE";


export const GET_RESERVATION_VOITURE = "GET_RESERVATION_VOITURE";


// Account

export const ACCOUNT_SUCCUSS = "ACCOUNT_SUCCUSS";


export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";

export const SEND_CONTACT_US = "SEND_CONTACT_US";

export const NEWSLETTER_CONTACT_US = "NEWSLETTER_CONTACT_US";


export const GET_ALL_PDV = "GET_ALL_PDV"
export const GET_PDV_BYID = "GET_PDV_BYID"


export const CLEAR_ERRORS = "CLEAR_ERRORS";
