import React, {useContext, useEffect, useState} from 'react'
import {Card, Select} from 'antd';
import {GoogleMap, Marker, withGoogleMap} from "react-google-maps"
import VoitureContext from '../context/voitures/voitureContext';
import City from '../utils/City';
import {BankOutlined, PhoneOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";

const {Option} = Select;


const Pdvpopup = (props) => {

    let history = useHistory();

    const voitureContext = useContext(VoitureContext);

    const {
        getPDV,
        allPDV

    } = voitureContext;

    const [resulrt, setresulrt] = useState();
    const [filter, setfilter] = useState(false);

    useEffect(() => {
        getPDV();

        setfilter(true);
        let dd = allPDV.filter((v) => {
            return v.city === props.city
        })
        setresulrt(dd);
        if (dd.length > 0) {
            let lat = parseFloat(dd[0].latitude, 10);
            let lng = parseFloat(dd[0].longitude, 10);
            setData({
                lat: lat,
                lng: lng
            })
        }

    }, [props.city]);

    console.log(props.city);

    const google = window.google;

    const [data, setData] = useState({
        lat: 33.573109,
        lng: -7.589843
    });

    const onMarkerClick = (ds) => {

        let lat = parseFloat(ds.latitude, 10);
        let lng = parseFloat(ds.longitude, 10);
        setData({
            lat: lat,
            lng: lng
        })

    };

    const handleChangePDV = (value) => {
        setfilter(true);
        let dd = allPDV.filter((v) => {
            return v.city === value
        })
        setresulrt(dd);

        let lat = parseFloat(dd[0].latitude, 10);
        let lng = parseFloat(dd[0].longitude, 10);
        setData({
            lat: lat,
            lng: lng
        })
    }

    const MyMapComponent = withGoogleMap((props) =>
        <GoogleMap
            zoom={8}
            center={data}


        >
            {props.isMarkerShown &&
            <>
                {resulrt &&
                resulrt.map((place, i) => {
                    let lat = parseFloat(place.latitude, 10);
                    let lng = parseFloat(place.longitude, 10);

                    return (
                        <Marker
                            onClick={() =>
                            {setSelectD(place.id); onMarkerClick(place)}}
                            id={place.id}
                            key={place.id}
                            position={{lat: lat, lng: lng}}
                        >
                        </Marker>
                    );
                })}


            </>

            }

        </GoogleMap>
    )


    const [selectD, setSelectD] = useState();
    const onGoDetails = (id) => {
         props.setPdvmodal(false);
        setSelectD(id);
    }


    return (
<>


                <div className='Pdvcontainter'>

                    <div className="map-dirc">
                        <div className="idmmap"
                        >
                            <MyMapComponent
                                isMarkerShown
                                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{height: `100%`}}/>}
                                containerElement={<div style={{height: `500px`, width: `700px`}}/>}
                                mapElement={<div style={{height: `100%`}}/>}
                            />
                        </div>

                        <div className='datamapc'>
                        <span className='stlsporMap'> {filter ? resulrt.length : allPDV.length} <span
                            className='sttylMap'>réponse(s) trouvée(s)</span></span>

                            {!filter ?
                                allPDV.map((v, i) => {

                                    return (
                                        <Card key={i} size="small" style={{width: 300, margin: 5,border: selectD === v.id ? '2px solid #ef6744' : ''}}>
                                            <p className='sttylsvMap'>{v.name}</p>
                                            <p className='abourdescMap'>
                                                <BankOutlined style={{marginRight:10}} />
                                                {v.address}{" "} {v.city}</p>
                                            <p className='abourdescMap'>
                                                <PhoneOutlined style={{marginRight:10}} />
                                                {v.tel}</p>

                                            <a onClick={() => onGoDetails(v.id)} className={"float-right upper"}>Sélectionner </a>
                                        </Card>

                                    )
                                })
                                :

                                resulrt.map((v, i) => {

                                    return (
                                        <Card key={i} size="small" style={{width: 300, margin: 5,border: selectD === v.id ? '2px solid #ef6744' : ''}}>
                                            <p className='sttylsvMap'>{v.name}</p>
                                            <p className='abourdescMap'>
                                                <BankOutlined style={{marginRight:10}} />
                                                {v.address}{" "} {v.city}</p>
                                            <p className='abourdescMap'>
                                                <PhoneOutlined style={{marginRight:10}} />
                                                {v.tel}</p>

                                            <a onClick={() => onGoDetails(v.id)} className={"float-right upper"}>Sélectionner </a>
                                        </Card>

                                    )
                                })
                            }


                        </div>

                    </div>
                </div>


</>
    );
}

export default Pdvpopup;