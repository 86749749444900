export const City =

        [{"name": "Casablanca"},
        {"name": "Agadir"},
        {"name": "Al Hoceima"},
        {"name": "Béni Mellal"},
        {"name": "El Jadida"},
        {"name": "Errachidia"},
        {"name": "Fès"},
        {"name": "Kénitra"},
        {"name": "Khénifra"},
        {"name": "Khouribga"},
        {"name": "Larache"},
        {"name": "Marrakech"},
        {"name": "Meknès"},
        {"name": "Nador"},
        {"name": "Ouarzazate"},
        {"name": "Oujda"},
        {"name": "Rabat"},
        {"name": "Safi"},
        {"name": "Settat"},
        {"name": "Salé"},
        {"name": "Tanger"},
        {"name": "Taza"},
        {"name": "Tétouan"}]

export default City;