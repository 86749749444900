import React, {useContext, useEffect, useState} from 'react'
import {Badge, Button, Empty, Form, message, Pagination, Select, Slider} from 'antd';
import City from '../utils/City';
import VoitureContext from "../context/voitures/voitureContext";
import LINKS from '../utils/Link';
import {useHistory} from "react-router-dom";
import {HeartFilled, HeartOutlined, MenuOutlined, TableOutlined} from "@ant-design/icons";
import {useShopCart} from '../contexts';
import './Main.css';

const {Option} = Select;
const Listning = (props) => {

    const voitureContext = useContext(VoitureContext);

    const {
        getMarks,
        filterDataResult,
        allmarks,
        filterDatas,
        filterData,
        allmodulesbymarque,
        getModuleByMark,
        rangemin,
        rangemax,

    } = voitureContext;

    let history = useHistory();

    const [formData, setformData] = useState({

        "city": null,
        "mark": null,
        "registrationYear": null,
        "minPrice": null,
        "maxPrice": null,
        "minMileage": null,
        "maxMileage": null,
        "model": null


    });


    const {cartValue, setCartValue} = useShopCart();

    const [form] = Form.useForm();


    React.useEffect(() => {
        form.setFieldsValue(props.data);
    }, [form, props.data]);


    useEffect(() => {
        getMarks();

        if (!props.searchType) {
            filterDatas(formData);
        }
    }, []);


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setpostsPerPage] = useState(10);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filterData.slice(indexOfFirstPost, indexOfLastPost);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(filterData.length / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const onChangePagination = (page) => {
        setCurrentPage(page);
    };

    const onShowSizeChange = (current, size) => {
        setpostsPerPage(size);
    };

    function handleChangeType(value) {
        setformData({...formData, mark: value});
        getModuleByMark(value);

    }


    function handleChangeType1(value) {
        setformData({...formData, model: value});

    }

    function handleChangeVille(value) {
        setformData({...formData, city: value});

    }

    const onSliderChange = (v) => {
        setformData({...formData, minPrice: v[0], maxPrice: v[1]});

    }

    const onSliderKMChange = (v) => {
        setformData({...formData, minMileage: v[0], maxMileage: v[1]});
    }

    function handleChangeAnnee(e) {
        setformData({...formData, registrationYear: e});
    }


    const onFilter = () => {

        filterDatas(formData).then((res) => {

            //   history.push("/Searchresult");
        })

    }


    const goToVoiture = (data) => {

        history.push({
            pathname: '/Voituredetail/' + data.id, data: data,
        });

    }

    const onAddToPanier = (v, t) => {
        if (t === "add") {
            if (v.status !== "PAYEE") {
                setCartValue([...cartValue, v]);
                message.info('Ajouté à mon panier');
            }


        } else {
            setCartValue(cartValue.filter(value => value.id !== v.id));
            message.info('Supprimé de mon panier');

        }
    }

    let yeard = new Date().getFullYear();
    let YearData = [];
    for (let index = 0; index < 35; index++) {
        YearData.push({id: index, year: yeard--})
    }


    const onChangeOrder = (e) => {

        if (e.target.value === 'ascendant') {

            filterDataResult(filterData.sort(function (a, b) {
                return b.promoPrice - a.promoPrice
            }));

        } else {
            filterDataResult(filterData.sort(function (a, b) {
                return a.promoPrice - b.promoPrice
            }));

        }


    }

    return (<>

            <section className="listing-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-md-push-3">
                            <div className="mobile_search">
                                <div className="sidebar_widget">
                                    <div className="widget_heading">
                                        <h5><i className="fa fa-filter" aria-hidden="true"/> FILTRE </h5>
                                    </div>
                                    <div className="sidebar_filter">
                                        <form action="#" method="get">
                                            <Form
                                                form={form}
                                            >

                                                <Form.Item
                                                    name="mark"

                                                >

                                                    <Select
                                                        showSearch
                                                        placeholder="Marque"
                                                        style={{width: '100%'}}
                                                        onChange={handleChangeType}>

                                                        {allmarks.map((v, i) => {

                                                            return (<Option key={i} value={v}>{v}</Option>

                                                            )
                                                        })}

                                                    </Select>

                                                </Form.Item>

                                                <Form.Item
                                                    name="model"

                                                >

                                                    <Select
                                                        showSearch
                                                        placeholder="Modèle"
                                                        style={{width: '100%'}}
                                                        onChange={handleChangeType1}>

                                                        {allmodulesbymarque.map((v, i) => {

                                                            return (<Option key={i} value={v}>{v}</Option>

                                                            )
                                                        })}

                                                    </Select>

                                                </Form.Item>


                                                <Form.Item
                                                    name="city"

                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Ville"
                                                        onChange={handleChangeVille}
                                                        style={{width: '100%'}}>

                                                        {City.map((v, i) => {
                                                            return (<Option key={i} value={v.name}>{v.name}</Option>

                                                            )
                                                        })}

                                                    </Select>

                                                </Form.Item>


                                                <Form.Item
                                                    name="registrationYear"

                                                >
                                                    <Select

                                                        allowClear
                                                        showSearch
                                                        placeholder="Année du modèle"
                                                        onChange={handleChangeAnnee}
                                                        style={{width: '100%'}}>

                                                        {YearData.map((v, i) => {
                                                            return (<Option key={i} value={v.year}>{v.year}</Option>

                                                            )
                                                        })}

                                                    </Select>


                                                </Form.Item>


                                                <Form.Item
                                                    name="minPrice"

                                                >
                                                    <label className="form-label">Prix (DH) </label>

                                                    {rangemin !== null && rangemax &&
                                                        <Slider onChange={onSliderChange} tooltipVisible
                                                                tooltipPlacement="bottom"
                                                                min={rangemin} max={rangemax} range
                                                                defaultValue={[rangemin, rangemax]}/>

                                                    }
                                                </Form.Item>
                                                <br/>
                                                <Form.Item
                                                    name="minMileage"

                                                >
                                                    <label className="form-label">Kilométrage (KM) </label>

                                                    <Slider onChange={onSliderKMChange} tooltipVisible min={0}
                                                            tooltipPlacement="bottom" max={175000} range
                                                            defaultValue={[0, 175000]}/>
                                                </Form.Item>


                                            </Form>

                                            <div className="form-group ">
                                                <br/>
                                            </div>
                                            <div className="form-group">
                                                <button type="button" onClick={onFilter}
                                                        className="btn btn-block bg-inc-g uppr">
                                                    FILTRER
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="result-sorting-wrapper">
                                <div className="sorting-count">

                                    {props.dataViewType && <>
                                        <Button style={{backgroundColor: '#88888'}}
                                                icon={<MenuOutlined style={{color: '#88888'}}/>}
                                                onClick={() => props.setdataViewType(false)}/>
                                        <Button style={{backgroundColor: '#ef6744'}}
                                                icon={<TableOutlined style={{color: 'white'}}/>}
                                                onClick={() => props.setdataViewType(true)}/>
                                    </>}

                                    {!props.dataViewType && <>
                                        <Button style={{backgroundColor: '#ef6744'}}
                                                icon={<MenuOutlined style={{color: 'white'}}/>}
                                                onClick={() => props.setdataViewType(false)}/>
                                        <Button style={{backgroundColor: '#88888'}}
                                                icon={<TableOutlined style={{color: '#88888'}}/>}
                                                onClick={() => props.setdataViewType(true)}/>
                                    </>}


                                </div>
                                <div className="result-sorting-by">
                                    <p>TRIER PAR</p>
                                    <form action="#" method="post">
                                        <div className="form-group select sorting-select">
                                            <select onChange={onChangeOrder} className="form-control">
                                                <option value="ascendant">Prix ascendant</option>
                                                <option value="descendant">Prix descendant</option>
                                            </select>
                                        </div>
                                    </form>

                                </div>
                            </div>

                            {currentPosts.length == 0 &&

                                <Empty/>}

                            {!props.dataViewType && currentPosts.map((v, i) => {

                                return (

                                    <Badge.Ribbon color={v.inOrder === true ? '#ef6744' : 'transparent'}
                                                  text={v.inOrder === true ? 'Réservé' : ''}>
                                        <div key={i} className="product-listing-m gray-bg displayItemFlex">
                                            <div className="product-listing-img">
                                                {v.inOrder ?

                                                    <a>
                                                        <img style={{opacity: "0.7"}}
                                                            src={LINKS.imagePath + v.mainPicture}
                                                            className="img-fluidD"
                                                            alt="Image"/>
                                                    </a> :
                                                    <a>
                                                        <img onClick={() => goToVoiture(v)}
                                                                                         src={LINKS.imagePath + v.mainPicture}
                                                                                         className="img-fluidD"
                                                                                         alt="Image"/>
                                                    </a>}
                                                {!v.inOrder ?

                                                    <div className="compare_item">
                                                        {cartValue.length > 0 && cartValue.find(value => value.id === v.id) ?
                                                            <HeartFilled onClick={() => onAddToPanier(v, "remove")}
                                                                         style={{color: '#ef6744', fontSize: 20}}/> :
                                                            <HeartOutlined onClick={() => onAddToPanier(v, "add")}
                                                                           style={{fontSize: 20}}/>}
                                                    </div> : null}
                                            </div>
                                            <div className="product-listing-content">

                                                <h5>
                                                    {v.inOrder ? <a>{v.mark}, {v.model}, {v.category}</a> :
                                                        <a onClick={() => goToVoiture(v)}
                                                           href="#">ss {v.mark}, {v.model}, {v.category}</a>}

                                                </h5>

                                                {

                                                }
                                                <p className="list-price">{new Intl.NumberFormat('fr-FR').format(v.promoPrice)}{" DH"}</p>

                                                <ul>
                                                    <li><i className="fa fa-road"
                                                           aria-hidden="true"/>{new Intl.NumberFormat('fr-FR').format(v.mileage)} {" KM"}
                                                    </li>
                                                    <li><i className="fa fa-user" aria-hidden="true"/>{v.carSeats}</li>
                                                    <li><i className="fa fa-calendar"
                                                           aria-hidden="true"/>{v.registrationDate}</li>
                                                    <li><i className="fa fa-car" aria-hidden="true"/>{v.fuel}</li>
                                                    <li><i className="fa fa-superpowers" aria-hidden="true"/>{v.gearBox}
                                                    </li>

                                                </ul>
                                                <div className='float-right'>
                                                    {v.inOrder ? <a style={{opacity: "0.7", color: "white"}}
                                                                    className="btn bg-inc-g uppr">Découvrir </a> :
                                                        <a onClick={() => goToVoiture(v)} href="#"
                                                           className="btn bg-inc-g uppr">Découvrir </a>}

                                                </div>

                                            </div>
                                        </div>
                                    </Badge.Ribbon>

                                )

                            })}

                            <div className="row">
                                {props.dataViewType && currentPosts.map((v, i) => {
                                    return (<div className="col-md-4 grid_listing">

                                            <div className="product-listing-m">
                                                <div className="product-listing-img"><a><img
                                                    onClick={() => goToVoiture(v)}
                                                    src={LINKS.imagePath + v.mainPicture} className="img-fluid"
                                                    alt="Image"/> </a>

                                                    <div className="compare_item">
                                                        {cartValue.length > 0 && cartValue.find(value => value.id === v.id) ?
                                                            <HeartFilled onClick={() => onAddToPanier(v, "remove")}
                                                                         style={{color: '#ef6744', fontSize: 20}}/> :
                                                            <HeartOutlined onClick={() => onAddToPanier(v, "add")}
                                                                           style={{fontSize: 20}}/>}
                                                    </div>
                                                </div>
                                                <div className="product-listing-content">
                                                    <h5 className='txthight2'><a
                                                        onClick={() => goToVoiture(v)}>{v.mark}, {v.category}</a></h5>
                                                    <p className="list-price">{new Intl.NumberFormat('fr-FR').format(v.promoPrice)}{" DH"}</p>
                                                    <div className="car-location"><span><i className="fa fa-map-marker"
                                                                                           aria-hidden="true"/>{" "}{v.city}</span>
                                                    </div>
                                                    <ul className="features_list">
                                                        <li><i className="fa fa-road"
                                                               aria-hidden="true"/>{new Intl.NumberFormat('fr-FR').format(v.mileage)} {" KM"}
                                                        </li>
                                                        <li><i className="fa fa-user" aria-hidden="true"/>{v.carSeats}
                                                        </li>
                                                        <li><i className="fa fa-car" aria-hidden="true"/>{v.fuel}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    )

                                })}

                            </div>

                            <div>
                                <Pagination
                                    onShowSizeChange={onShowSizeChange}
                                    showSizeChanger
                                    current={currentPage}
                                    onChange={onChangePagination}
                                    total={filterData.length}
                                />
                            </div>
                        </div>


                        <aside className="col-md-3 col-md-pull-9">
                            <div className="sidebar_widget sidebar_search_wrap">
                                <div className="widget_heading">
                                    <h5><i className="fa fa-filter" aria-hidden="true"/>FILTRE
                                    </h5>
                                </div>
                                <div className="sidebar_filter">
                                    <form action="#" method="get">
                                        <div className="form-group ">

                                            <Form
                                                form={form}
                                            >

                                                <Form.Item
                                                    name="mark"

                                                >

                                                    <Select
                                                        allowClear
                                                        showSearch
                                                        placeholder="Marque"
                                                        style={{width: '100%'}}
                                                        onChange={handleChangeType}>

                                                        {allmarks.map((v, i) => {

                                                            return (<Option key={i} value={v}>{v}</Option>

                                                            )
                                                        })}

                                                    </Select>

                                                </Form.Item>

                                                <Form.Item
                                                    name="model"

                                                >

                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder="Modèle"
                                                        style={{width: '100%'}}
                                                        onChange={handleChangeType1}>

                                                        {allmodulesbymarque.map((v, i) => {

                                                            return (<Option key={i} value={v}>{v}</Option>

                                                            )
                                                        })}

                                                    </Select>

                                                </Form.Item>


                                                {/*     <Form.Item
                                                    name="city"

                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder="Ville"
                                                        onChange={handleChangeVille}
                                                        style={{width: '100%'}}>

                                                        {City.map((v, i) => {
                                                            return (
                                                                <Option key={i} value={v.name}>{v.name}</Option>

                                                            )
                                                        })}

                                                    </Select>

                                                </Form.Item>*/}


                                                <Form.Item
                                                    name="registrationYear"

                                                >

                                                    <Select

                                                        allowClear
                                                        showSearch
                                                        placeholder="Année du modèle"
                                                        onChange={handleChangeAnnee}
                                                        style={{width: '100%'}}>

                                                        {YearData.map((v, i) => {
                                                            return (<Option key={i} value={v.year}>{v.year}</Option>

                                                            )
                                                        })}

                                                    </Select>


                                                </Form.Item>


                                                <Form.Item
                                                    name="minPrice"

                                                >

                                                    <label className="form-label">Prix (DH) </label>

                                                    {rangemin !== null && rangemax &&
                                                        <Slider onChange={onSliderChange} tooltipVisible
                                                                tooltipPlacement="bottom"
                                                                min={rangemin} max={rangemax} range
                                                                defaultValue={[rangemin, rangemax]}/>

                                                    }
                                                </Form.Item>
                                                <br/>
                                                <Form.Item
                                                    name="minMileage"

                                                >
                                                    <label className="form-label">Kilométrage (KM) </label>

                                                    <Slider onChange={onSliderKMChange} tooltipVisible min={0}
                                                            tooltipPlacement="bottom" max={175000} range
                                                            defaultValue={[0, 175000]}/>
                                                </Form.Item>


                                            </Form>

                                        </div>


                                        <div className="form-group ">
                                            <br/>
                                        </div>
                                        <div className="form-group">
                                            <button type="button" onClick={onFilter}
                                                    className="btn btn-block bg-inc-g uppr">
                                                FILTRER
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>


                        </aside>

                    </div>
                </div>
            </section>


        </>)
}

export default Listning;
