import React, {useContext, useEffect} from 'react'

import './Main.css';

import {Badge, message} from 'antd';
import VoitureContext from "../context/voitures/voitureContext";
import LINKS from '../utils/Link';
import {useHistory} from "react-router-dom";
import {HeartFilled, HeartOutlined} from '@ant-design/icons';
import {useShopCart} from '../contexts';

import Slider from "react-slick";


const Featured = () => {


    let history = useHistory();

    const voitureContext = useContext(VoitureContext);

    const {cartValue, setCartValue} = useShopCart();

    const {
        Offresmoment,
        offresmoment,
    } = voitureContext;

    useEffect(() => {
        Offresmoment();
        console.log("offresmoment",Offresmoment);

    }, []);


    const onAddToPanier = (v, t) => {
        if (t === "add") {
            setCartValue([...cartValue, v]);
            message.info('Ajouté à mon panier');

        } else {
            setCartValue(cartValue.filter(value => value.id !== v.id));
            message.info('Supprimé de mon panier');

        }
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: offresmoment?.length === 1 ? 1 : offresmoment?.length === 2 ? 2 : 3 ,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            // {
            //     breakpoint: 1024,
            //     settings: {
            //         slidesToShow: 3,
            //         slidesToScroll: 3,
            //         infinite: true,
            //         dots: true
            //     }
            // },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const goToVoiture = (data) => {

        history.push({
            pathname: '/Voituredetail/' + data.id,
            data: data,
        });

    }

    return (
        <section className="section-padding">


            <div className="container">
                <div className="section-header text-center">
                    <h2 className='stlspor'><span className="sttyl">OFFRES DU MOMENT </span> </h2>

                </div>


                <Slider {...settings}>
                    {offresmoment.map((v, i) => {

                        return (

                            <div key={i} className="col-list-3">


                                {v.inOrder ?

                                    <Badge.Ribbon
                                        color='#ef6744'
                                        text={
                                            v.inOrder ? 'Réservé' : null
                                        }
                                    >

                                        <div className="featured-car-list">
                                            <div className="featured-car-img text-center  heightAuto"><a href=""><img

                                                src={LINKS.imagePath + v.mainPicture}
                                                className="img-fluid-VB" alt="Image"/></a>
                                           {/*     <div className="compare_item">
                                                    {cartValue.length > 0 && cartValue.find(value => value.id === v.id)
                                                        ?
                                                        <HeartFilled onClick={() => onAddToPanier(v, "remove")}
                                                                     style={{color: '#ef6744', fontSize: 20}}/>
                                                        :
                                                        <HeartOutlined onClick={() => onAddToPanier(v, "add")}
                                                                       style={{fontSize: 20}}/>
                                                    }
                                                </div>*/}
                                            </div>
                                            <div  className="featured-car-content">
                                                <h6 className='txthight'><a>{v.mark}, {v.model}, {v.category}</a></h6>
                                                <div className="price_info">
                                                    <p className="featured-price">{new Intl.NumberFormat('fr-FR').format(v.promoPrice)}{" DH"}</p>
                                                {/*    <div className="car-location"><span><i className="fa fa-map-marker"
                                                                                       aria-hidden="true"></i> {v.city}</span>
                                                    </div>*/}
                                                </div>
                                                <ul>
                                                    <li><i className="fa fa-road"
                                                           aria-hidden="true"></i>{new Intl.NumberFormat('fr-FR').format(v.mileage)} {" KM"}
                                                    </li>
                                                    <li><i className="fa fa-calendar"
                                                           aria-hidden="true"></i>{v.registrationDate}</li>
                                                    <li><i className="fa fa-car" aria-hidden="true"></i>{v.fuel}</li>
                                                    <li><i className="fa fa-user" aria-hidden="true"></i>{v.carSeats}</li>
                                                    <li><i className="fa fa-superpowers" aria-hidden="true"></i>{v.gearBox}
                                                    </li>
                                                </ul>
                                                <div className="text-center">
                                                    <button disabled className="btn  bg-inc-g uppr">Déjà Réservé
                                                    </button>
                                                </div>
                                            </div>


                                        </div>
                                    </Badge.Ribbon>

                                    : <div className="featured-car-list">
                                        <div className="featured-car-img text-center heightAuto"><a href=""><img
                                            onClick={() => goToVoiture(v)}
                                            src={LINKS.imagePath + v.mainPicture}
                                            className="img-fluid-VB" alt="Image"/></a>
                                            <div className="compare_item">
                                                {cartValue.length > 0 && cartValue.find(value => value.id === v.id)
                                                    ?
                                                    <HeartFilled onClick={() => onAddToPanier(v, "remove")}
                                                                 style={{color: '#ef6744', fontSize: 20}}/>
                                                    :
                                                    <HeartOutlined onClick={() => onAddToPanier(v, "add")}
                                                                   style={{fontSize: 20}}/>
                                                }
                                            </div>
                                        </div>
                                        <div onClick={() => goToVoiture(v)} className="featured-car-content">
                                            <h6 className='txthight'><a href="#">{v.mark}, {v.model}, {v.category}</a></h6>
                                            <div className="price_info">
                                                <p className="featured-price">{new Intl.NumberFormat('fr-FR').format(v.promoPrice)}{" DH"}</p>
                                              {/*  <div className="car-location"><span><i className="fa fa-map-marker"
                                                                                   aria-hidden="true"></i> {v.city}</span>
                                                </div>*/}
                                            </div>
                                            <ul>
                                                <li><i className="fa fa-road"
                                                       aria-hidden="true"></i>{new Intl.NumberFormat('fr-FR').format(v.mileage)} {" KM"}
                                                </li>
                                                <li><i className="fa fa-calendar"
                                                       aria-hidden="true"></i>{v.registrationDate}</li>
                                                <li><i className="fa fa-car" aria-hidden="true"></i>{v.fuel}</li>
                                                <li><i className="fa fa-user" aria-hidden="true"></i>{v.carSeats}</li>
                                                <li><i className="fa fa-superpowers" aria-hidden="true"></i>{v.gearBox}</li>
                                            </ul>
                                            <div className="text-center">
                                                <button onClick={() => goToVoiture(v)} type="submit"
                                                        className="btn  bg-inc-g uppr">En profiter
                                                </button>
                                            </div>
                                        </div>
                                    </div>}
                            </div>

                        )
                    })}
                </Slider>


            </div>
        </section>

    )
}

export default Featured;
