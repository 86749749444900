import React from "react";
import {asyncHandler, checkResponse, errorResponseHandler, openNotification,} from "../utils";
import axios from "axios";

const ProfilesContext = React.createContext();
const {Provider} = ProfilesContext;

const initState = {
    current: null,
    status: null,
    filtered: [],
    profiles: [],
    contacts: [],
    loading: false,
};
const ProfilesProvider = ({children}) => {
    const [state, setState] = React.useState(initState);

    const loadContacts = asyncHandler(async () => {
        initialLoading();
        return await axios
            .get("/users")
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        contacts: res.data,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const loadProfiles = asyncHandler(async () => {
        initialLoading();
        return await axios
            .get("/profile")
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        profiles: res.data,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const newProfile = asyncHandler(async (formData) => {
        initialLoading();
        return await axios
            .post("/profile", formData)
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const newInternalProfile = asyncHandler(async (formData) => {
        initialLoading();
        return await axios
            .post("/profile/internal", formData)
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                    loadProfiles();
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const editProfile = asyncHandler(async (formData) => {
        initialLoading();
        const id = formData.profileId;
        return await axios
            .put(`/profile/${id}`, formData)
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        profiles: prevState.profiles.map((profile) =>
                            profile.profileId === id ? res.data : profile
                        ),
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const deleteProfileById = asyncHandler(async (id) => {
        initialLoading();
        return await axios
            .delete(`/profile/${id}`)
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        profiles: prevState.profiles.filter(
                            (profile) => profile.profileId !== id
                        ),
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const setCurrent = (current) => {
        setState((prevState) => ({
            ...prevState,
            current,
        }));
    };

    const clearCurrent = () => {
        setState((prevState) => ({
            ...prevState,
            current: null,
        }));
    };

    const filterProfiles = (payload) => {
        setState((prevState) => ({
            ...prevState,
            filtered: prevState.profiles.filter((profile) => {
                const regex = new RegExp(`${payload}`, "gi");
                return (
                    profile.firstName.match(regex) ||
                    profile.lastName.match(regex) ||
                    profile.email.match(regex)
                );
            }),
        }));
    };

    const clearFilter = () => {
        setState((prevState) => ({
            ...prevState,
            filtered: [],
        }));
    };

    const handleErrorResponse = (err) => {
        err = errorResponseHandler(err);
        setState((prevState) => ({
            ...prevState,
            error: errorResponseHandler(err),
            loading: false,
        }));
        openNotification("error", err);
    };

    const initialLoading = () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
    };

    return (
        <Provider
            value={{
                ...state,
                newProfile,
                newInternalProfile,
                deleteProfileById,
                filterProfiles,
                clearCurrent,
                loadContacts,
                loadProfiles,
                editProfile,
                clearFilter,
                setCurrent,
            }}
        >
            {children}
        </Provider>
    );
};

export {ProfilesContext, ProfilesProvider};
