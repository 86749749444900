import React from 'react'
import {Route} from 'react-router-dom'
import DemandeReservation from '../components/account/DemandeReservation'
import Login from '../components/account/Login'
import MesReservation from '../components/account/MesReservation'
import MesSelections from '../components/account/MesSelections'
import Registration from '../components/account/Registration'
import Verify from '../components/account/Verify'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import failUrl from '../components/payment/failUrl'
import okUrl from '../components/payment/okUrl'
import PDV from '../components/PDV'
import Revendre from '../components/Revendre'
import SearchResult from '../components/SearchResult'
import Simulator from '../components/Simulator'
import ViewDetail from '../components/ViewDetail'
import Home from './Home';
import ContactUs from '../components/headerpages/ContactUs'
import FAQ from '../components/headerpages/FAQ'
import ValidationPage from '../components/account/ValidationPage'
import {Engaements} from '../components/headerpages/Engaements'
import Pdvdetail from "../components/Pdvdetail";
import {GuideAchat} from "../components/headerpages/GuideAchat";
import MotDePassOub from "../components/account/MotDePassOub";
import EditProfile from "../components/account/EditProfile";
import VerifyPassword from "../components/account/VerifyPassword";
import PrivateRoute from "../routes/PrivateRoute";
import WhoAreWe from '../components/headerpages/WhoAreWe'

const Layout = () => {

    return (
        <div>
            <div>
                <Navbar/>
                <Route exact path="/" component={Home}/>
                <Route exact path="/voitures-occasion" component={SearchResult}/>
                <Route exact path="/Voituredetail/:id" component={ViewDetail}/>
                <Route exact path="/Pointsdevente" component={PDV}/>
                <Route exact path="/Simulator" component={Simulator}/>
                <Route exact path="/Simulator/:id" component={Simulator}/>
                <Route exact path="/Reprise" component={Revendre}/>

                <Route exact path="/create-account" component={Registration}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/forgot-password" component={MotDePassOub}/>
                <PrivateRoute exact path="/edit-profil" component={EditProfile}/>

                <Route exact path="/ma-selection-de-vehicules" component={MesSelections}/>
                <PrivateRoute exact path="/demande-reservation/:id" component={DemandeReservation}/>
                <PrivateRoute exact path="/reservations" component={MesReservation}/>

                <Route exact path="/verify" component={Verify}/>

                <Route exact path="/reset" component={VerifyPassword}/>

                <PrivateRoute exact path="/payment/ok" component={okUrl}/>

                <PrivateRoute exact path="/payment/fail" component={failUrl}/>

                <Route exact path="/contact-us" component={ContactUs}/>
                <Route exact path="/FAQ" component={FAQ}/>
                <Route exact path="/ENGAGEMENTS" component={Engaements}/>
                <Route exact path="/notre-guide-d-achat-occasion" component={GuideAchat}/>

                <Route exact path="/validationPage" component={ValidationPage}/>

                <Route exact path="/pdvdetail/:id" component={Pdvdetail}/>
                <Route exact path="/WhoAreWe" component={WhoAreWe}/>


                <Footer/>
            </div>
        </div>
    )
}

export default Layout
