import React from 'react';

const PaymentForm = ({paymentForm}) => <form name="paymentForm" method="post" action={paymentForm.url}>
    <input type="hidden" name="clientid" value={paymentForm.form.clientid}/>
    <input type="hidden" name="amount" value={paymentForm.form.amount}/>
    <input type="hidden" name="okUrl" value={paymentForm.form.okUrl}/>
    <input type="hidden" name="failUrl" value={paymentForm.form.failUrl}/>
    <input type="hidden" name="TranType" value={paymentForm.form.TranType}/>
    <input type="hidden" name="callbackUrl" value={paymentForm.form.callbackUrl}/>
    <input type="hidden" name="shopurl" value={paymentForm.form.shopurl}/>
    <input type="hidden" name="currency" value={paymentForm.form.currency}/>
    <input type="hidden" name="storetype" value={paymentForm.form.storetype}/>
    <input type="hidden" name="lang" value={paymentForm.form.lang}/>
    <input type="hidden" name="hashAlgorithm" value={paymentForm.form.hashAlgorithm}/>
    <input type="hidden" name="sessiontimeout" value={paymentForm.form.sessiontimeout}/>
    <input type="hidden" name="BillToName" value={paymentForm.form.BillToName}/>
    <input type="hidden" name="BillToStreet1" value={paymentForm.form.BillToStreet1}/>
    <input type="hidden" name="BillToCity" value={paymentForm.form.BillToCity}/>
    <input type="hidden" name="BillToStateProv" value={paymentForm.form.BillToStateProv}/>
    <input type="hidden" name="BillToPostalCode" value={paymentForm.form.BillToPostalCode}/>
    <input type="hidden" name="BillToCountry" value={paymentForm.form.BillToCountry}/>
    <input type="hidden" name="email" value={paymentForm.form.email}/>
    <input type="hidden" name="tel" value={paymentForm.form.tel}/>
    <input type="hidden" name="encoding" value={paymentForm.form.encoding}/>
    <input type="hidden" name="oid" value={paymentForm.form.oid}/>
    <input type="hidden" name="hash" value={paymentForm.form.hash}/>
</form>

export default PaymentForm;
// <input type="hidden" name="AutoRedirect" value={paymentForm.form.AutoRedirect}/>
