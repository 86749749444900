import React, {useEffect, useState} from "react";
import Axios from 'axios';
import {useLocation} from "react-router-dom";
import {Result} from 'antd';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Verify = () => {

    const [valide, setvalide] = useState(false);

    let query = useQuery();

    useEffect(() => {
        let Data = [];

        Data.push({
            token: query.get("token"),
        });

        Axios.post('/verify', Data[0])
            .then((res) => {
                if (res.data.status === 200) {
                    setvalide(true);
                } else {
                }
            }).catch((err) => {
            setvalide(false);
        })
            .catch((err) => {
                setvalide(false);
            });
    }, []);

    const onGo = () => {
        window.location.href = "/login";
    };


    return (
        <div>
            <br></br> <br></br>
            <Result
                status={valide ? "success" : "error"}
                title={valide ? "VOTRE COMPTE EST MAINTENANT ACTIVÉ !" : "Vérification de l'e-mail non valide"}
                subTitle={
                    valide ? "Vous pouvez dès à présent vous connecter sur le site avec vos identifiants . Cliquez sur le lien ci-dessous pour accéder à votre espace personnel."
                        : "Vérification de l'e-mail non valide"
                }
                extra={[
                    <button onClick={onGo} type="submit" class="btn  bg-inc-g">CONTINUER</button>
                    ,
                ]}
            />,
        </div>
    )
}

export default Verify
