import React, { useContext, useEffect, useState,useLayoutEffect } from 'react';
import { Card, Checkbox, Col, Form, Input, Modal, Row, Select, Slider, Spin, Steps } from "antd";
import { isEmpty } from "../utils/validator";
import axios from "axios";
import { buttonLayout, checkResponse, errorResponseHandler, openNotification, validatePhone } from "../utils";
import VoitureContext from '../context/voitures/voitureContext';
import SimpleBar from 'simplebar-react';
import LINKS from '../utils/Link';
import Swal from "sweetalert2";
import AuthContext from '../context/auth/authContext';

const { Step } = Steps;
const { Meta } = Card;
const sheets = ["Salaf Contact Particuliers"];
const Simulator = (props) => {

    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);
    const [isReserve, setIsReserve] = useState(false);
    const { isAuth, loadProfile, currentProfil } = authContext;
    const [infoUser, setInfoUser] = useState(null);
    const [testValue, setTestValue] = useState();

/*    useEffect(() => {

    }, []);*/
    const [formData, setFormData] = useState({
        firstName: null,
        lastName: null,
        email: currentProfil?.email,
        phone: currentProfil?.phone,
        type: 'FINANCE',
        vehicle: null
    });




     useEffect( () => {
         if (currentProfil === null) {
             loadProfile().then(function(result) {

                 setFormData({
                     firstName: result.firstName,
                     lastName: result.lastName,
                     email: result.email,
                     phone: result.phone,
                     type: 'FINANCE',
                     vehicle: null
                 });
                 console.log("0000 : ",formData);
                 setTestValue( {
                     firstName: result.firstName,
                     lastName: result.lastName,
                     email: result.email,
                     phone: result.phone,
                     type: 'FINANCE',
                     vehicle: null
                 })
                 console.log("test value :", testValue)
             })


         }

         console.log("data formData",formData);
         let data =loadProfile();
        data.then(function(result) {
                setInfoUser(result);
                console.log("data user",result);
                console.log("data 2",infoUser);

                let data2 ={
                    firstName: result?.firstName,
                    lastName: result?.lastName,
                    email: result?.email,
                    phone: result?.phone,
                    type: 'FINANCE',
                    vehicle: null
                }

            setFormData(data2);
            console.log("data formData2",data2);
            console.log("data formData",formData);

        })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoUser!=null  ]);
    const [agreementModal, setAgreementModal] = useState({
        visible: false,
        checked: false
    });

    const [loading, setLoading] = useState(false);



    const changeAccount = (key, value) => {
        let keys = key.split(".");
        let formDataS = formData;
        if (keys.length === 1) {
            formDataS[keys[0]] = value;
        } else if (keys.length === 2) {
            formDataS[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
            formDataS[keys[0]][keys[1]][keys[2]] = value;
        }
        setFormData({ ...formDataS });
    };

    const voitureContext = useContext(VoitureContext);
    const {
        voituredata,
        AddContact,
        getVoitureById
    } = voitureContext;

    const [state, setState] = React.useState({
        current: 0,
        sheet: null,
        amount: 0,
        contribution: 0,
        period: 6,
        creditAmount: 0,
        fileCost: 0,
        monthly: 0,
        insuranceDI: 0,
        messages: [],
        loading: false,
    });

    useEffect(() => {

        getVoitureById(props.match.params.id);

        if (voituredata !== null) {
            setState({ ...state, amount: Number(voituredata.promoPrice) })
        }
    }, [props.match.params.id, voituredata !== null]);


    const fetchData = async (value) => {
        const values = {
            ...state,
            ...value
        };
        setState(values);

    }

    const handleErrorResponse = (err) => openNotification("error", errorResponseHandler(err));
    const [done, setDone] = useState(false);
    const [impr, setImpr] = useState(false);

    const onFilter = async () => {
        if (!isEmpty(state)) {
            setLoading(true);
            await axios
                .post("/simulator/generate", { ...state, amount: voituredata !== null ? voituredata.promoPrice : state.amount })
                .then((res) => {
                    if (checkResponse(res)) {
                        setState((prevState) => ({
                            ...prevState,
                            ...res.data,
                            loading: false,
                        }));
                        form.setFieldsValue(res.data);
                        setDone(true);
                        setLoading(false);
                    }
                    return res;
                })
                .catch(handleErrorResponse);
        }

    }


    const onFinish = (values) => {
        console.log(values);
        next();
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);

    };
    const getRequest = () => {
        if (isAuth) {
            window.location.href = '/demande-reservation/' + voituredata.id
        } else {
            setIsReserve(true);
        }
    }

    const onIMP = async (t) => {

        if (!isEmpty(state)) {
            setLoading(true);
            await axios
                .post("/simulator", { ...state, ...formData, amount: voituredata.promoPrice, sendMail: t, vehicleId: props.match.params.id, vehicle: voituredata })
                .then((res) => {
                    if (checkResponse(res)) {
                        setState((prevState) => ({
                            ...prevState,
                            ...res.data,
                            loading: false,
                        }));
                        form.setFieldsValue(res.data);
                        AddContact({ ...formData, sendMail: t, vehicleId: props.match.params.id, vehicle: voituredata })
                            .then(() => {
                                setDone(true);
                            }).then(() => {
                                if (!t) {
                                    window.open(LINKS.SimiliPath + res.data, "_blank");
                                }
                                else{
                                    Swal.fire({
                                        text: "Votre simulation a bien été envoyée sur votre adresse email.",
                                        icon: 'success',

                                        showCloseButton: true
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            // window.location.href = '/'

                                        }
                                    });
                                }
                                setImpr(true);


                            }).catch((err) => {
                                Swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text: err.response.data.details,
                                });

                            });
                        setLoading(false);
                    }
                    return res;
                })
                .catch(handleErrorResponse);
        }

    }

    /*
    *
        */
    const steps = [
        {
            title: 'Remplir le formulaire',
            content: <>
                <div className="PersonnelInfosForm">
                    <p className='SimTitle lower' style={{ textTransform: 'none' }}>Veuillez compléter le formulaire suivant pour commencer votre simulation</p>
                    <form className="el-form">
                        <div className="el-row">

                            <Form
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                { formData && formData.lastName && (
                                <Form.Item

                                    labelAlign="left"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 12 }}
                                    label={<>
                                        Nom <span style={{ color: 'red' }}>&nbsp;*</span>
                                    </>}
                                    name="Nom"
                                    rules={!formData.lastName ? [
                                        {
                                            required: true,
                                            message: 'Veuillez renseigner le champ obligatoire',
                                        },
                                        {
                                            required: true,
                                            message: 'Veuillez renseigner le champ obligatoire',
                                            pattern: new RegExp("^[^\\s]+[-a-zA-Z\\s]+([-a-zA-Z]+)*$")
                                        }
                                    ]:""}
                                >
                                    <Input type="text"
                                           defaultValue={formData?.lastName}
                                           placeholder='Nom'
                                        value={formData.lastName}
                                        onChange={(e) =>
                                            changeAccount("lastName", e.target.value)
                                        }
                                    />
                                </Form.Item>
                                ) }

                                { formData && formData.lastName==null && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label={<>
                                            Nom <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </>}
                                        name="Nom"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez renseigner le champ obligatoire',
                                            },
                                            {
                                                required: true,
                                                message: 'Veuillez renseigner le champ obligatoire',
                                                pattern: new RegExp("^[^\\s]+[-a-zA-Z\\s]+([-a-zA-Z]+)*$")
                                            }
                                        ]}
                                    >
                                        <Input type="text"
                                               placeholder='Nom'
                                               value={formData.lastName}
                                               onChange={(e) =>
                                                   changeAccount("lastName", e.target.value)
                                               }
                                        />
                                    </Form.Item>
                                ) }
                                { formData && formData.firstName &&
                                (
                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 12 }}
                                    label={<>
                                        Prénom <span style={{ color: 'red' }}>&nbsp;*</span>
                                    </>}
                                    name="Prenom"
                                    rules={!formData.firstName ? [
                                        {
                                            required: true,
                                            message: 'Veuillez renseigner le champ obligatoire',
                                        }
                                        ,
                                        {
                                            required: true,
                                            message: 'Veuillez renseigner le champ obligatoire',
                                            pattern: new RegExp("^[^\\s]+[-a-zA-Z\\s]+([-a-zA-Z]+)*$")
                                        }
                                    ] : ""}

                                >
                                    {/*       rules={[
                                        {
                                            required: true,
                                            message: 'Veuillez renseigner le champ obligatoire',
                                        }
                                        ,
                                        {
                                            required: true,
                                            message: 'Veuillez renseigner le champ obligatoire',
                                            pattern: new RegExp("^[^\\s]+[-a-zA-Z\\s]+([-a-zA-Z]+)*$")
                                        }
                                    ]}*/}
                                    <Input
                                        defaultValue={formData?.firstName}
                                        placeholder='Prénom'
                                        value={formData?.firstName}
                                        onChange={(e) =>
                                            changeAccount("firstName", e.target.value)
                                        }
                                    />
                                </Form.Item>
                                ) }

                                { formData && formData.firstName==null &&
                                (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label={<>
                                            Prénom <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </>}
                                        name="Prenom"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez renseigner le champ obligatoire',
                                            }
                                            ,
                                            {
                                                required: true,
                                                message: 'Veuillez renseigner le champ obligatoire',
                                                pattern: new RegExp("^[^\\s]+[-a-zA-Z\\s]+([-a-zA-Z]+)*$")
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder='Prénom'
                                            value={formData.firstName}
                                            onChange={(e) =>
                                                changeAccount("firstName", e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                ) }

                                {currentProfil &&
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label={<>
                                            E-mail <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </>}
                                        name="E-mail"
                                        rules={currentProfil.email ? [
                                            {
                                                type: 'email',
                                                message: 'Email n\'est pas un email valide'
                                            },
                                        ] : [
                                                {
                                                    type: 'email',
                                                    message: 'Email n\'est pas un email valide'
                                                },
                                                {
                                                    required: true,
                                                    message: 'Veuillez renseigner le champ obligatoire',
                                                },
                                            ]}
                                    >
                                        <Input
                                            defaultValue={currentProfil && currentProfil?.email}
                                            placeholder='E-mail'
                                            valrefue={formData.email}
                                            onChange={(e) =>
                                                changeAccount("email", e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                }
                                {currentProfil === null &&
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label={<>
                                            E-mail <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </>}
                                        name="E-mail"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Email n\'est pas un email valide'
                                            },
                                            {
                                                required: true,
                                                message: 'Veuillez renseigner le champ obligatoire',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder='E-mail'
                                            value={formData.email}
                                            onChange={(e) =>
                                                changeAccount("email", e.target.value)
                                            }
                                        />
                                    </Form.Item>}
                                {currentProfil &&
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label={<>
                                            Téléphone <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </>}
                                        name="telephone"
                                        rules={!currentProfil.phone ? [
                                            {
                                                required: true,
                                                message: 'Veuillez renseigner le champ obligatoire',
                                            }, { validator: validatePhone }
                                        ] : ""}
                                    >
                                        <Input
                                            defaultValue={currentProfil && currentProfil?.phone}
                                            placeholder='Téléphone'
                                            value={formData.phone}
                                            onChange={(e) =>
                                                changeAccount("phone", e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                }
                                {currentProfil === null &&
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label={<>
                                            Téléphone <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </>}
                                        name="telephone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez renseigner le champ obligatoire',
                                            }, { validator: validatePhone }
                                        ]}
                                    >
                                        <Input
                                            placeholder='Téléphone'
                                            value={formData.phone}
                                            onChange={(e) =>
                                                changeAccount("phone", e.target.value)
                                            }
                                        />
                                    </Form.Item>}
                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 12 }}
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject('Prière accepter les mentions légales'),
                                        },
                                    ]}
                                    {...buttonLayout}
                                >
                                    <Checkbox onClick={() => setAgreementModal({ ...agreementModal, visible: true })}
                                        checked={agreementModal.checked}>
                                        <span> J'accepte les mentions légales</span><span
                                            style={{ color: '#88888' }}>*</span>
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 12 }}
                                    {...buttonLayout}>

                                    <button type="submit" className="btn  bg-inc-g uppr"> Je simule mon crédit

                                    </button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="el-form-item conditions is-required">
                        </div>
                    </form>
                </div>
            </>
        },
        {
            title: 'Choisir un apport et une durée',
            content: <div style={{ margin: 20 }}>
                <Spin spinning={loading} size={"large"}>
                    <Form onFinish={onFilter} form={form} onValuesChange={fetchData} horizontal>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            labelAlign="left"
                            rules={[
                                {
                                    required: true,
                                    message: 'Veuillez renseigner le champ obligatoire',
                                },
                            ]}
                            name="sheet" label="OFFRES SPECIAL OCCASION">
                            <Select style={{ width: 300 }} placeholder="Votre choix">
                                {
                                    sheets.map((value, index) => <Select.Option key={index}
                                        value={value}>{value}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>

                        {voituredata !== null ? null :

                            <Row style={{ width: '100%' }}>
                                <Col span={20}>
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 14 }}
                                        wrapperCol={{ span: 14 }}
                                        name="amount" label="Prix du véhicule TTC">
                                        <Slider
                                            min={5000}
                                            max={200000}
                                            step={1000}
                                            defaultValue={voituredata !== null ? Number(voituredata.promoPrice) : 0}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        wrapperCol={{ span: 24 }}
                                        name="amount"
                                    >
                                        <Input type="number"
                                            defaultValue={voituredata !== null ? Number(voituredata.promoPrice) : 0}
                                            style={{ width: 126 }}
                                            suffix="DH"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                        }

                        <Row style={{ width: '100%' }}>
                            <Col span={20}>
                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 14 }}
                                    wrapperCol={{ span: 14 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Veuillez renseigner le champ obligatoire',
                                        },
                                    ]}
                                    name="contribution" label="Apport client">
                                    <Slider
                                        min={0}
                                        max={voituredata !== null ? Number(voituredata.promoPrice) : state.amount}

                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    wrapperCol={{ span: 24 }}
                                    name="contribution"
                                >
                                    <Input type="number" style={{ width: 126 }}
                                        suffix="DH" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Col span={20}>
                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 14 }}
                                    wrapperCol={{ span: 14 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Veuillez renseigner le champ obligatoire',
                                        },
                                    ]}
                                    name="period" label="Durée de remboursement">
                                    <Slider
                                        min={6}
                                        max={84}
                                        step={6}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    wrapperCol={{ span: 24 }}
                                    name="period"
                                >
                                    <Input type="number" style={{ width: 126 }}
                                        suffix="MOIS" />
                                </Form.Item>
                            </Col>
                        </Row>

                        {done &&
                            <>
                                <Form.Item
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 12 }}
                                    labelAlign="left"
                                    name="creditAmount" label="Montant du crédit">
                                    <Input style={{ width: '116%' }}
                                        suffix="DH" type="number" disabled />
                                </Form.Item>
                                <Form.Item
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 12 }}
                                    labelAlign="left"
                                    name="fileCost" label="Frais de Dossier">
                                    <Input style={{ width: '116%' }}
                                        suffix="DH" type="number" disabled />
                                </Form.Item>
                                <Form.Item
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 12 }}
                                    labelAlign="left"
                                    name="monthly" label="Mensualité TTC">
                                    <Input style={{ width: '116%' }}
                                        suffix="DH" type="number" disabled />
                                </Form.Item>
                                <Form.Item
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 12 }}
                                    labelAlign="left"
                                    name="insuranceDI" label="Assurance DI (hors sociétés)">
                                    <Input style={{ width: '116%' }}
                                        suffix="DH" type="number" disabled />
                                </Form.Item>
                            </>
                        }

                        <Form.Item
                            wrapperCol={{ offset: 12 }}
                            name="insuranceDI"
                            className="simule">

                            {
                                !done
                                &&
                                <button type="submit" className="btn bg-inc-g uppr" style={{ width: '100%' }}>Calculer mon
                                    crédit </button>

                            }

                            {props.match.params.id !== undefined && done && !impr ?

                                <div className='row'>
                                    <div className='col-12 mb-4 simule-butt'>
                                        <button onClick={() => onIMP(true)} className="btn bg-inc-g uppr" >ENVOYER A MON EMAIL </button>
                                    </div>

                                    <div className='col-12 mb-4 simule-butt'>
                                        <button onClick={() => onIMP(false)} className="btn bg-inc-g uppr" >IMPRIMER L'OFFRE</button>
                                    </div>
                                    <div className='col-12 mb-4 simule-butt'>
                                        <button onClick={getRequest} className="btn bg-inc-g uppr" >POURSUIVRE MA RÉSERVATION</button>
                                    </div>


                                </div>
                                :
                                null
                            }
                            {props.match.params.id !== undefined && impr ?

                                <div className='row'>

                                    <div className='col-12 mb-4'>
                                        <button onClick={() => {
                                            window.location.href = "/Voituredetail/" + props.match.params.id
                                        }} className="btn bg-inc-g uppr" >revenir au process de
                                            réservation</button>
                                    </div>

                                </div>
                                :
                                null
                            }

                        </Form.Item>
                    </Form>
                </Spin>
            </div>,
        }
    ];

    const next = () => {
        const current = state.current + 1;
        setState({ current });
    }

    const { current } = state;

    return (
        <>
            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        {/*<div className="page-heading">*/}
                        {/*</div>*/}
                        <ul className="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>SIMULATEUR DE CRÉDIT EN LIGNE</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>
            <div className="classCenter">
                <>

                    <div className='container-fluid'>
                        <img
                            style={{ display: 'block', margin: 'auto', marginBottom: 25 }}
                            className="displayInDesktop"
                            alt="logo"
                            width="80%"
                            src='/images/financimg.jpeg'
                        />
                        <img
                            style={{ display: 'block', margin: 'auto', marginBottom: 25 }}
                            className="displayInMobile"
                            alt="logo"
                            width="100%"
                            src='/images/financimgMobile.jpg'
                        />

                        <p className={'fifnctxt'}>L'acquisition d'une voiture est une étape importante qui symbolise la réalisation d'un projet et l'accomplissement d'un rêve. SOPRIAM FINANCMENT s'engage à vous accompagner tout au long de votre contrat en vous apportant un service adapté à vos besoins. </p>
                    </div>

                    <Steps style={{ width: '50%' }} current={current}>

                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>

                    {voituredata !== null &&
                        <>

                            <Card
                                hoverable

                            >

                                <img
                                    height="140px"
                                    alt="logo"
                                    src={LINKS.imagePath + voituredata.mainPicture}
                                    className="img-modal"
                                />
                                <Meta title={voituredata.category}
                                    description={voituredata.mark + "  " + voituredata.model + "  " + voituredata.category} />

                                {new Intl.NumberFormat('fr-FR').format(voituredata.promoPrice)}{" DH"}<sub></sub>

                            </Card>

                        </>

                    }

                    <div className="steps-content">{steps[current].content}</div>
                    <div className="steps-action">
                    </div>

                    <>


                    </>
                </>
                <Modal
                    title='Mentions légales :'
                    visible={agreementModal.visible}
                    onCancel={() => setAgreementModal({ ...agreementModal, visible: false })}
                    footer={null}>
                    <SimpleBar style={{ maxHeight: 300, maxWidth: 600 }}>
                        <p>
                            Les données à caractère personnel collectées dans ce formulaire de collecte sont
                            destinées à Sopriam
                            importateur exclusif de Sopriam au Maroc (siège : 125, Boulevard Moulay Slimane, Casablanca - Maroc).
                        </p>
                        <p>
                            Tous les champs marqués d&#39;un astérisque sont obligatoires aux fins de traiter votre
                            demande d&#39;offre.
                            Dans le cas contraire Sopriam pourrait ne pas être en mesure de traiter et de fournir le
                            service
                            demandé comme indiqué ci-dessus.
                        </p>
                        <p>
                            Sous réserve de votre consentement lorsque requis, vous pouvez recevoir des
                            renseignements sur les
                            offres, les nouvelles et les événements (newsletters, invitations et autres
                            publications) de Sopriam, de
                            ses filiales ou de ses partenaires.
                        </p>
                        <p>
                            Conformément à la Règlementation en vigueur en matière de protection des données à
                            caractère
                            personnel (qui désigne la loi n° 09-08 relative à la protection des personnes physiques
                            à l&#39;égard du
                            traitement des données à caractère personnel), vous disposez d&#39;un droit d&#39;accès,
                            de rectification,
                            d&#39;effacement, de limitation du traitement, de portabilité, ainsi que d&#39;un droit
                            d&#39;opposition au
                            traitement de vos données à caractère personnel lorsque ces dernières sont traitées à
                            des fins de
                            marketing direct ou lorsque le traitement est fondé sur l’intérêt légitime.
                        </p>
                        <p>
                            Pour plus d&#39;informations sur la manière dont vos données à caractère personnel sont
                            traitées
                            par Sopriam Maroc, veuillez lire la Déclaration de Confidentialité de Sopriam.
                        </p>

                    </SimpleBar>
                </Modal>
                <Modal
                    title='POURSUIVRE MA RÉSERVATION'
                    visible={isReserve}
                    onCancel={() => setIsReserve(false)}
                    footer={null}>
                    <SimpleBar>
                        <p>Merci de se connecter ou de créer un compte pour continuer votre réservation</p>
                    </SimpleBar>
                </Modal>
            </div>
        </>
    );
};

export default Simulator;
