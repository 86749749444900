import React, {useContext, useState} from 'react'
import {Button, Checkbox, Form, Input, message, Modal, Radio, Select, Steps, Switch, Upload,Descriptions} from 'antd';
import {buttonLayout, layout, validatePhone} from '../utils';
import SimpleBar from 'simplebar-react';
import VoitureContext from '../context/voitures/voitureContext';
import Swal from 'sweetalert2';
import {UploadOutlined} from '@ant-design/icons';
import {Cars} from "../utils/Cars";
import City from "../utils/City";
import Pdvpopup from "./Pdvpopup";
import {useHistory} from "react-router-dom";

const {Option} = Select;
const {Step} = Steps;
const Revendre = () => {

    const [filesData, setfilesData] = useState({
        Data: []
    });

    const [agreementModal, setAgreementModal] = useState({
        visible: false,
        checked: false
    });

    const [showFin, setShowFin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState();

    const [state, setState] = useState({
        current: 0,
        voiture: {
            "mark": "",
            "markPicture": "",
            "model": "",
            "category": "",
            "frame": "",
            "color": "",
            "carSeats": "",
            "gearBox": "",
            "fuel": "",
            "registrationDate": "",
            "mileage": "",
            "city": "",
            "addressPDV": "",
            "mainPicture": '',
            "pictures": [],
            "salePoint": null,
            "firstName": null,
            "lastName": null,
            "email": null,
            "phone": null,
            "horsepower":null,
            "origin":null,
            "firstHand":null,
            "equipments":null

        }
    });

    const [equimpents, setEquipment] = useState([{value:"Jantes aluminium",checked:false},
        {value:"Airbags",checked: false},
        {value:"Climatisation",checked: false},
        {value:"Système de navigation/GPS",checked: false},
        {value:"Toit ouvrant",checked: false},
        {value:"Sièges cuir",checked: false},
        {value:"Radar de recul",checked:false},
        {value:"Caméra de recul",checked: false},
        {value:"Vitres électriques",checked: false},
        {value:"ABS",checked: false},
        {value:"ESP",checked: false},
        {value:"Régulateur de vitesse",checked: false},
        {value:"Limiteur de vitesse",checked: false}
        ,{value:"CD/MP3/Bluetooth",checked: false},
        {value:"Ordinateur de bord",checked: false},
        {value:"Verrouillage centralisé à distance",checked: false}
    ]);
    const voitureContext = useContext(VoitureContext);

    const {
        RevendreVoiture,
        UploadFile,
        getPDV,
        allPDV
    } = voitureContext;

    React.useEffect(() => {
        getPDV();
    }, []);


    const normFile = (e) => {


        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const changeAccount = (key, value) => {

        let keys = key.split(".");
        let voiture = state.voiture;
        if (keys.length === 1) {
            voiture[keys[0]] = value;
        } else if (keys.length === 2) {
            voiture[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
            voiture[keys[0]][keys[1]][keys[2]] = value;
        }
        setState({...state, voiture});
    };

    const next = () => {
        const current = state.current + 1;
        setState({...state, current});
    }

    const prev = () => {
        console.log(state.current);

        const current = state.current - 1;
        console.log(current);
        setState({...state, current});
    }

    const onSendRevendre = () => {


        RevendreVoiture(state.voiture).then(() => {

            // Swal.fire(
            //     "Votre demande a été envoyée!",
            //     "Nous avons pris en compte votre demande. Notre équipe commerciale décidée prendre contact avec vous rapidemant.",
            //     "success"
            // )

            setShowFin(true)


        });
    }
    const {current} = state;

    const mm = {
        name: 'file',
        multiple: true,
        // accept: '.xlsx, .xls, .json',
        onChange(info) {
            const {status} = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        customRequest(option) {
            const {onSuccess, onError, file} = option;
            return new Promise(((resolve, reject) => {
                setLoading(true);
                setTimeout(() => {
                    if (file) {
                        const formData = new FormData();
                        formData.append('file', file);

                        UploadFile(formData).then(res => {
                            setFormData({
                                ...formData,
                                fileKey: res.fileKey
                            });
                            filesData.Data.push(...[res.fileKey]);

                            setLoading(false);
                            resolve(onSuccess(res.data));

                        }, (err) => {
                            setLoading(false);
                            resolve(onSuccess(err));

                        }).then(() => {

                            changeAccount("pictures", filesData.Data);

                        });
                    }
                }, 1000);
            }));
        }
    };
    let history = useHistory();
    const onHideMsg = () => {
        history.push('/');
        setShowFin(false);
    };
    const [mmd, setmmd] = React.useState();
    const onChangeModel = (vv) => {
        let dd = Cars.filter((v,i,a) => {
            return v.mark === vv && a.indexOf(v) === i
        })


        setmmd(dd[0]);

    }

    const [pdvmodal, setPdvmodal] = useState(false);

    const list = ["Hangzhou", "Beijing"];

    const [statec, setStateC] = React.useState([]);

    function onChange(value) {
        if (statec.includes(value)) {
            setStateC(statec.filter(val => val !== value))
        } else {
            setStateC([value, ...statec])
        }
        changeAccount("equipments", statec);

    }


    const cncal = () => setPdvmodal(false);


    const steps = [
        {
            title: 'INFORMATIONS DU VÉHICULE',
            content: <>

                <div className='container CDXA'>

                    <div className='row'>

                        <Form

                            {...layout}
                            onFinish={next}
                            className='col-12'


                        >
                            <br></br>

                            <Form.Item
                                label='Marque'
                                name="mark"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >


                                <Select
                                    showSearch={true}
                                    placeholder='Marque'
                                    onChange={(e) => {
                                        changeAccount("mark", e)
                                        onChangeModel(e)
                                    }

                                    }

                                >
                                    {
                                        Cars.map((v, i) => {
                                            return (
                                                <Option value={v.mark}>{v.mark.toUpperCase()}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Form.Item>

                            <Form.Item
                                label='Modèle'
                                name="model"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >


                                <Select
                                    showSearch={true}
                                    placeholder='Modèle'
                                    onChange={(e) =>
                                        changeAccount("model", e)}
                                >
                                    {
                                        mmd?.models?.map((v, i) => {
                                            return (
                                                <Option value={v}>{v.toUpperCase()}</Option>

                                            )
                                        })
                                    }
                                </Select>

                            </Form.Item>

                            <Form.Item
                                label='Couleur'
                                name="color"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Couleur'
                                    onChange={(e) =>
                                        changeAccount("color", e.target.value)
                                    }
                                ></Input>

                            </Form.Item>

                            <Form.Item
                                label='Nombre de places'
                                name="carSeats"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Input
                                    type='number'
                                    placeholder='Nombre de places'
                                    onChange={(e) =>
                                        changeAccount("carSeats", e.target.value)
                                    }
                                ></Input>

                            </Form.Item>


                            <Form.Item
                                label='Boite à vitesse'
                                name="gearBox"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Boite à vitesse'
                                    onChange={(e) =>
                                        changeAccount("gearBox", e)
                                    }
                                >
                                    <Option value='MANUELLE'>MANUELLE</Option>
                                    <Option value='AUTOMATIQUE'>AUTOMATIQUE</Option>
                                </Select>
                            </Form.Item>


                            <button type="submit" className="btn  bg-inc-g float-right uppr">Suivant</button>


                        </Form>
                    </div>
                </div>


            </>
        },
        {

            title: 'PLUS DE DÉTAILS',
            content: <>

                <div className='container CDXA'>

                    <div className='row'>

                        <Form
                            {...layout}
                            onFinish={next}
                            className='col-12'

                        >

                            <br></br>





                            <Form.Item
                                label="Carburant"
                                name='Carburant'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Carburant'
                                    onChange={(e) =>
                                        changeAccount("fuel", e)
                                    }
                                >
                                    <Option value="DIESEL">DIESEL</Option>
                                    <Option value="ESSENCE">ESSENCE</Option>
                                    <Option value="HYBRID">HYBRID</Option>
                                    <Option value="ÉLECTRIQUE ">ÉLECTRIQUE</Option>
                                    <Option value="LPG">LPG</Option>

                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Origine"
                                name='Origine'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Origine'
                                    onChange={(e) =>
                                        changeAccount("origin", e)
                                    }
                                >
                                    <Option value="Dédouanée">Dédouanée</Option>
                                    <Option value="WW au Maroc">WW au Maroc</Option>


                                </Select>
                            </Form.Item>


                            <Form.Item
                                label="Première main"
                                name='Première main'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Première main'
                                    onChange={(e) =>
                                        changeAccount("firstHand", e)
                                    }
                                >
                                    <Option value="OUI">Oui</Option>
                                    <Option value="NON">Non</Option>


                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Puissance fiscale"
                                name='Puissance fiscale'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='5 CV'
                                    onChange={(e) =>
                                        changeAccount("horsepower", e.target.value)
                                    }
                                />

                            </Form.Item>

                            <Form.Item
                                label="Date de mise en circulation"
                                name='Date de mise en circulation'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='DD/MM/YYYY'
                                    onChange={(e) =>
                                        changeAccount("registrationDate", e.target.value)
                                    }
                                />

                            </Form.Item>

                            <Form.Item
                                label="Kilométrage"
                                name='kilométrage'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Kilométrage'
                                    onChange={(e) =>
                                        changeAccount("mileage", e.target.value)
                                    }
                                />

                            </Form.Item>

                            <Form.Item
                                label="Voiture Images"
                                name="images"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}

                            >

                                <Upload onRemove={false} listType="picture"  {...mm}>
                                    <Button icon={<UploadOutlined/>}>Importer les images</Button>
                                </Upload>,


                            </Form.Item>

                            <Form.Item
                                label="Détails supplémentaires"
                                name="dett"


                            >

                                {equimpents.map(value =>
                                    <Button type={statec.includes(value.value) ? "primary" : "default"}
                                            onClick={() => onChange(value.value)}>{value.value}</Button>
                                )}


                            </Form.Item>

                            <Form.Item
                                label='Ville'
                                name="ville"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez remplir le champs obligatoire',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Ville'
                                    showSearch
                                    onChange={(e,vv) => {
                                        setPdvmodal(true);
                                        changeAccount("city", e)
                                    }
                                    }

                                >
                                    {City.map((v, i) => {
                                        return (
                                            <Option key={i} value={v.name} vv={v}>
                                                {v.name}
                                            </Option>

                                        )
                                    })}

                                </Select>

                            </Form.Item>


                            <Modal
                                onCancel={cncal}
                                width={'90%'}
                                title={null}
                                visible={pdvmodal}
                                footer={null}>
                                <SimpleBar >
                                   <Pdvpopup changeAccount={changeAccount} setPdvmodal={setPdvmodal}   city={state.voiture.city} />

                                </SimpleBar>
                            </Modal>



                            <button onClick={() => prev()} type="button" class="btn  bg-inc-g uppr">Précédent</button>
                            &nbsp;
                            <button type="submit" class="btn  bg-inc-g float-right uppr">Suivant</button>

                        </Form>
                    </div>
                </div>



            </>,
        },
        {
            title: 'VOTRE PROFIL',
            content: <>

                <div className='container CDXA'>

                    <div className='row'>



                        <Form
                            {...layout}
                            onFinish={onSendRevendre}
                            className='col-12'
                        >

                            <Descriptions
                                title="VOTRE VÉHICULE"

                                          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label="Marque">{state.voiture.mark}</Descriptions.Item>
                                <Descriptions.Item label="Modèle">{state.voiture.model}</Descriptions.Item>
                                <Descriptions.Item label="Kilométrage">{state.voiture.mileage}</Descriptions.Item>
                                <Descriptions.Item label="Boite à vitesse">{state.voiture.gearBox}</Descriptions.Item>
                                <Descriptions.Item label="Ville">
                                    {state.voiture.city}
                                </Descriptions.Item>
                            </Descriptions>

                            <br></br>


                            <Form.Item
                                label='Nom'
                                name="Nom"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez renseigner le champ obligatoire',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Nom'
                                    onChange={(e) =>
                                        changeAccount("lastName", e.target.value)
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label='Prénom'
                                name="Prénom"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez renseigner le champ obligatoire',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Prénom'
                                    onChange={(e) =>
                                        changeAccount("firstName", e.target.value)
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label='E-mail'
                                name="E-mail"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez renseigner le champ obligatoire',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='E-mail'
                                    onChange={(e) =>
                                        changeAccount("email", e.target.value)
                                    }
                                />
                            </Form.Item>


                            <Form.Item
                                label='Téléphone'
                                name="telephone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez renseigner le champ obligatoire',
                                    }, {validator: validatePhone}
                                ]}
                            >
                                <Input
                                    placeholder='Téléphone'
                                    onChange={(e) =>
                                        changeAccount("phone", e.target.value)
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject('Prière accepter les mentions légales'),
                                    },
                                ]}
                                {...buttonLayout}
                            >
                                <Checkbox onClick={() => setAgreementModal({...agreementModal, visible: true})}
                                          checked={agreementModal.checked}>
                                    <span> J'accepte les mentions légales</span><span
                                    style={{color: '#88888'}}>*</span>
                                </Checkbox>
                            </Form.Item>

                            <button onClick={() => prev()} type="button" className="btn  bg-inc-g">Précédent</button>
                            &nbsp;

                            <button type="submit" className="btn  bg-inc-g float-right"> VALIDER
                              </button>


                        </Form>

                    </div>
                </div>
            </>,
        }
    ];


    return (

        <div>

            <section class="page-header listing_page">
                <div class="container-fluid">
                    <div class="page-header_wrap">
                        <ul class="coustom-breadcrumb">
                            <li><a href="/">Accueil</a></li>
                            <li>Reprise de votre voiture</li>
                        </ul>
                    </div>
                </div>

                <div class="dark-overlay"></div>
            </section>

            <section class="compare-page inner_pages">
                <div class="container">
                    <div class="compare_info">
                        <h4></h4>

                    </div>

                    <>
                        <Steps current={current}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title}/>
                            ))}
                        </Steps>
                        <div className="steps-content">{steps[current].content}</div>
                        <div className="steps-action">

                        </div>
                    </>

                </div>

            </section>

            <Modal
                title='Mentions légales :'
                visible={agreementModal.visible}
                onCancel={() => setAgreementModal({...agreementModal, visible: false})}
                footer={null}>
                <SimpleBar style={{maxHeight: 300, maxWidth: 600}}>
                    <p>
                        Les données à caractère personnel collectées dans ce formulaire de collecte sont
                        destinées à Sopriam
                        importateur exclusif de Sopriam au Maroc (siège : 125, Boulevard Moulay Slimane, Casablanca - Maroc).
                    </p>
                    <p>
                        Tous les champs marqués d&#39;un astérisque sont obligatoires aux fins de traiter votre
                        demande d&#39;offre.
                        Dans le cas contraire Sopriam pourrait ne pas être en mesure de traiter et de fournir le
                        service
                        demandé comme indiqué ci-dessus.
                    </p>
                    <p>
                        Sous réserve de votre consentement lorsque requis, vous pouvez recevoir des
                        renseignements sur les
                        offres, les nouvelles et les événements (newsletters, invitations et autres
                        publications) de Sopriam, de
                        ses filiales ou de ses partenaires.
                    </p>
                    <p>
                        Conformément à la Règlementation en vigueur en matière de protection des données à
                        caractère
                        personnel (qui désigne la loi n° 09-08 relative à la protection des personnes physiques
                        à l&#39;égard du
                        traitement des données à caractère personnel), vous disposez d&#39;un droit d&#39;accès,
                        de rectification,
                        d&#39;effacement, de limitation du traitement, de portabilité, ainsi que d&#39;un droit
                        d&#39;opposition au
                        traitement de vos données à caractère personnel lorsque ces dernières sont traitées à
                        des fins de
                        marketing direct ou lorsque le traitement est fondé sur l’intérêt légitime.
                    </p>
                    <p>
                        Pour plus d&#39;informations sur la manière dont vos données à caractère personnel sont
                        traitées
                        par Sopriam Maroc, veuillez lire la Déclaration de Confidentialité de Sopriam.
                    </p>

                </SimpleBar>
            </Modal>


            <Modal
            visible={showFin}
            footer={null}
            width={'75%'}
            onCancel={onHideMsg}
            className="revendrePopUp"
            >

                <h6 className='uppr text-center'> BONJOUR {state.voiture.lastName}{" "} {state.voiture.firstName}</h6>
                <p className='uppr text-center'> Merci de votre confiance voici le récapitulatif de votre demande de reprise</p>

                <Descriptions
                    title="VOTRE VÉHICULE"

                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label="Marque">{state.voiture.mark}</Descriptions.Item>
                    <Descriptions.Item label="Modèle">{state.voiture.model}</Descriptions.Item>
                    <Descriptions.Item label="Kilométrage">{state.voiture.mileage}</Descriptions.Item>
                    <Descriptions.Item label="Boite à vitesse">{state.voiture.gearBox}</Descriptions.Item>
                    <Descriptions.Item label="Ville">
                        {state.voiture.city}
                    </Descriptions.Item>
                </Descriptions>

                <p className='uppr text-center'> Vous serez bientôt contacté par votre point de vente pour étudier la reprise
                de votre véhicule et découvrir les offres du moment.
                </p>

                <br></br>

            </Modal>
        </div>
    )
}

export default Revendre;

