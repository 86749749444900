import React, {useContext, useEffect,useState} from 'react'
import {AutoComplete, Checkbox, Col, Form, Input, Modal, Row, Select,} from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import VoitureContext from '../../context/voitures/voitureContext';
import Swal from "sweetalert2";
import SimpleBar from 'simplebar-react';
import {PasswordInput} from "../../utils/PasswordInput";
import {checkResponse, validatePassword, validatePhone} from "../../utils";
import {isEmpty} from "../../utils/validator";
import axios from "axios";
import LINKS from "../../utils/Link";
import AuthContext from "../../context/auth/authContext";

const {Option} = Select;
const AutoCompleteOption = AutoComplete.Option;


const EditProfile = (props) => {

    const authContext = useContext(AuthContext);
    const {loadProfile , currentProfil} = authContext;
    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const [formData, setFormData] = useState({

        "email": currentProfil?.email,
        "firstName": currentProfil?.firstName,
        "lastName": currentProfil?.lastName,
        "phone": currentProfil?.phone,
        "city": currentProfil?.city,
        "address": currentProfil?.address,
        "password": currentProfil?.password

    });

    useEffect(() => {

        if (currentProfil === null) loadProfile();

        // eslint-disable-next-line
    }, []);


    const changeAccount = (key, value) => {
        let keys = key.split(".");
        let formDataS = formData;
        if (keys.length === 1) {
            formDataS[keys[0]] = value;
        } else if (keys.length === 2) {
            formDataS[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
            formDataS[keys[0]][keys[1]][keys[2]] = value;
        }
        setFormData({...formDataS});
    };


    const onFinish = async () => {

        await axios
            .put("/profile/my", {...formData})
            .then((res) => {
                if (checkResponse(res)) {
                    loadProfile();
                    Swal.fire({
                        title: 'Vos informations a bien été enregistrée!',
                        icon: 'success',
                        confirmButtonColor: '#ef6744',
                        confirmButtonText: 'Retourner à l’accueil'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/'

                        }
                    })
                }
                return res;
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: err.response.data.details,
                });

            });

    };

    const onFinish2 = async () => {

        if (!isEmpty(formData)) {
            await axios
                .put("/profile/my", {...formData})
                .then((res) => {
                    if (checkResponse(res)) {
                        loadProfile();
                        Swal.fire({
                            title: 'Vos informations a bien été enregistrée!',
                            icon: 'success',
                            confirmButtonColor: '#ef6744',
                            confirmButtonText: 'Retourner à l’accueil'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/'

                            }
                        })
                    }
                    return res;
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: err.response.data.details,
                    });

                });
        }


    };


    return (

        <>

            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        {/*<div class="page-heading">*/}
                        {/*    <h1>Contactez-nous</h1>*/}
                        {/*</div>*/}
                        <ul className="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>Profil</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section><br></br>
        <div className="container">
            <div className="row flex-lg-nowrap">


                <div className="col">
                    <div className="row">
                        <div className="col mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="e-profile">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><a style={{backgroundColor:'rgb(31, 58, 108)'}} href="" className=" nav-link uppr">Mes données personnelles</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content pt-3">
                                            <div className="tab-pane active">

                                                {currentProfil &&

                                                <Form
                                                    style={{width: '75%'}}
                                                    size="small"
                                                    {...formItemLayout}
                                                    form={form}
                                                    name="register"
                                                    onFinish={onFinish}
                                                    scrollToFirstError
                                                >


                                                    <Form.Item
                                                        name="lastname"
                                                        label="Nom"

                                                    >

                                                        <Input
                                                            defaultValue={currentProfil && currentProfil?.lastName}
                                                            placeholder='Nom *'
                                                            value={formData.lastName}
                                                            onChange={(e) =>
                                                                changeAccount("lastName", e.target.value)
                                                            }
                                                        />
                                                    </Form.Item>


                                                    <Form.Item
                                                        name="firstname"
                                                        label="Prénom"

                                                    >
                                                        <Input
                                                            defaultValue={currentProfil && currentProfil?.firstName}
                                                            placeholder='Prénom *'
                                                            value={formData.firstName}
                                                            onChange={(e) =>
                                                                changeAccount("firstName", e.target.value)
                                                            }
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="email"
                                                        label="E-mail"
                                                        rules={[
                                                            {
                                                                type: 'email',
                                                                message: 'Invalid email address',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            defaultValue={currentProfil && currentProfil?.email}
                                                            placeholder='E-mail'
                                                            value={formData.email}
                                                            onChange={(e) =>
                                                                changeAccount("email", e.target.value)
                                                            }
                                                        />
                                                    </Form.Item>

                                                    {/*<Form.Item*/}
                                                    {/*    name="password"*/}
                                                    {/*    label="Mot de passe"*/}
                                                    {/*    rules={[*/}
                                                    {/*        {validator: validatePassword}*/}
                                                    {/*    ]}*/}
                                                    {/*    hasFeedback*/}
                                                    {/*>*/}
                                                    {/*    <PasswordInput*/}
                                                    {/*        placeholder='Mot de passe *'*/}
                                                    {/*        value={formData.password}*/}
                                                    {/*        onChange={(e) =>*/}
                                                    {/*            changeAccount("password", e.target.value)*/}
                                                    {/*        }/>*/}
                                                    {/*</Form.Item>*/}

                                                    {/*<Form.Item*/}
                                                    {/*    name="confirm"*/}
                                                    {/*    label="Confirm Mot de passe"*/}
                                                    {/*    dependencies={['password']}*/}
                                                    {/*    hasFeedback*/}
                                                    {/*    rules={[*/}
                                                    {/*        ({getFieldValue}) => ({*/}
                                                    {/*            validator(rule, value) {*/}
                                                    {/*                if (!value || getFieldValue('password') === value) {*/}
                                                    {/*                    return Promise.resolve();*/}
                                                    {/*                }*/}

                                                    {/*                return Promise.reject('Les deux mots de passe que vous avez saisis ne correspondent pas!');*/}
                                                    {/*            },*/}
                                                    {/*        }),*/}
                                                    {/*    ]}*/}
                                                    {/*>*/}
                                                    {/*    <Input.Password*/}
                                                    {/*        placeholder='Confirm Mot de passe *'*/}
                                                    {/*        value={formData.password}*/}
                                                    {/*        onChange={(e) =>*/}
                                                    {/*            changeAccount("password", e.target.value)*/}
                                                    {/*        }*/}
                                                    {/*    />*/}
                                                    {/*</Form.Item>*/}


                                                    <Form.Item
                                                        name="phone"
                                                        label="Téléphone"

                                                    >
                                                        <Input
                                                            defaultValue={currentProfil && currentProfil?.phone}
                                                            placeholder='Téléphone *'
                                                            value={formData.phone}
                                                            onChange={(e) =>
                                                                changeAccount("phone", e.target.value)
                                                            }
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="adresse"
                                                        label="Adresse"

                                                    >
                                                        <Input
                                                            defaultValue={currentProfil && currentProfil?.address}
                                                            placeholder='Adresse *'
                                                            value={formData.address}
                                                            onChange={(e) =>
                                                                changeAccount("address", e.target.value)
                                                            }
                                                        />
                                                    </Form.Item>


                                                    <Form.Item
                                                        name="ville"
                                                        label="Ville"

                                                    >
                                                        <Input

                                                            defaultValue={currentProfil && currentProfil?.city}
                                                            placeholder='Ville *'
                                                            value={formData.city}
                                                            onChange={(e) =>
                                                                changeAccount("city", e.target.value)
                                                            }
                                                        />
                                                    </Form.Item>






                                                    <Form.Item {...tailFormItemLayout}>
                                                        <button type="submit" class="btn  bg-inc-g uppr">Valider </button>
                                                    </Form.Item>

                                                </Form>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                    <div className="e-profile">
                                        <ul className="nav nav-tabs">
                                            <li  className="nav-item"><a style={{backgroundColor:'rgb(31, 58, 108)'}} href="" className=" nav-link uppr">Mes codes d'accès</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content pt-3">
                                            <div className="tab-pane active">

                                                <Form
                                                    style={{width: '75%'}}
                                                    size="small"
                                                    {...formItemLayout}
                                                    form={form2}
                                                    name="register"
                                                    onFinish={onFinish2}
                                                    scrollToFirstError
                                                >

                                                    <Form.Item
                                                        name="password"
                                                        label="Mot de passe"
                                                        rules={[
                                                            {validator: validatePassword}
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <PasswordInput
                                                            placeholder='Mot de passe *'
                                                            value={formData.password}
                                                            onChange={(e) =>
                                                                changeAccount("password", e.target.value)
                                                            }/>
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="confirm"
                                                        label="Confirmer mot de passe"
                                                        dependencies={['password']}
                                                        hasFeedback
                                                        rules={[
                                                            ({getFieldValue}) => ({
                                                                validator(rule, value) {
                                                                    if (!value || getFieldValue('password') === value) {
                                                                        return Promise.resolve();
                                                                    }

                                                                    return Promise.reject('Les deux mots de passe que vous avez saisis ne correspondent pas!');
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input.Password
                                                            placeholder='Confirmer mot de passe *'
                                                            value={formData.password}
                                                            onChange={(e) =>
                                                                changeAccount("password", e.target.value)
                                                            }
                                                        />
                                                    </Form.Item>

                                                    <Form.Item {...tailFormItemLayout}>
                                                        <button type="submit" className="btn  bg-inc-g uppr">Valider
                                                        </button>
                                                    </Form.Item>

                                                </Form>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
</>
    );
}

export default EditProfile;


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
