import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Input,} from 'antd';
import Swal from "sweetalert2";
import AuthContext from '../../context/auth/authContext';
import env from "../../env/env";
import {useHistory} from "react-router-dom";
const Login = () => {
    let history = useHistory();
    const [form] = Form.useForm();

    const [captcharesponse, setcaptcharesponse] = useState();

    const authContext = useContext(AuthContext);

    // eslint-disable-next-line no-unused-vars
    const {isAuth, login} = authContext;

    useEffect(() => {
        if (isAuth) {
            window.location.href = env.publicUrl;
        }
    }, [isAuth]);

    const [formData, setFormData] = useState({

        "username": null,
        "password": null

    });

    const changeAccount = (key, value) => {
        let keys = key.split(".");
        let formDataS = formData;
        if (keys.length === 1) {
            formDataS[keys[0]] = value;
        } else if (keys.length === 2) {
            formDataS[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
            formDataS[keys[0]][keys[1]][keys[2]] = value;
        }
        setFormData({...formDataS});
    };


    const onFinish = () => {

        login(formData).then(() => {
            // history.goBack();

        }).catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: err.response.data.details,
            });

        });

    };

    function onChange(value) {
        setcaptcharesponse(value);
    }

    const onGoMotPass = () => {

        window.location.href ="/forgot-password"
    }

    return (
        <div>

            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        {/*<div class="page-heading">*/}
                        {/*    <h1>Contactez-nous</h1>*/}
                        {/*</div>*/}
                        <ul className="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>Login</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>

            <br></br>
            <h5 className="text-center">CONNECTEZ-VOUS À VOTRE COMPTE </h5>

            <Form
                style={{width: '75%'}}
                size="small"
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                layout={formItemLayout}
                scrollToFirstError
            >


                <Form.Item
                    name="username"
                    label="E-mail"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        },
                    ]}
                >
                    <Input
                        placeholder='E-mail'
                        value={formData.username}
                        onChange={(e) =>
                            changeAccount("username", e.target.value)
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Mot de passe"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        placeholder='Mot de passe'
                        value={formData.password}
                        onChange={(e) =>
                            changeAccount("password", e.target.value)
                        }
                    />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>

                    <Button onClick={onGoMotPass} className='uppr' type="link" htmlType="button" >
                        Mot de passe oublié ?
                    </Button>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>


                    <button
                        type="submit" class="btn  bg-inc-g uppr">Se Connecter</button>
                    &nbsp;
                    Ou &nbsp;

                    <button onClick={() => {
                        window.location.href = "/create-account";
                    }} class="btn  bg-inc-g-blue uppr">Pas encore inscrit ? « Cliquez ici »</button>


                </Form.Item>

            </Form>


        </div>
    )
}

export default Login;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
