import React from "react";
import {AuthContext} from "./auth";
import {DataContext} from "./data";
import {ProfilesContext} from "./profiles";
import {DatasourceContext} from "./datasource";
import {ShopCartContext} from "./shopCart";

export const useAuth = () => React.useContext(AuthContext);

export const useData = () => React.useContext(DataContext);

export const useProfiles = () => React.useContext(ProfilesContext);

export const useDatasource = () => React.useContext(DatasourceContext);

export const useShopCart = () => React.useContext(ShopCartContext);

