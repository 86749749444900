import React from "react";
import moment from "moment";
import {Button, Col, Form, Input, message, Row, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import ScrollToBottom from 'react-scroll-to-bottom';
import firebase from "../../Firebase";
import useLocalStorage from "../../utils/hooks/useLocalStorage";
import {snapshotToArray} from "../../utils";
import {useDatasource} from "../../contexts";
import Loading from "../commons/Loading";
import {api} from "../../env";

function ChatRoom({room}) {
    const [form] = Form.useForm();

    const [chats, setChats] = React.useState([]);

    const {uploadFile, deleteFile} = useDatasource();

    const [roomname, setRoomname] = React.useState("");

    const [formData, setFormData] = useLocalStorage("chat-user", undefined);

    const [newchat, setNewchat] = React.useState({
        roomname: "",
        nickname: "",
        message: "",
        date: "",
        type: "",
    });

    React.useEffect(() => {
        const fetchData = async () => {
            setRoomname(room);
            firebase
                .database()
                .ref("chats/")
                .orderByChild("roomname")
                .equalTo(roomname)
                .on("value", (resp) => {
                    setChats([]);
                    setChats(snapshotToArray(resp));
                });
        };
        fetchData();
    }, [room, roomname]);

    // const [users, setUsers] = React.useState([]);
    // React.useEffect(() => {
    //     const fetchData = async () => {
    //         setRoomname(room);
    //         firebase.database().ref('roomusers/').orderByChild('roomname').equalTo(roomname).on('value', (resp2) => {
    //             setUsers([]);
    //             const roomusers = snapshotToArray(resp2);
    //             setUsers(roomusers.filter(x => x.status === 'online'));
    //         });
    //     };
    //
    //     fetchData();
    // }, [room, roomname]);

    const [loading, setLoading] = React.useState(false);

    function uploadProps(type) {
        let accept = "";
        switch (type) {
            case "img":
                accept = ".jpg, .jpeg, .png, .svg";
                break;
            case "document":
                accept = ".pdf, .doc, .docx";
                break;
        }
        return {
            name: "file",
            multiple: false,
            accept,
            beforeUpload: (file) => {
                const fileExten = file.name.slice(file.name.lastIndexOf('.'), file.name.length);
                if (!accept.includes(fileExten)) {
                    message.error(`${file.name} is not on ${type}`);
                }
                return accept.includes(fileExten);
            },
            onRemove(file) {
                if (file) {
                    const {response} = file;
                    if (response && response.fileKey) {
                        deleteFile(response.fileKey)
                            .then(() => {
                                message.success(`${file.name} deleted successfully.`);
                            })
                            .catch();
                    }
                }
            },
            onChange(info) {
                const {status} = info.file;
                if (status === "done") {
                    message.success(`${info.file.name} ${type} uploaded successfully.`);
                } else if (status === "error") {
                    message.error(`${info.file.name} ${type} upload failed.`);
                }
            },
            customRequest(option) {
                const {onSuccess, onError, file} = option;
                return new Promise((resolve, reject) => {
                    setLoading(true);
                    setTimeout(() => {
                        if (file) {
                            const formData = new FormData();
                            formData.append("file", file);
                            uploadFile(formData).then(
                                (res) => {
                                    sendNewMessage(res.data.fileKey, type);
                                    setLoading(false);
                                    resolve(onSuccess(res.data));
                                },
                                (err) => {
                                    setLoading(false);
                                    reject(onError(err));
                                }
                            );
                        }
                    }, 1000);
                });
            },
        };
    }

    const submitMessage = (values) => {
        const {message} = values;
        sendNewMessage(message, "message");
        form.resetFields();
    };

    const sendNewMessage = (message, type) => {
        const chat = newchat;
        chat.message = message;
        chat.roomname = roomname;
        chat.nickname = formData.nickname;
        chat.username = formData.username;
        chat.date = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
        chat.type = type;
        const newMessage = firebase.database().ref("chats/").push();
        newMessage.set(chat);
        setNewchat({
            roomname: "",
            nickname: "",
            username: "",
            message: "",
            date: "",
            type: "",
        });
    };

    return (
        <>
            <ScrollToBottom className="scroll-style">
                {chats.map((item, idx) => (
                    <div key={idx} className="MessageBox">
                        {item.type === "join" || item.type === "exit" ? (
                            <div className="ChatStatus">
                                <span className="ChatDate">{item.date}</span>
                                <span className="ChatContentCenter">{item.message}</span>
                            </div>
                        ) : (
                            <div className="ChatMessage">
                                <div
                                    className={`${
                                        item.username === formData.username
                                            ? "RightBubble"
                                            : "LeftBubble"
                                    }`}
                                >
                                    {item.username === formData.username ? (
                                        <span className="MsgName">Me</span>
                                    ) : (
                                        <span className="MsgName">{item.nickname}</span>
                                    )}
                                    <span className="MsgDate"> at {item.date}</span>
                                    {item.type === "message" && (
                                        <p style={{color: "white"}}>{item.message}</p>
                                    )}
                                    {item.type === "img" && (
                                        <img width={130} src={`${api}file/${item.message}`}/>
                                    )}
                                    {item.type === "document" &&
                                    <>
                                        <br/>
                                        <a style={{color: "black"}} href={`${api}/file/${item.message}`}
                                           target="_blank">{item.message}</a>
                                    </>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </ScrollToBottom>
            <Loading spinning={loading}>
                <Form form={form} onFinish={submitMessage} style={{marginTop: 10}}>
                    <Row gutter={[16, 16]}>
                        <Col span={16}>
                            <Form.Item name="message" rules={[{required: true}]}>
                                <Input placeholder="Entrer votre msg" size="large"/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" size="large">
                                    Envoyer
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item>
                                <Upload {...uploadProps("img")}>
                                    <Button icon={<UploadOutlined/>} size="large">Image</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Upload {...uploadProps("document")}>
                                    <Button icon={<UploadOutlined/>} size="large">Fichier</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Loading>
        </>
    );
}

export default ChatRoom;

