import Modal from 'antd/lib/modal/Modal';
import React, {useEffect, useState} from 'react';
import AuthState from './context/auth/AuthState';
import VoitureState from './context/voitures/voitureState';
import {DatasourceProvider} from './contexts/datasource';
import {ShopCartProvider} from './contexts/shopCart';
import Routes from './routes/Routes';
import locale from 'antd/es/locale/fr_FR';
import {ConfigProvider} from 'antd';
import './App.css';

function App() {

    const [state, setState] = useState({
        viewPopup: false,
    })

    useEffect(() => {
        let visited = localStorage["alreadyVisited"];
        if (visited) {
            setState({viewPopup: false})
            //do not view Popup
        } else {
            //this is the first time
            localStorage["alreadyVisited"] = true;
            setState({viewPopup: true});
        }
    }, []);

    const handleCance = () => setState({viewPopup: false});

    return (
        <>
            <Modal
                title="NOUS RESPECTONS VOTRE VIE PRIVÉE"
                visible={state.viewPopup}
                onOk={handleCance}
                onCancel={handleCance}
                footer={false}
            >
                <p>Nous utilisons les cookies afin de vous offrir une expérience optimale et une communication
                    pertinente sur nos sites. Nous veillons à obtenir votre consentement quant à l’usage de vos données
                    et nous nous engageons à les respecter.
                    Pour plus d’infos sur vos données personnelles, consultez notre la section des cookies dans notre
                    politique de confidentialité.</p>

                <button onClick={handleCance} type="submit" class="btn  bg-inc-g">TOUT ACCEPTER </button>
            </Modal>

            <ConfigProvider locale={locale}>

                <AuthState>


                    <VoitureState>
                        <ShopCartProvider>
                            <DatasourceProvider>
                                <Routes/>
                            </DatasourceProvider>
                        </ShopCartProvider>
                    </VoitureState>

                </AuthState>

            </ConfigProvider>

        </>
    );
}

export default App;
