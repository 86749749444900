import React from "react";
import axios from "axios";
import qs from "qs";
import {useHistory} from "react-router-dom";
import {isEmpty} from "../utils/validator";
import {asyncHandler, checkResponse, errorResponseHandler, getJWT, openNotification, removeJWT,} from "../utils";

const AuthContext = React.createContext();
const {Provider} = AuthContext;

const initState = {
    isAuth: !isEmpty(getJWT()),
    currentUser: null,
    loading: false,
    error: null,
};

const AuthProvider = ({children}) => {
    const history = useHistory();

    const [state, setState] = React.useState(initState);

    const login = asyncHandler(async (formData) => {
        const options = {
            headers: {
                "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
            auth: {
                username: "browser", // This is the client_id
                password: "secret", // This is the client_secret
            },
        };
        initialLoading();
        return await axios
            .post(
                "/oauth/token",
                qs.stringify({...formData, grant_type: "password"}),
                options
            )
            .then(async (res) => {
                if (checkResponse(res) && res.data.access_token) {
                    localStorage.setItem("asc_token", res.data.access_token);
                    setState((prevState) => ({
                        ...prevState,
                        isAuth: true,
                        loading: false,
                    }));
                    await loadCurrentUser();
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const loadCurrentUser = asyncHandler(async () => {
        initialLoading();
        return await axios
            .get("/auth/current")
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        isAuth: true,
                        currentUser: res.data,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const logout = () => {
        removeJWT();
        setState((prevState) => ({
            ...prevState,
            isAuth: false,
            currentUser: null,
        }));
        history.push("/login");
    };

    const initialLoading = () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
    };

    const handleErrorResponse = (err) => {
        err = errorResponseHandler(err);
        setState((prevState) => ({
            ...prevState,
            error: errorResponseHandler(err),
            loading: false,
        }));
        openNotification("error", err);
        logout();
    };

    const clearError = () => {
        setState((prevState) => ({
            ...prevState,
            error: null,
        }));
    };

    return (
        <Provider
            value={{
                ...state,
                loadCurrentUser,
                clearError,
                logout,
                login,
            }}
        >
            {children}
        </Provider>
    );
};

export {AuthContext, AuthProvider};
