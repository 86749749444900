import React from "react";

import useLocalStorage from '../utils/hooks/useLocalStorage'

const ShopCartContext = React.createContext();
const {Provider} = ShopCartContext;

const ShopCartProvider = ({children}) => {

    const [cartValue, setCartValue] = useLocalStorage("shop-cart", []);

    return (
        <Provider
            value={{
                cartValue, setCartValue,
            }}
        >
            {children}
        </Provider>
    );
};

export {ShopCartContext, ShopCartProvider};
