import {
    ACCOUNT_SUCCUSS,
    CONTACT_VOITURE_SUCCUSS,
    Delete_Mark_Succuss,
    DELETE_VOITURE_SUCCUSS,
    Export_Succuss,
    GET_ALL_CategoriesByMarkByModel,
    GET_ALL_FILTER,
    GET_ALL_MARKS,
    GET_ALL_ModuleByMark,
    GET_ALL_PDV,
    GET_ALL_VOITURES,
    GET_CONTENT_SUCCESS,
    GET_PDV_BYID,
    GET_RESERVATION_VOITURE,
    GET_VOITURE_SUCCUSS,
    Import_Succuss,
    NEWSLETTER_CONTACT_US,
    OFFRE_MOMENT,
    RESERVER_VOITURE,
    REVENDRE_VOITURE,
    SEND_CONTACT_US,
    Update_Mark_Succuss,
    UPDATE_VOITURE_SUCCUSS,
    UPLOAD_Multiple,
    UPLOAD_Succuss,
    UPLOAD_Voiture_Succuss
} from "./types";

export default (state, action) => {
    const {type, payload} = action;
    switch (type) {
        case GET_PDV_BYID:
            return {
                ...state,
                PdvDetail: payload,
                isLoading: false,
            };
        case GET_ALL_PDV:
            return {
                ...state,
                allPDV: payload,
                isLoading: false,
            };
        case GET_RESERVATION_VOITURE:
            return {
                ...state,
                allreservations: payload,
                isLoading: false,
            };
        case GET_ALL_ModuleByMark:
            return {
                ...state,
                allmodulesbymarque: payload,
                isLoading: false,
            };
        case GET_ALL_MARKS:
            return {
                ...state,
                allmarks: payload.data,
                rangemin: payload.price.min,
                rangemax: payload.price.max,
                allmodels: payload.models,
                isLoading: false,
            };
        case Update_Mark_Succuss:
            return {
                ...state,
                isLoading: false,
            };
        case REVENDRE_VOITURE:
            return {
                ...state,
                isLoading: false,
            };
        case RESERVER_VOITURE:
            return {
                ...state,
                isLoading: false,
            };
        case NEWSLETTER_CONTACT_US:
            return {
                ...state,
                isLoading: false,
            };
        case SEND_CONTACT_US:
            return {
                ...state,
                isLoading: false,
            };
        case Delete_Mark_Succuss:
            return {
                ...state,
                isLoading: false,
            };
        case GET_ALL_FILTER:
            return {
                ...state,
                filterData: payload,
                isLoading: false,
            };
        case OFFRE_MOMENT:
            return {
                ...state,
                offresmoment: payload,
                isLoading: false,
            };
        case GET_VOITURE_SUCCUSS:
            return {
                ...state,
                voituredata: payload,
                isLoading: false,
            };
        case GET_ALL_CategoriesByMarkByModel:
            return {
                ...state,
                allcategoriesbymarquebymodel: payload,
                isLoading: false,
            };
        case UPLOAD_Succuss:
            return {
                ...state,
                isLoading: false,
            };
        case CONTACT_VOITURE_SUCCUSS:
            return {
                ...state,
                isLoading: false,
            };
        case UPLOAD_Multiple:
            return {
                ...state,
                isLoading: false,
            };
        case Import_Succuss:
            return {
                ...state,
                isLoading: false,
            };
        case Export_Succuss:
            return {
                ...state,
                isLoading: false,
            };
        case UPLOAD_Voiture_Succuss:
            return {
                ...state,
                isLoading: false,
            };
        case GET_ALL_VOITURES:
            return {
                ...state,
                allvoitures: payload,
                isLoading: false,
            };
        case DELETE_VOITURE_SUCCUSS:
            return {
                ...state,
                isLoading: false,
            };
        case UPDATE_VOITURE_SUCCUSS:
            return {
                ...state,
                isLoading: false,
            };

        case ACCOUNT_SUCCUSS:
            return {
                ...state,
                isLoading: false,
            };
        case GET_CONTENT_SUCCESS:
            return {
                ...state,
                homesidelogo: payload.filter((value) => {
                    return value.key === "homesidelogo";
                }),
                homelogo: payload.filter((value) => {
                    return value.key === "homelogo";
                }),
                media: payload.filter((value) => {
                    return value.key === "media";
                }),
                faq: payload.filter((value) => {
                    return value.key === "faq";
                }),
                contactus: payload.filter((value) => {
                    return value.key === "contactus";
                }),
                conditions: payload.filter((value) => {
                    return value.key === "conditions";
                }),
                whoAreWee: payload.filter((value) => {
                    return value.key === "whoAreWee";
                }),
                conditionsvent: payload.filter((value) => {
                    return value.key === "conditionsvent";
                }),
                guide: payload.filter((value) => {
                    return value.key === "guide";
                }),
                chat: payload.filter((value) => {
                    return value.key === "chat";
                }),
                isLoading: false,
            };


    }

};