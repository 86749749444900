import React from "react";
import {asyncHandler, checkResponse, errorResponseHandler, openNotification,} from "../utils";
import axios from "axios";

const DatasourceContext = React.createContext();
const {Provider} = DatasourceContext;

const initState = {
    files: [],
    connections: [],
    filteredFiles: [],
    filteredConnections: [],
    fileDataForm: null,
    loading: false,
    error: null,
};

const DatasourceProvider = ({children}) => {
    const [state, setState] = React.useState(initState);

    const fetchAllConnections = asyncHandler(async () => {
        initialLoading();
        return await axios
            .get("/datasource")
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        ...res.data,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const saveConnection = asyncHandler(async (formData) => {
        initialLoading();
        return await axios
            .post("/datasource/connection", formData)
            .then((res) => res)
            .catch(handleErrorResponse);
    });

    const uploadFile = asyncHandler(async (formData) => {
        return await axios
            .post("/file", formData)
            .then((res) => res)
            .catch(handleErrorResponse);
    });

    const deleteConnection = asyncHandler(async (connectionId) => {
        return await axios
            .delete(`/datasource/${connectionId}`)
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        connections: prevState.connections.filter(
                            (connection) => connection.connectionId !== connectionId
                        ),
                    }));
                }
            })
            .catch(handleErrorResponse);
    });

    const deleteFile = asyncHandler(async (fileKey) => {
        return await axios
            .delete(`/file/${fileKey}`)
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        files: prevState.files.filter((file) => file.fileKey !== fileKey),
                    }));
                }
            })
            .catch(handleErrorResponse);
    });

    const filterConnections = (payload) => {
        setState((prevState) => ({
            ...prevState,
            filteredConnections: prevState.connections.filter((connection) => {
                const regex = new RegExp(`${payload}`, "gi");
                return (
                    connection.databaseType.match(regex) ||
                    connection.connectionName.match(regex)
                );
            }),
        }));
    };

    const filterFiles = (payload) => {
        setState((prevState) => ({
            ...prevState,
            filteredFiles: prevState.files.filter((file) => {
                const regex = new RegExp(`${payload}`, "gi");
                return file.fileKey.match(regex) || file.fileName.match(regex);
            }),
        }));
    };

    const clearFilter = () => {
        setState((prevState) => ({
            ...prevState,
            filteredConnections: [],
        }));
    };

    const clearFilesFilter = () => {
        setState((prevState) => ({
            ...prevState,
            filteredFiles: [],
        }));
    };

    const setFileDataForm = (payload) => {
        setState((prevState) => ({
            ...prevState,
            fileDataForm: payload,
        }));
    };

    const handleErrorResponse = (err) => {
        err = errorResponseHandler(err);
        setState((prevState) => ({
            ...prevState,
            error: errorResponseHandler(err),
            loading: false,
        }));
        openNotification("error", err);
    };

    const initialLoading = () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
    };

    return (
        <Provider
            value={{
                ...state,
                fetchAllConnections,
                filterConnections,
                clearFilesFilter,
                setFileDataForm,
                saveConnection,
                deleteConnection,
                deleteFile,
                uploadFile,
                filterFiles,
                clearFilter,
            }}
        >
            {children}
        </Provider>
    );
};

export {DatasourceProvider, DatasourceContext};
