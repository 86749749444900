import React, {useContext, useEffect} from "react";
import moment from "moment";
import {Avatar, Badge, Button, Card, Form, Input} from "antd";
import firebase from "../../Firebase";
import useLocalStorage from "../../utils/hooks/useLocalStorage";
import {isEmpty} from "../../utils/validator";
import Loading from "../commons/Loading";
import ChatRoom from "./ChatRoom";
import {snapshotToArray} from "../../utils";
import "./ChatWindow.css";
import {LoginOutlined} from "@ant-design/icons";
import VoitureContext from "../../context/voitures/voitureContext";
import LINKS from "../../utils/Link";

const showChatWindow = {
    opacity: 1,
    bottom: 85,
    pointerEvents: "auto",
};
const closeChatWindow = {
    opacity: 0,
    bottom: 0,
    pointerEvents: "none",
};

const validateMessages = {
    required: "Vous devez remplir le champ!",
    types: {
        email: "L'adresse email n'est pas valide!",
    },
};

const supportUsers = [
    {
        nickname: "Sopriam Admin",
        username: "peaqock@sopriamoccasions.ma",
    },
];

function ChatWindow({isOpen = false}) {

    const roomRef = firebase.database().ref("rooms/");
    const contactRef = firebase.database().ref("contacts/");

    const [chatRoute, setChatRoute] = React.useState("form");

    const [participants, setParticipants] = React.useState([]);

    const [formData, setFormData] = useLocalStorage("chat-user", undefined);

    const [romName, setRoomName] = React.useState(undefined);

    const [showLoading, setShowLoading] = React.useState(false);


    const voitureContext = useContext(VoitureContext);

    const {
        GetAllContent,chat
    } = voitureContext;

    useEffect(() => {
        GetAllContent();
    }, []);


    const onFinish = (values) => {
        if (!isEmpty(values)) {
            setFormData(values);
            setShowLoading(true);
            contactRef
                .orderByChild("username")
                .equalTo(values.username)
                .once("value", (snapshot) => {
                    if (!snapshot.exists()) {
                        const newUser = firebase.database().ref("contacts/").push();
                        newUser.set(values);
                        setShowLoading(false);
                    }
                    setShowLoading(false);
                    setChatRoute("supportlist");
                });
        }
    };

    const selectContact = (contact) => {
        setShowLoading(true);
        const roomname = `${formData.username}_${contact.username}`;
        roomRef
            .orderByChild("roomname")
            .equalTo(roomname)
            .once("value", (snapshot) => {
                if (!snapshot.exists()) {
                    const newRoom = roomRef.push();
                    newRoom.set({roomname});
                }
                setRoomName(roomname);
                enterChatRoom(roomname);
            });
    };

    const enterChatRoom = (roomname) => {
        const chat = {
            roomname: "",
            nickname: "",
            message: "",
            date: "",
            type: "",
        };
        chat.roomname = roomname;
        chat.nickname = formData.nickname;
        chat.username = formData.username;
        chat.date = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
        chat.message = `${formData.nickname} entrer`;
        chat.type = "join";
        const newMessage = firebase.database().ref("chats/").push();
        newMessage.set(chat);

        firebase
            .database()
            .ref("roomusers/")
            .orderByChild("roomname")
            .equalTo(roomname)
            .on("value", (resp) => {
                let roomuser;
                roomuser = snapshotToArray(resp);
                const user = roomuser.find((x) => x.username === formData.username);
                if (!isEmpty(user)) {
                    const userRef = firebase.database().ref("roomusers/" + user.key);
                    userRef.update({status: "online"});
                } else {
                    const newroomuser = {
                        roomname: "",
                        username: "",
                        nickname: "",
                        status: "",
                    };
                    newroomuser.roomname = roomname;
                    newroomuser.username = formData.username;
                    newroomuser.nickname = formData.nickname;
                    newroomuser.status = "online";
                    const newRoomUser = firebase.database().ref("roomusers/").push();
                    newRoomUser.set(newroomuser);
                }
            });
        setShowLoading(false);
        setChatRoute("chatroom");
    };


    const exitChat = () => {
        if (!isEmpty(romName) && !isEmpty(formData)) {
            const chat = {
                roomname: "",
                nickname: "",
                username: "",
                message: "",
                date: "",
                type: "",
            };
            chat.roomname = romName;
            chat.nickname = formData.nickname;
            chat.username = formData.username;
            chat.date = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
            chat.message = `${formData.nickname} leave the chat`;
            chat.type = "exit";
            const newMessage = firebase.database().ref("chats/").push();
            newMessage.set(chat);
            firebase
                .database()
                .ref("roomusers/")
                .orderByChild("roomname")
                .equalTo(romName)
                .once("value", (resp) => {
                    let roomuser;
                    roomuser = snapshotToArray(resp);
                    const user = roomuser.find((x) => x.username === formData.username);
                    if (!isEmpty(user)) {
                        const userRef = firebase.database().ref("roomusers/" + user.key);
                        userRef.update({status: "offline"});
                    }
                });
            setChatRoute("form");
            setFormData(undefined);
            setRoomName(undefined);
        }
    };

    return (
        <div
            className="chat-wrapper"
            style={isOpen ? showChatWindow : closeChatWindow}
        >
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}
                className="head-text">
                Let's chat? - Online
                {!isEmpty(romName) &&
                <Button icon={<LoginOutlined/>} onClick={exitChat}>
                </Button>
                }
            </div>
            <div className="chat-box">
                <Loading spinning={showLoading}>
                    {chatRoute === "form" ? (
                        <>
                            <div className="desc-text">
                                Veuillez remplir le formulaire ci-dessous pour commencer à
                                discuter avec le prochain agent disponible.
                            </div>
                            <Form
                                name="basic"
                                onFinish={onFinish}
                                className="chat-form"
                                validateMessages={validateMessages}
                            >
                                <Form.Item
                                    name="nickname"
                                    className="chat-field"
                                    rules={[{required: true}]}
                                >
                                    <Input placeholder="Entrer votre nom complet" size="large"/>
                                </Form.Item>
                                <Form.Item
                                    name="username"
                                    className="chat-field"
                                    rules={[{type: "email"}, {required: true}]}
                                >
                                    <Input
                                        placeholder="Entrez votre adresse email"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item className="chat-field">
                                    <Button type="primary" htmlType="submit" size="large">
                                        Start Chat
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    ) : chatRoute === "supportlist" ? (
                        supportUsers.map((value, index) => (
                            <Card
                                hoverable
                                key={index}
                                onClick={() => selectContact(value)}
                                style={{width: 300, marginTop: 16}}
                            >
                                <Card.Meta
                                    avatar={
                                        <Badge size="default" status="success">
                                            <Avatar
                                                style={{backgroundColor: "#1890ff"}}
                                                size={60}
                                                src={LINKS.imagePath + chat[0]?.data?.inputList[0]?.image}
                                            />
                                        </Badge>
                                    }
                                    title={chat && chat[0]?.data?.inputList[0]?.name}
                                    description={chat && chat[0]?.data?.inputList[0]?.email}
                                />
                            </Card>
                        ))
                    ) : chatRoute === "chatroom" ? (
                        <>
                            <ChatRoom room={romName}/>
                        </>
                    ) : (
                        <></>
                    )}
                </Loading>
            </div>
        </div>
    );
}

export default ChatWindow;

