import React, { useContext, useEffect,useState } from 'react'
import { Controls, FullScreenControl } from "./Controls";     
import Map from "./Map";
import { Layers, TileLayer, VectorLayer } from "./Layers";
 import { osm, vector } from "./Source";
import { fromLonLat, get } from 'ol/proj';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
const Map3 = ({lat, lang}) => {
    
    let styles = {
        'Point': new Style({
            image: new Icon({
                crossOrigin: 'anonymous',
                // For Internet Explorer 11
                imgSize: [20, 20],
                src: 'https://sopriamoccasions.ma/images/logo.png',
              })  
        }) 
    };
  
    const [center, setCenter] = useState([-6.849813,  33.971588]);
	const [zoom, setZoom] = useState(18);
    let data =[];
    if(lat && lang) {
        data =[lat,lang];
        console.log("Map3 fined"); 
    }
     else {
        data =[-6.849813, 33.971588]
        console.log("Map3 undefined"); 
     }
     console.log("Map3 ");  
     useEffect(() => { 
	 setCenter(data,data);
	}, [])

    var point = new Feature({
        geometry: new Point(fromLonLat(data)),
      });

      var vectorSource = new VectorSource({
        features: [point],
      });

      point.setStyle(
        new Style({
            image: new CircleStyle({
                radius: 10,
                fill: new Fill({
                    color: '#ed512973',
                }),
                stroke: new Stroke({
                    color: '#ef6744',
                }),
            }),
        })
      );
    return (

        <div>
         <Map center={fromLonLat(center)} zoom={zoom}>
				<Layers>
					<TileLayer
						source={osm()}
						zIndex={0}
					/> 
                    <VectorLayer
							source={vectorSource}
							 
						/>
				</Layers>
				<Controls>
					<FullScreenControl />
				</Controls>
			</Map>
        
        </div>


    )
}

export default Map3