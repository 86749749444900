import React, {useState} from 'react'
import Listning from './Listning'
import './Main.css';

import {Select} from 'antd';

const {Option} = Select;



const SearchResult = (props) => {




    const [dataViewType, setdataViewType] = useState(false);

    return (
        <>


            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        {/*<div className="page-heading">*/}
                        {/*</div>*/}
                        <ul className="coustom-breadcrumb">
                            <li className='uppr'><a href="/">Accueil</a></li>
                            <li className='uppr'>Voitures d’occasion</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>

            <Listning data={props.location.data} dataViewType={dataViewType} setdataViewType={setdataViewType}
                      searchType={props.location.SearchType}/>


        </>
    )
}

export default SearchResult;
