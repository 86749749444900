import React, {useReducer} from "react";
import axios from "axios";
import VoitureContext from "./voitureContext";
import voitureReducer from "./voitureReducer";
import {
    ACCOUNT_SUCCUSS,
    CLEAR_ERRORS,
    CONTACT_VOITURE_SUCCUSS,
    Delete_Mark_Succuss,
    DELETE_VOITURE_SUCCUSS,
    Export_Succuss,
    GET_ALL_CategoriesByMarkByModel,
    GET_ALL_FILTER,
    GET_ALL_MARKS,
    GET_ALL_ModuleByMark,
    GET_ALL_PDV,
    GET_ALL_VOITURES,
    GET_CONTENT_SUCCESS,
    GET_RESERVATION_VOITURE,
    GET_VOITURE_SUCCUSS,
    Import_Succuss,
    NEWSLETTER_CONTACT_US,
    OFFRE_MOMENT,
    RESERVER_VOITURE,
    REVENDRE_VOITURE,
    SEND_CONTACT_US,
    Update_Mark_Succuss,
    UPDATE_VOITURE_SUCCUSS,
    UPLOAD_Multiple,
    UPLOAD_Succuss,
    UPLOAD_Voiture_Succuss,
    GET_PDV_BYID
} from "./types";

// import { getErrorMessage } from "../../utils/helpers";

const VoitureState = (props) => {
    const initialState = {
        allmarks: [],
        allmodulesbymarque: [],
        allcategoriesbymarquebymodel: [],
        allvoitures: [],
        filterData: [],
        allPDV: [],
        voituredata: null,
        rangemin: null,
        rangemax: null,
        offresmoment: [],
        allreservations: [],
        allmodels: [],
        homesidelogo: [],
        homelogo: [],
        media: [],
        faq: [],
        conditions:[],
        contactus:[],
        conditionsvent:[],
        PdvDetail:null,
        guide:[],
        chat:[],
        whoAreWee:[]

    };

    const [state, dispatch] = useReducer(voitureReducer, initialState);

    // get All PDV
    const getPDV = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/salepoint`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_ALL_PDV,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // get  PDV By Id
    const getPDVById = (id) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/salepoint/${id}`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_PDV_BYID,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // get All Marks
    const getMarks = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/vehicles/filter/data`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_ALL_MARKS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // Update Mark
    const updateMarque = (marque, id) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.put(`/vehicles/mark/${id}`, marque).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: Update_Mark_Succuss,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // Delete Mark
    const deleteMarque = (marque) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.delete(`/vehicles/mark/${marque}`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: Delete_Mark_Succuss,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // get All Modules By Mark
    const filterDatas = (formData) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/vehicles/filter/`, formData).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_ALL_FILTER,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // get All Categories By Mark By Model
    const getCategoriesByMarkByModel = (marque, model) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/vehicles/categories/${marque}/${model}`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_ALL_CategoriesByMarkByModel,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // get All Modules By Mark
    const getModuleByMark = (marque) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/vehicles/models/${marque}`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_ALL_ModuleByMark,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Upload File
    const UploadFile = (formData) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/file`, formData).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: UPLOAD_Succuss,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // Upload Multiple File
    const UploadMultipleFile = (formData) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/file/multi`, formData).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: UPLOAD_Multiple,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // Import File
    const ImportFile = (formData) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/vehicles/import/data`, formData).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: Import_Succuss,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Export File
    const ExportFile = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/vehicles/export/data`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: Export_Succuss,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Add new Voiture
    const AddNewVoiture = (formData) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/vehicles `, formData).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: UPLOAD_Voiture_Succuss,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    const getAllVoitures = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/vehicles `).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_ALL_VOITURES,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // Offres du moment
    const Offresmoment = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/vehicles/publications `).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: OFFRE_MOMENT,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // Delete Voiture
    const deleteVoiture = (marque) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.delete(`/vehicles/${marque}`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: DELETE_VOITURE_SUCCUSS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Update Voiture
    const updateVoiture = (marque, id) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.put(`/vehicles/${id}`, marque).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: UPDATE_VOITURE_SUCCUSS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Get Voiture Data
    const getVoitureById = (id) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/vehicles/${id}`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_VOITURE_SUCCUSS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Add Contact
    const AddContact = (contact) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/request/`, contact).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: CONTACT_VOITURE_SUCCUSS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Create Account
    const CreateAccount = (formData, headers) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/profile`, formData, {
                    headers: headers
                }).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: ACCOUNT_SUCCUSS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                }).catch((error) => {
                    reject(error);
                })
            });
        } catch (err) {
        }
    };


    // Reserver Voiture
    const ReserverVoiture = (vehicle) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/orders/`, {vehicle}).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: RESERVER_VOITURE,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Get All Reservation
    const getAllReservations = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/orders/my`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_RESERVATION_VOITURE,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Revendre Voiture
    const RevendreVoiture = (formData) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/reprise`, formData).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: REVENDRE_VOITURE,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    const filterDataResult = (data) => {

        dispatch({
            type: GET_ALL_FILTER,
            payload: data,
        });
    }

    //  Get All Content
    const GetAllContent = () => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.get(`/cms`).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: GET_CONTENT_SUCCESS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };


    // Contact us envoyer
    const sendContactUs = (form) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/contact-us`, form).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: SEND_CONTACT_US,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // Newsletter  envoyer
    const sendNewsLetter = (form) => {
        try {
            return new Promise(async (resolve, reject) => {
                await axios.post(`/newsletter`, form).then((response) => {
                    if (response.data) {
                        dispatch({
                            type: NEWSLETTER_CONTACT_US,
                            payload: response.data,
                        });
                        resolve(response.data);
                    }
                });
            });
        } catch (err) {
        }
    };

    // Clear Errors
    const clearErrors = () => {
        dispatch({type: CLEAR_ERRORS});
    };

    return (
        <VoitureContext.Provider
            value={{

                allmarks: state.allmarks,
                allmodulesbymarque: state.allmodulesbymarque,
                allcategoriesbymarquebymodel: state.allcategoriesbymarquebymodel,
                allvoitures: state.allvoitures,
                rangemin: state.rangemin,
                rangemax: state.rangemax,
                filterData: state.filterData,
                allPDV: state.allPDV,
                voituredata: state.voituredata,
                offresmoment: state.offresmoment,
                allreservations: state.allreservations,
                homesidelogo: state.homesidelogo,
                homelogo: state.homelogo,
                media: state.media,
                faq: state.faq,
                conditions:state.conditions,
                contactus:state.contactus,
                conditionsvent:state.conditionsvent,
                PdvDetail:state.PdvDetail,
                guide:state.guide,
                chat:state.chat,
                RevendreVoiture,
                getModuleByMark,
                sendContactUs,
                getCategoriesByMarkByModel,
                getAllReservations,
                ReserverVoiture,
                getVoitureById,
                UploadMultipleFile,
                UploadFile,
                AddNewVoiture,
                getAllVoitures,
                sendNewsLetter,
                ImportFile,
                ExportFile,
                deleteMarque,
                updateMarque,
                updateVoiture,
                filterDatas,
                deleteVoiture,
                CreateAccount,
                Offresmoment,
                AddContact,
                getPDV,
                getPDVById,
                clearErrors,
                GetAllContent,
                filterDataResult,
                getMarks,
                whoAreWee:state.whoAreWee,

                // getNotifications,
            }}
        >
            {props.children}
        </VoitureContext.Provider>
    );
};

export default VoitureState;