import React from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Layout from "../pages/Layout";
import ScrollToTop from "./ScrollToTop";
 const Routes = () => {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route
                    exact
                    path={[
                        "/",
                        "/voitures-occasion",
                        "/Voituredetail/:id",
                        "/Pointsdevente",
                        "/Simulator",
                        "/Simulator/:id",
                        "/Reprise",
                        "/create-account",
                        "/login",
                        "/forgot-password",
                        "/edit-profil",
                        "/ma-selection-de-vehicules",
                        "/demande-reservation/:id",
                        "/verify",
                        "/reset",
                        "/reservations",
                        "/payment/ok",
                        "/payment/fail",
                        "/contact-us",
                        "/FAQ",
                        "/ENGAGEMENTS",
                        "/validationPage",
                        "/pdvdetail/:id",
                        "/notre-guide-d-achat-occasion",
                        "/WhoAreWe"


                    ]}
                >
                    <Layout/>
                </Route>

            </Switch>
        </Router>


    );
};

export default Routes;
