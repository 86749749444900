import React from 'react'

import './Main.css'

const Engagment = () => {
    return (
        <>


            <section id="about_us" class="section-padding">
                <div class="container">
                    <div class="section-header text-center">
                        <h2 className='stlspor'><span className="sttyl">ENGAGEMENTS SOPRIAM OCCASIONS </span></h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-6">
                            <div class="about_info">
                                <div class="icon_box">
                                    <i className="fa fa-history" aria-hidden="true"></i>
                                </div>
                                <h5>Reprise de votre véhicule</h5>
                                <p>Offre de reprise toutes marques confondues :</p><p>
                                    Parce que vous devez pouvoir compter sur nous pour le rachat de votre véhicule
                                    Alors, nous nous engageons à faire une offre de reprise de votre véhicule actuel, quel que soit sa marque.
                                </p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6">
                            <div class="about_info">
                                <div class="icon_box">
                                    <i className="fa fa-users" aria-hidden="true"></i>

                                </div>
                                <h5>Dossier administratif</h5>
                                <p>Nous vous accompagnons dans toutes vos démarches administratives :</p><p>
                                    La gestion administrative du dossier de mutation est prise en charge par Sopriam Occasion.  La prise de RDV du contrôle technique de mutation.
                                </p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6">
                            <div class="about_info">
                                <div class="icon_box">
                                    <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>

                                </div>
                                <h5>Voiture certifiée</h5>
                                <p>Véhicule expertisé et contrôlé. Parce que vous devez acheter en toute confiance et rouler en toute sécurité, nos véhicules d'occasion sont sélectionnés
                                    et font l’objet d'un contrôle minutieux et d'une préparation rigoureuse portant sur nos points de contrôle.</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6">
                            <div class="about_info">
                                <div class="icon_box">
                                    <i className="fa fa-money" aria-hidden="true"></i>
                                </div>
                                <h5>Financement avantageux</h5>
                                <p>Grâce à nos solutions financement automobiles, choisissez le véhicule d'occasion et personnalisez votre solution avec SOPRIAM Financement selon vos besoins. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Engagment
