import React from 'react'
import {List, Space,} from 'antd';
import {HeartFilled, HeartOutlined} from '@ant-design/icons';
import {useShopCart} from '../../contexts';
import LINKS from '../../utils/Link';
import {useHistory} from "react-router-dom";

const MesSelections = () => {

    let history = useHistory();

    const {cartValue, setCartValue} = useShopCart();

    const goToVoiture = (data) => {

        history.push({
            pathname: '/Voituredetail/' + data.id,
            data: data,
        });

    }

    const listData = [];
    for (let i = 0; i < 23; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `ant design part ${i}`,
            avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            description:
                'Ant Design, a design language for background applications, is refined by Ant UED Team.',
            content:
                'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
        });
    }

    const IconText = ({icon, text}) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    const onAddToPanier = (v, t) => {
        if (t === "add") {
            setCartValue([...cartValue, v]);

        } else {
            setCartValue(cartValue.filter(value => value.id !== v.id));
        }
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            margin: '3rem'
        }}>


            <h5 className='text-center'>MA SÉLECTION DE VÉHICULE(S) D'OCCASION </h5>

            <h6> {cartValue.length} VÉHICULE(S) SÉLECTIONNÉ(S) </h6>

            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: page => {

                    },
                    pageSize: 3,
                }}
                dataSource={cartValue}
                footer={null
                }
                renderItem={item => (
                    <List.Item
                        key={item.category}
                        actions={[
                            <button onClick={() => goToVoiture(item)} type="button" class="btn btn-block bg-inc-g">
                                <i class="fa fa-search" aria-hidden="true"></i> Voir voiture
                            </button>
                        ]}
                        extra={
                            <>
                                <img
                                    width={272}
                                    alt="logo"
                                    src={LINKS.imagePath + item.mainPicture}
                                />
                                {cartValue.length > 0 && cartValue.find(value => value.id === item.id)
                                    ?
                                    <HeartFilled onClick={() => onAddToPanier(item, "remove")}
                                                 style={{color: '#ef6744', fontSize: 20}}/>
                                    :
                                    <HeartOutlined onClick={() => onAddToPanier(item, "add")} style={{fontSize: 20}}/>
                                }


                            </>
                        }
                    >
                        <List.Item.Meta
                            title={<a onClick={() => goToVoiture(item)}>{item.mark + "  " + item.category}</a>}
                            description={item.mark}
                        />
                        {new Intl.NumberFormat('fr-FR').format(item.promoPrice)}{" DH"}

                    </List.Item>
                )}
            />,

        </div>
    )
}

export default MesSelections
