import React, { useContext, useEffect, useState } from "react";

import { Empty, Select, Slider } from "antd";
import AutoPlaySlider from "./Slider";
import VoitureContext from "../context/voitures/voitureContext";

import { useHistory } from "react-router-dom";
import "./Main.css";

const { Option } = Select;

const Main = () => {
  const voitureContext = useContext(VoitureContext);

  const {
    getMarks,
    allmarks,
    filterDatas,
    allmodulesbymarque,
    getModuleByMark,
    rangemin,
    rangemax,
    GetAllContent,
    homelogo,
  } = voitureContext;

  let history = useHistory();

  const [formData, setformData] = useState({
    city: null,
    mark: null,
    registrationYear: null,
    minPrice: rangemin,
    maxPrice: rangemax,
    minMileage: 0,
    maxMileage: 275000,
    model: null,
  });

  useEffect(() => {
    GetAllContent();
    getMarks();

    if (rangemax !== null && rangemin !== null)
      setformData({
        ...formData,
        minPrice: rangemin,
        maxPrice: rangemax,
        minMileage: 0,
        maxMileage: 275000,
      });
  }, [rangemin !== null, rangemax !== null]);

  function handleChangeType(value) {
    setformData({ ...formData, mark: value });
    getModuleByMark(value);
  }

  function handleChangeType1(value) {
    setformData({ ...formData, model: value });
  }

  function handleChangeVille(value) {
    setformData({ ...formData, city: value });
  }

  const onSliderChange = (v) => {
    setformData({ ...formData, minPrice: v[0], maxPrice: v[1] });
  };

  const onSliderKMChange = (v) => {
    setformData({ ...formData, minMileage: v[0], maxMileage: v[1] });
  };

  function handleChangeAnnee(e) {
    setformData({ ...formData, registrationYear: e });
  }

  const onFilter = () => {
    filterDatas(formData).then((res) => {
      // history.push("/Searchresult", { type: true });

      history.push({
        pathname: "/voitures-occasion",
        SearchType: true,
        data: formData,
      });
    });
  };

  const onClearFilter = () => {
    setformData({
      city: null,
      mark: null,
      registrationYear: null,
      minPrice: rangemin,
      maxPrice: rangemax,
      minMileage: 0,
      maxMileage: 275000,
      model: null,
    });
  };

  let yeard = new Date().getFullYear();
  let YearData = [];
  for (let index = 0; index < 35; index++) {
    YearData.push({ id: index, year: yeard-- });
  }

  return (
    <>
      <section id="banner2">
        <AutoPlaySlider />
      </section>

      <section id="filter_form2">
        <div className="container">
          <div className="text-center m-3">
            <a
              style={{ backgroundColor: "#ed5129", marginRight: 18 }}
              href="/"
              className="btn btn-hd"
            >
              ACHETER
            </a>

            <a
              style={{ backgroundColor: "white", color: "#ed5129" }}
              href="/Reprise"
              className="btn btn-hd"
            >
              REVENDRE
            </a>
          </div>

          <div className="main_bg white-text">
            <div className="row">
              <div className="form-group col-md-4 col-sm-6">
                <div className="">
                  <Select
                    value={formData.mark}
                    allowClear
                    showSearch
                    placeholder="Marque"
                    style={{ width: "100%" }}
                    onChange={handleChangeType}
                  >
                    {allmarks.map((v, i) => {
                      return (
                        <Option key={i} value={v}>
                          {v}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="form-group col-md-4 col-sm-6">
                <Select
                  notFoundContent={
                    <Empty description="veuillez sélectionner la marque" />
                  }
                  value={formData.model}
                  allowClear
                  showSearch
                  placeholder="Modèle"
                  style={{ width: "100%" }}
                  onChange={handleChangeType1}
                >
                  {allmodulesbymarque.map((v, i) => {
                    return (
                      <Option key={i} value={v}>
                        {v}
                      </Option>
                    );
                  })}
                </Select>
              </div>

              {/* <div className="form-group col-md-3 col-sm-6">
                                <div className="">
                                    <Select
                                        value={formData.city}
                                        allowClear
                                        showSearch
                                        placeholder="Ville"
                                        onChange={handleChangeVille}
                                        style={{width: '100%'}}>
                                        {City.map((v, i) => {
                                            return (
                                                <Option key={i} value={v.name}>{v.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>*/}
              <div className="form-group col-md-4 col-sm-6">
                <div className="">
                  <Select
                    value={formData.registrationYear}
                    allowClear
                    showSearch
                    placeholder="Année du modèle"
                    onChange={handleChangeAnnee}
                    style={{ width: "100%" }}
                  >
                    {YearData.map((v, i) => {
                      return (
                        <Option key={i} value={v.year}>
                          {v.year}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="form-group col-md-5 col-sm-6">
                <label className="form-label">Prix (DH) </label>

                {rangemin !== null && rangemax !== null && (
                  <Slider
                    range
                    value={[formData.minPrice, formData.maxPrice]}
                    tooltipVisible={true}
                    onChange={onSliderChange}
                    style={{ width: "90%" }}
                    tooltipPlacement="bottom"
                    min={rangemin}
                    max={rangemax}
                  />
                )}

                <br></br>
                <br></br>
              </div>

              <div className="form-group col-md-4 col-sm-6">
                <label className="form-label">Kilométrage (KM) </label>
                {rangemin !== null && rangemax !== null && (
                  <Slider
                    value={[formData.minMileage, formData.maxMileage]}
                    style={{ width: "90%" }}
                    tooltipVisible={true}
                    onChange={onSliderKMChange}
                    min={0}
                    tooltipPlacement="bottom"
                    max={175000}
                    range
                    defaultValue={[0, 175000]}
                  />
                )}
                {/*    <Slider
                                            value={[formData.minMileage, formData.maxMileage]}
                                            tooltipVisible={true} onChange={onSliderKMChange} min={0} tooltipPlacement="bottom"
                                            max={175000} range defaultValue={[0, 175000]}/>*/}
              </div>

              <div className="form-group col-md-3 col-sm-6 ">
                <button
                  type="button"
                  onClick={onFilter}
                  style={{ backgroundColor: "white", color: "#ed5129" }}
                  className="btn btn-block float-right"
                >
                  <i className="fa fa-search" aria-hidden="true"></i> VOIR LES
                  OFFRES
                </button>
                <button
                  type="button"
                  onClick={onClearFilter}
                  style={{ backgroundColor: "#ed5129", color: "white" }}
                  className="btn btn-block float-right"
                >
                  EFFACER LES FILTRES
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
