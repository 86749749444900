import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Input, Result} from "antd";
import Swal from "sweetalert2";
import AuthContext from "../../context/auth/authContext";
import Axios from "axios";

const MotDePassOub = (props) => {
    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false);

    const authContext = useContext(AuthContext);

    const {isAuth, login} = authContext;

    const [formData, setFormData] = useState({
        "username": null,
    });
    const changeAccount = (key, value) => {
        let keys = key.split(".");
        let formDataS = formData;
        if (keys.length === 1) {
            formDataS[keys[0]] = value;
        } else if (keys.length === 2) {
            formDataS[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
            formDataS[keys[0]][keys[1]][keys[2]] = value;
        }
        setFormData({...formDataS});
    };


    const onFinish = () => {
        let Data = [];
        Data.push({email: formData.username.trim().toLowerCase()});
        Axios.post('/reset', Data[0])
            .then((res) => {
                setVisible(true);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: err?.response?.data?.details,
                });
            });

    };

    const onGo = () => {
        window.location.href = "/login";
    };



    return (

        <>

            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        <div className="page-heading">
                        </div>
                        <ul className="coustom-breadcrumb">
                            <li><a href="/">ACCUEIL</a></li>
                            <li>MOT DE PASSE OUBLIÉ</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section>
            {!visible &&

            <div>

                <br></br><br></br>
                <h5 className="text-center">MOT DE PASSE OUBLIÉ ?</h5>

                <Form
                    style={{width: '75%'}}
                    size="small"
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    layout={formItemLayout}
                    scrollToFirstError
                >


                    <Form.Item
                        name="username"
                        label="E-mail"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input
                            placeholder='E-mail'
                            value={formData.username}
                            onChange={(e) =>
                                changeAccount("username", e.target.value)
                            }
                        />
                    </Form.Item>




                    <Form.Item {...tailFormItemLayout}>


                        <button
                            type="submit" className="btn  bg-inc-g uppr">VALIDER
                        </button>



                    </Form.Item>

                </Form>


            </div>


            }



            { visible &&

            <div>
                <br></br> <br></br>
                <Result
                    status={"success"}
                    title={"MOT DE PASSE OUBLIÉ ?"}
                    subTitle={"Un email a été envoyé à l'adresse indiquée. Pour réinitialiser votre mot de passe, cliquez dans celui-ci et suivez les instructions.\n" + "\n"}
                    extra={[
                        <button onClick={onGo} type="submit" className="btn  bg-inc-g">FERMER </button>
                        ,
                    ]}
                />,
            </div>
            }




            </>
    );
}

export default MotDePassOub;


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
