import React from "react";
import {asyncHandler, checkResponse, errorResponseHandler, openNotification,} from "../utils";
import axios from "axios";

const DataContext = React.createContext();
const {Provider} = DataContext;

const initState = {
    tables: [],
    filteredTables: [],
    selectedTable: null,
    connectionInfo: null,
    loading: false,
    error: null,
};

const DataProvider = ({children}) => {
    const [state, setState] = React.useState(initState);

    // const [storedState, setStoredState] = useLocalStorage('tst', initStoredState);

    const pingDatabase = asyncHandler(async (formData) => {
        initialLoading();
        return await axios
            .post("/data/ping", formData)
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        tables: res.data,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const getDatabaseConnection = asyncHandler(async (formData) => {
        initialLoading();
        return await axios
            .post("/data/connect", formData)
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        tables: res.data,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const fetchDataTable = asyncHandler(async (formData, params) => {
        initialLoading();
        return await axios
            .post("/data/fetch", formData, {
                params: {
                    ...params,
                },
            })
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const fetchJoinData = asyncHandler(async (formData, params) => {
        initialLoading();
        return await axios
            .post("/data/join", formData, {
                params: {
                    ...params,
                },
            })
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const fetchFileData = asyncHandler(async (formData, params) => {
        initialLoading();
        return await axios
            .post("/data/fetch/file", formData, {
                params: {
                    ...params,
                },
            })
            .then((res) => {
                if (checkResponse(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                }
                return res;
            })
            .catch(handleErrorResponse);
    });

    const filterTables = (payload) => {
        setState((prevState) => ({
            ...prevState,
            filteredTables: prevState.tables.filter((table) => {
                const regex = new RegExp(`${payload}`, "gi");
                return table.schema.match(regex);
            }),
        }));
    };

    const clearFilter = () => {
        setState((prevState) => ({
            ...prevState,
            filteredTables: [],
        }));
    };

    const initialLoading = () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
    };

    const selectTable = (value) => {
        setState((prevState) => ({
            ...prevState,
            selectedTable: value,
        }));
    };

    const setConnectionInfo = (value) => {
        setState((prevState) => ({
            ...prevState,
            connectionInfo: value,
            selectedTable: null,
        }));
    };

    const handleErrorResponse = (err) => {
        err = errorResponseHandler(err);
        setState((prevState) => ({
            ...prevState,
            error: errorResponseHandler(err),
            loading: false,
        }));
        openNotification("error", err);
    };

    const clearError = () => {
        setState((prevState) => ({
            ...prevState,
            error: null,
        }));
    };

    return (
        <Provider
            value={{
                ...state,
                getDatabaseConnection,
                setConnectionInfo,
                fetchDataTable,
                fetchFileData,
                fetchJoinData,
                filterTables,
                pingDatabase,
                selectTable,
                clearFilter,
                clearError,
            }}
        >
            {children}
        </Provider>
    );
};

export {DataContext, DataProvider};
