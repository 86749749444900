import React from "react";

const useUpdateEffect = (effect, deps) => {
    const isMounted = React.useRef(false);

    React.useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
        } else {
            return effect();
        }
    }, deps);
};

export default useUpdateEffect;
