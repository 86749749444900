import React, {useContext, useState ,useEffect} from 'react';
import {AutoComplete, Checkbox, Col, Form, Input, Modal, Row, Select,} from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import VoitureContext from '../../context/voitures/voitureContext';
import Swal from "sweetalert2";
import SimpleBar from 'simplebar-react';
import {PasswordInput} from "../../utils/PasswordInput";
import {validatePassword, validatePhone} from "../../utils";
import {Helmet} from "react-helmet";

const {Option} = Select;
const AutoCompleteOption = AutoComplete.Option;

const Registration = () => {

    const [form] = Form.useForm();

    const [captcharesponse, setcaptcharesponse] = useState();

    const voitureContext = useContext(VoitureContext);

    const {CreateAccount} = voitureContext;

    const [formData, setFormData] = useState({

        "email": null,
        "firstName": null,
        "lastName": null,
        "phone": null,
        "city": null,
        "address": null,
        "password": null

    });

    const changeAccount = (key, value) => {
        let keys = key.split(".");
        let formDataS = formData;
        if (keys.length === 1) {
            formDataS[keys[0]] = value;
        } else if (keys.length === 2) {
            formDataS[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
            formDataS[keys[0]][keys[1]][keys[2]] = value;
        }
        setFormData({...formDataS});
    };

    const [agreementModal, setAgreementModal] = useState({
        visible: false,
        checked: false
    });


    const onFinish = () => {

        const headers = {
            'captcha-response': captcharesponse,
        }

        CreateAccount(formData, headers).then((res) => {

            window.location.href = "/validationPage";

        }).catch((err) => {

            Swal.fire({
                icon: "error",
                title: "Error",
                text: err.response.data.message,
            });
        });
    };

    function onChange(value) {
        setcaptcharesponse(value);
    }
    const [randomValue, setRandomValue] = useState(0);

    useEffect(() => {
        let axel = Math.random() + '';
        setRandomValue(axel * 10000000000000) ;
    }, []);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sopriam Occasions - creation de compte</title>

                <script type="text/javascript">
                    {` 
                    document.write('<iframe src="https://13491286.fls.doubleclick.net/activityi;src=13491286;type=form;cat=formu00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=' ${randomValue} '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
                    `}
                </script>
                <noscript>
                    {`
                    <iframe src="https://13491286.fls.doubleclick.net/activityi;src=13491286;type=form;cat=formu00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=1?" width="1" height="1" frameborder="0" style="display:none"></iframe>
                    `}
                </noscript>
             </Helmet>


            <section className="page-header contactus_page">
                <div className="container-fluid">
                    <div className="page-header_wrap">
                        {/*<div class="page-heading">*/}
                        {/*    <h1>Contactez-nous</h1>*/}
                        {/*</div>*/}
                        <ul className="coustom-breadcrumb">
                            <li className='uppr'><a href="/">ACCUEIL</a></li>
                            <li className='uppr'>CRÉER MON COMPTE</li>
                        </ul>
                    </div>
                </div>

                <div className="dark-overlay"></div>
            </section><br></br>

            <h5 className="text-center">CRÉER MON COMPTE </h5>

            <Form
                style={{width: '75%'}}
                size="small"
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
            >


                <Form.Item
                    name="lastname"
                    label="Nom"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez renseigner le champ obligatoire!',
                        },
                    ]}
                >
                    <Input
                        placeholder='Nom *'
                        value={formData.lastName}
                        onChange={(e) =>
                            changeAccount("lastName", e.target.value)
                        }
                    />
                </Form.Item>


                <Form.Item
                    name="firstname"
                    label="Prénom"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez renseigner le champ obligatoire!',
                        },
                    ]}
                >
                    <Input
                        placeholder='Prénom *'
                        value={formData.firstName}
                        onChange={(e) =>
                            changeAccount("firstName", e.target.value)
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                        {
                            type: 'email',
                            message: 'Invalid email address',
                        },
                        {
                            required: true,
                            message: 'Veuillez renseigner le champ obligatoire!',
                        },
                    ]}
                >
                    <Input
                        placeholder='E-mail'
                        value={formData.email}
                        onChange={(e) =>
                            changeAccount("email", e.target.value)
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Mot de passe"
                    rules={[
                        {required: true, message: "Veuillez renseigner le champ obligatoire!"},
                        {validator: validatePassword}
                    ]}
                    hasFeedback
                >
                    <PasswordInput
                        placeholder='Mot de passe *'
                        value={formData.password}
                        onChange={(e) =>
                            changeAccount("password", e.target.value)
                        }/>
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirmer Mot de passe"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez renseigner le champ obligatoire!',
                        },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject('Les deux mots de passe que vous avez saisis ne correspondent pas!');
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        placeholder='Confirmer Mot de passe *'
                        value={formData.password}
                        onChange={(e) =>
                            changeAccount("password", e.target.value)
                        }
                    />
                </Form.Item>


                <Form.Item
                    name="phone"
                    label="Téléphone"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez renseigner le champ obligatoire!',
                        },
                        {validator: validatePhone}
                    ]}
                >
                    <Input
                        placeholder='Téléphone *'
                        value={formData.phone}
                        onChange={(e) =>
                            changeAccount("phone", e.target.value)
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="adresse"
                    label="Adresse"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez renseigner le champ obligatoire!',
                        },
                    ]}
                >
                    <Input
                        placeholder='Adresse *'
                        value={formData.address}
                        onChange={(e) =>
                            changeAccount("address", e.target.value)
                        }
                    />
                </Form.Item>


                <Form.Item
                    name="ville"
                    label="Ville"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez renseigner le champ obligatoire!',
                        },
                    ]}
                >
                    <Input
                        placeholder='Ville *'
                        value={formData.city}
                        onChange={(e) =>
                            changeAccount("city", e.target.value)
                        }
                    />
                </Form.Item>


                <Form.Item label="Captcha">
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item

                            >
                                <ReCAPTCHA
                                    sitekey="6LeMZ9gZAAAAABgT1KQZji32rw_qjZiXj4kOkf00"
                                    onChange={onChange}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form.Item>


                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Prière accepter les mentions légales'),
                        },
                    ]}
                    {...tailFormItemLayout}
                >
                    <Checkbox onClick={() => setAgreementModal({...agreementModal, visible: true})}
                              checked={agreementModal.checked}>
                        <span> J'accepte les mentions légales</span><span
                        style={{color: '#88888'}}>*</span>
                    </Checkbox>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <button type="submit" class="btn  bg-inc-g">Valider</button>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    (<span
                    style={{color: '#88888'}}>*</span>) Champs obligatoires


                </Form.Item>

            </Form>


            <Modal
                title='Mentions légales :'
                visible={agreementModal.visible}
                onCancel={() => setAgreementModal({...agreementModal, visible: false})}
                footer={null}>
                <SimpleBar style={{maxHeight: 300, maxWidth: 600}}>
                    <p>
                        Les données à caractère personnel collectées dans ce formulaire de collecte sont
                        destinées à Sopriam
                        importateur exclusif de Sopriam au Maroc (siège : 125, Boulevard Moulay Slimane, Casablanca - Maroc).
                    </p>
                    <p>
                        Tous les champs marqués d&#39;un astérisque sont obligatoires aux fins de traiter votre
                        demande d&#39;offre.
                        Dans le cas contraire Sopriam pourrait ne pas être en mesure de traiter et de fournir le
                        service
                        demandé comme indiqué ci-dessus.
                    </p>
                    <p>
                        Sous réserve de votre consentement lorsque requis, vous pouvez recevoir des
                        renseignements sur les
                        offres, les nouvelles et les événements (newsletters, invitations et autres
                        publications) de Sopriam, de
                        ses filiales ou de ses partenaires.
                    </p>
                    <p>
                        Conformément à la Règlementation en vigueur en matière de protection des données à
                        caractère
                        personnel (qui désigne la loi n° 09-08 relative à la protection des personnes physiques
                        à l&#39;égard du
                        traitement des données à caractère personnel), vous disposez d&#39;un droit d&#39;accès,
                        de rectification,
                        d&#39;effacement, de limitation du traitement, de portabilité, ainsi que d&#39;un droit
                        d&#39;opposition au
                        traitement de vos données à caractère personnel lorsque ces dernières sont traitées à
                        des fins de
                        marketing direct ou lorsque le traitement est fondé sur l’intérêt légitime.
                    </p>
                    <p>
                        Pour plus d&#39;informations sur la manière dont vos données à caractère personnel sont
                        traitées
                        par Sopriam Maroc, veuillez lire la Déclaration de Confidentialité de Sopriam.
                    </p>

                </SimpleBar>
            </Modal>

        </div>
    )
}

export default Registration;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
