import React, {useContext, useEffect} from 'react'
import {Avatar, Badge, Card, Popover} from 'antd';
import {ShoppingCartOutlined} from '@ant-design/icons';
import {useShopCart} from '../contexts';
import LINKS from '../utils/Link';
import {useHistory} from "react-router-dom";
import AuthContext from "../context/auth/authContext";


const Navbar = () => {

    let history = useHistory();

    const {cartValue} = useShopCart();

    const authContext = useContext(AuthContext);
    const {logout, loadUser, currentUser , loadProfile , currentProfil} = authContext;

    useEffect(() => {

        if (currentUser === null) loadUser();
        if (currentProfil === null) loadProfile();


        // eslint-disable-next-line
    }, []);

    const goToVoiture = (data) => {

        history.push({
            pathname: '/Voituredetail/' + data.id,
            data: data,
        });

    }


    const text = <span>MON PANIER</span>;
    const content = (
        <div>

            {cartValue.length > 0 && cartValue.slice(0, 2).map((v, i) => {

                return (
                    <Card key={i} onClick={() => goToVoiture(v)}
                          cover={<img width='60'   alt="example" src={LINKS.imagePath + v.mainPicture}/>}
                          size="small" title={v.mark + " " + v.category} style={{width: 220, cursor: 'pointer'}}>
                    </Card>
                )

            })}


            <span onClick={() => {
                history.push({
                    pathname: '/ma-selection-de-vehicules'
                });
            }}
                  style={{cursor: 'pointer'}}>Voir toutes mes sélections ({cartValue.length})</span>
        </div>
    );

    return (
        <header>


            <div className="default-header">
                <div className="container-fluid m-1">
                    <div className="row">
                        <div className="col-sm-3 col-md-2">
                            <div className="logo float right"><a href="/"><img height="50" width="168"
                                                                           src="/images/logo.png"
                                                                           alt="image"/></a></div>
                        </div>
                        <div className="col-sm-9 col-md-10">
                            <div className="header_info">
                                {/*<div className="header_widgets">*/}
                                {/*    <div style={{marginLeft: '-80%'}}>*/}
                                {/*        <div className="circle_icon"><i className="fa fa-envelope" aria-hidden="true"></i></div>*/}
                                {/*        <p className="uppercase_text">POUR NOUS CONTACTER: </p>*/}
                                {/*        <a href="mailto:info@example.com">info@sopriam.ma</a></div>*/}
                                {/*</div>*/}

                                {currentUser &&
                                <>
                                    <div className="login_btn "><a href="/reservations"
                                                               className="btn btn-xs uppercase btn-btn-nav"
                                                               data-toggle="modal" data-dismiss="modal">Mes
                                        réservations</a></div>


                                    <Popover placement="bottom" content={
                                        <>

                                        <div className="login_btn "><a onClick={logout}
                                                                       className="btn btn-xs uppercase btn-btn-nav"
                                                                       data-toggle="modal"
                                                                       data-dismiss="modal">Déconnexion</a>
                                        </div>

                                        <div className="login_btn "><a onClick={() => {
                                            window.location.href = "/edit-profil"
                                        }}
                                        className="btn btn-xs uppercase btn-btn-nav"
                                        data-toggle="modal"
                                        data-dismiss="modal">MON PROFIL
                                        </a>
                                        </div>

                                        </>

                                    } trigger="click">
                                        <div className="login_btn "><a
                                                                       className="btn btn-xs uppercase btn-btn-nav"
                                                                       data-toggle="modal"
                                                                       data-dismiss="modal">{currentProfil?.firstName + ' ' + currentProfil?.lastName}</a>
                                        </div>
                                    </Popover>


                                </>

                                }

                                {!currentUser &&
                                <>
                                    <div className="login_btn"><a href="/login" className="btn btn-xs uppercase btn-btn-nav"
                                                              data-toggle="modal" data-dismiss="modal">Se Connecter</a>
                                    </div>
                                    <div className="login_btn"><a href="/create-account"
                                                              className="btn btn-xs uppercase btn-btn-nav"
                                                              data-toggle="modal" data-dismiss="modal">Créer un
                                        compte</a></div>
                                </>
                                }

                                &nbsp; <span className="avatar-item">
                                    <Badge count={cartValue.length}>

                                        <Popover placement="bottom" title={text} content={content} trigger="click">
                                        <Avatar shape="square" icon={<ShoppingCartOutlined/>}/>
                                      </Popover>
                                    </Badge>
                                </span>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <nav id="navigation_bar" className="navbar navbar-expand-lg ">
                <div className="container">
                    <div className="row header-row">
                        <div className="navbar-header">
                            <button onClick={() => {
                                document.getElementById("navigation").classList.toggle("show");
                            }} id="menu_slide" data-target="#navigation" aria-expanded="false"
                                    data-toggle="collapse" className="navbar-toggler" type="button"><i
                                className="fa fa-bars"></i></button>
                        </div>

                        <div className="collapse navbar-collapse" id="navigation">
                            <ul className="nav navbar-nav">

                                <li><a href="/">ACCUEIL</a></li>

                                <li><a href="/voitures-occasion">VOITURES D’OCCASION</a></li>

                                {/*<li><a href="/ENGAGEMENTS">ENGAGEMENTS</a></li>*/}

                                <li><a href="/Pointsdevente">POINTS DE VENTE</a></li>


                                <li><a href="/Simulator">FINANCEMENT</a></li>

                                <li><a href="/notre-guide-d-achat-occasion">GUIDE D’ACHAT </a></li>


                            </ul>
                        </div>

                    </div>
                </div>
            </nav>


        </header>

    )
}

export default Navbar
