import React from "react";
import useUpdateEffect from "./useUpdateEffect";

const isFunction = (obj) => typeof obj === "function";

const useStorageState = (storage, key, defaultValue) => {
    const [state, setState] = React.useState(() => getStoredValue());

    function getStoredValue() {
        const raw = storage.getItem(key);
        if (raw) {
            try {
                return JSON.parse(raw);
            } catch (e) {
            }
        }
        if (isFunction(defaultValue)) {
            return defaultValue();
        }
        return defaultValue;
    }

    function updateState(value) {
        if (typeof value === "undefined") {
            storage.removeItem(key);
            setState(undefined);
        } else if (isFunction(value)) {
            const previousState = getStoredValue();
            const currentState = value(previousState);
            storage.setItem(key, JSON.stringify(currentState));
            setState(currentState);
        } else {
            storage.setItem(key, JSON.stringify(value));
            setState(value);
        }
    }

    useUpdateEffect(() => {
        setState(getStoredValue());
    }, [key]);

    return [state, updateState];
};

export default useStorageState;
